import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import CienaSvg from '@/assets/ciena.svg';
import AppBarSvg from '@/assets/app-bar.svg';
import GeneralSvg from '@/assets/general.svg';
import SecuritySvg from '@/assets/security.svg';

import './index.less';

const getSvg = (name) => {
  switch (name) {
    case 'app-bar':
      return AppBarSvg;
    case 'ciena':
      return CienaSvg;
    case 'security':
      return SecuritySvg;
    default:
      return GeneralSvg;
  }
};

const AppIcon = ({
  svg = 'general',
  color,
  name,
  className,
  disabled,
  onClick,
  tooltip,
  ...others
}) => (
  <Tooltip title={tooltip}>
    <svg
      color={color}
      className={classNames(
        'cec-icon',
        `cec-icon-${name}`,
        disabled ? 'disabled' : '',
        onClick ? 'onClick' : '',
        className
      )}
      onClick={disabled ? undefined : onClick}
      {...others}
    >
      <use xlinkHref={`${getSvg(svg)}#${name}`} />
    </svg>
  </Tooltip>
);

export default AppIcon;
