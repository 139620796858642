import {
  getCategories,
  getCategoriesAdmin,
  getEditCategoriesAdmin,
  getSubCategories,
  getSubCategoriesAdmin,
  deleteSubCategories,
  postSubCategories,
  getContents,
  getContentsAdmin,
  postContents,
  deleteContents,
  putContents,
  getMCPDocs,
  getMCPDocsAdmin,
  postMCPDocs,
  deleteMCPDocs,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'contentModel',
  state: {
    categories: [],
    editCategories: [],
    subCategories: [],
    contents: [],
    currentContent: {},
    mcpDocs: {},
  },
  subscriptions: {},
  effects: {
    *getCategories(_, { call, put, select }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = yield call(adminRole ? getCategoriesAdmin : getCategories);

        yield put({
          type: 'setState',
          payload: { categories: data },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getEditCategories(_, { call, put, select }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        if (adminRole === true) {
          const params = { editable: true };
          const data = yield call(getEditCategoriesAdmin, params);

          yield put({
            type: 'setState',
            payload: { editCategories: data },
          });
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getSubCategories(_, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'contentModel',
            loading: true,
          },
        });

        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = yield call(adminRole ? getSubCategoriesAdmin : getSubCategories);

        yield put({
          type: 'setState',
          payload: { subCategories: data },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'contentModel',
            loading: false,
          },
        });
      }
    },
    *deleteSubCategories({ payload }, { call, put }) {
      try {
        yield call(deleteSubCategories, payload);

        yield put({ type: 'getSubCategories' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postSubCategories({ payload, callback }, { call, put }) {
      try {
        yield call(postSubCategories, payload);

        yield put({ type: 'getSubCategories' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getContents({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'contentModel',
            loading: true,
          },
        });

        const { refineParams, adminRole } = yield select(({ contentModel, userModel }) => ({
          refineParams: contentModel.refineParams,
          adminRole: userModel.adminRole,
        }));

        let params = { ...(payload || refineParams) };
        if (params && params.published) {
          params.published = params.published.length === 1 ? params.published[0] : undefined;
        }

        const data = yield call(adminRole ? getContentsAdmin : getContents, params);

        yield put({
          type: 'setState',
          payload: {
            contents: data,
            currentContent: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'contentModel',
            loading: false,
          },
        });
      }
    },
    *getSubCategoriesByParams({ payload }, { call, put }) {
      try {
        const data = yield call(getSubCategories, payload);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return [];
      }
    },
    *getContentsByParams({ payload }, { call, put }) {
      try {
        const data = yield call(getContents, payload);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return [];
      }
    },
    *postContents({ payload, callback }, { call, put }) {
      try {
        yield call(postContents, payload);

        yield put({ type: 'getContents' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteContents(_, { call, select, put }) {
      try {
        const { currentContent } = yield select(({ contentModel }) => ({
          currentContent: contentModel.currentContent,
        }));

        yield call(deleteContents, currentContent);

        yield put({ type: 'getContents' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *putContents({ payload, callback }, { call, put }) {
      try {
        yield call(putContents, payload);

        yield put({ type: 'getContents' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onRefineParamsChange({ payload, callback }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            refineParams: payload,
          },
        });
        yield put({ type: 'getContents' });
        callback && callback();
      } catch (e) {
        console.log(e);
      }
    },
    *onCurrentContentChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentContent: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    *getMCPDocs(_, { call, put }) {
      try {
        const data = yield call(getMCPDocs);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return {};
      }
    },
    *getMCPDocsAdmin(_, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'contentModel',
            loading: true,
          },
        });

        const data = yield call(getMCPDocsAdmin);

        yield put({
          type: 'setState',
          payload: { mcpDocs: data },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'contentModel',
            loading: false,
          },
        });
      }
    },
    *deleteMCPDocs({ payload }, { call, put }) {
      try {
        yield call(deleteMCPDocs, payload);

        yield put({ type: 'getMCPDocsAdmin' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postMCPDocs({ payload, callback }, { call, put }) {
      try {
        yield call(postMCPDocs, payload);

        yield put({ type: 'getMCPDocsAdmin' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
