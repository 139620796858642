import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Form, Row, Col, Radio, Space, Checkbox, Input, Upload } from 'antd';

import './index.less';

class ReportIssue extends React.Component {
  formRef = React.createRef();

  state = {
    loading: false,
  };

  render() {
    const { onCancel, dispatch } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        visible
        width={600}
        className="report-issue-container"
        title="Problem Report"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                this.formRef.current && this.formRef.current.submit();
              }}
            >
              Submit
            </Button>
          </>
        }
      >
        <div className="modal-info">
          We want your feedback and suggestions for our CEC portal! If you have any problems, we
          want to know immediately. "Report a Bug" is intended for you to let us know when things on
          the portal don't work as expected and provides a problem report ID which you can use to
          track progress and to communicate with Ciena. The best place to query API functionality
          (and not report a bug) is the user forum.
        </div>
        <br />
        <Form
          layout="vertical"
          ref={this.formRef}
          onFinish={(values) => {
            this.setState({ loading: true });
            if (values.files && values.files.length > 0) {
              values.files = values.files.map((item) => item.originFileObj);
            }

            dispatch({
              type: 'reportIssueModel/postUsersReportIssues',
              payload: values,
              callback: onCancel,
            }).then(() => this.setState({ loading: false }));
          }}
        >
          <Row>
            <Col span={14}>
              <Form.Item
                label="Report Type"
                name="type"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="Sample Code Error">Sample Code Error</Radio>
                    <Radio value="API Error">API Error</Radio>
                    <Radio value="Documentation">Documentation</Radio>
                    <Radio value="Portal">Portal</Radio>
                    <Radio value="Query">Query</Radio>
                    <Radio value="Other">Other</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <div className="modal-subtitle">How important is this to your development?</div>
              <Form.Item
                label="Severity"
                name="severity"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="Critical">Critical</Radio>
                    <Radio value="Major">Major</Radio>
                    <Radio value="Minor">Minor</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name="reproducible"
                initialValue={false}
                rules={[{ required: true }]}
              >
                <Checkbox>Can reproduce the problem</Checkbox>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="files"
                label="Attachments"
                tooltip=".txt, .text, .xml, .json, .jpg, .jpeg, .png, .gif"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
              >
                <Upload
                  multiple
                  customRequest={({ onSuccess }) => onSuccess()}
                  accept=".txt, .text, .xml, .json, .jpg, .jpeg, .png, .gif"
                >
                  <Button>Choose File</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Problem description and how to reproduce"
            name="problem"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Suggested fix (optional)"
            name="suggestedFix"
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default connect()(ReportIssue);
