import React from 'react';
import { Modal, Form, Button, Input } from 'antd';

export default class DocVideoDetails extends React.Component {
  formRef = React.createRef();

  render() {
    const { docVideo, onCancel, dispatch } = this.props;
    const isClaim = !docVideo.requestor;

    return (
      <Modal
        visible
        width={800}
        title={`${isClaim ? 'Claim' : 'Edit'} Documentation Link`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.formRef.current && this.formRef.current.submit();
              }}
            >
              {isClaim ? 'Claim' : 'Save'}
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
          ref={this.formRef}
          initialValues={docVideo}
          onFinish={(values) => {
            isClaim
              ? dispatch({
                  type: 'docVideoModel/postMediaDocumentationDocumentationLinks',
                  payload: values,
                  callback: onCancel,
                })
              : dispatch({
                  type: 'docVideoModel/patchMediaDocumentationDocumentationLinks',
                  payload: {
                    tag: docVideo.tag,
                    description: values.description,
                  },
                  callback: onCancel,
                });
          }}
        >
          <Form.Item
            name="productType"
            label="Product Type"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="index"
            label="Index"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
