import React from 'react';
import moment from 'moment';
import { Statistic } from 'antd';

class Countdown extends React.Component {
  getFormat = (value = this.props.value) => {
    const day = moment.duration(value).days();

    if (day === 0) return 'HH:mm:ss';

    if (day === 1) return 'D [day] HH:mm:ss';

    return 'D [days] HH:mm:ss';
  };

  state = {
    format: this.getFormat(),
  };

  render() {
    const { onChange } = this.props;
    const { format } = this.state;

    return (
      <Statistic.Countdown
        {...this.props}
        format={format}
        onChange={(value) => {
          format !== this.getFormat(value) && this.setState({ format: this.getFormat(value) });
          onChange && onChange(value);
        }}
      />
    );
  }
}

export default Countdown;
