import React from 'react';
import { Modal, Form, Button, Upload, Image } from 'antd';
import { ROW_KEY } from '../';

class FileDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      file:
        values.file && values.file.length > 0
          ? values.file.map((item) => item.originFileObj)
          : values.file,
    };

    this.setState({
      isSaveDisabled: !(this.values.file && this.values.file.length > 0),
    });
  };

  render() {
    const {
      file: { fileName, path },
      onCancel,
      dispatch,
    } = this.props;
    const { isSaveDisabled } = this.state;
    const isView = this.props.file[ROW_KEY];

    return (
      <Modal
        visible
        width={800}
        title={`${isView ? 'View' : 'New'} Device Image`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {!isView && (
              <Button
                type="primary"
                disabled={isSaveDisabled}
                onClick={() => {
                  dispatch({
                    type: 'deviceImageModel/postFiles',
                    payload: this.values,
                    callback: onCancel,
                  });
                }}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        {isView ? (
          <Image
            width="100%"
            height="100%"
            src={`${path}/${fileName}`}
          />
        ) : (
          <Form
            layout="vertical"
            onValuesChange={(_, values) => this.onValuesChange(values)}
          >
            <Form.Item
              name="file"
              label="Upload Device Image"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
            >
              <Upload
                maxCount={1}
                customRequest={({ onSuccess }) => onSuccess()}
              >
                <Button>Choose File</Button>
              </Upload>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default FileDetails;
