import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Modal, Radio, Checkbox, Collapse } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import BannerDetails from './banner-details';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'body',
    key: 'body',
    render: (body) => <span dangerouslySetInnerHTML={{ __html: body }} />,
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (checked) => (
      <Radio
        checked={checked}
        disabled
      />
    ),
  },
];

export const ROW_KEY = '_id';

class AdminLandingPage extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'landingPageBannerModel/getLandingPage',
    });

    this.state = {
      showModal: false,
    };
  }

  showBannerDetails = () => {
    this.setState({ showModal: true });
  };

  hideBannerDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      landingPageBannerModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'landingPageBannerModel/getLandingPage',
              payload: values,
            });
            this.props.dispatch({
              type: 'landingPageBannerModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Creator"
            name="creator"
          >
            <Input />
          </Form.Item>
          <Collapse
            ghost
            defaultActiveKey={['active']}
          >
            <Collapse.Panel
              header="Active"
              key="active"
            >
              <Form.Item name="active">
                <Checkbox.Group>
                  <Checkbox value={true}>Yes</Checkbox>
                  <Checkbox value={false}>No</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      landingPageBannerModel: { currentBanner },
    } = this.props;

    return currentBanner[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove the banner.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'landingPageBannerModel/deleteLandingPage',
                  });
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={this.showBannerDetails}
          >
            Edit
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentBannerChange = (record = {}) => {
    const {
      landingPageBannerModel: { currentBanner },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentBanner[ROW_KEY] ? {} : record;
    dispatch({ type: 'landingPageBannerModel/onCurrentBannerChange', payload });
  };

  render() {
    const {
      landingPageBannerModel: { loading, landingPageBanners, currentBanner },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#8d6ccf"
          title="Landing Page Management"
          subtitle="Manage the content that appears in the CEC"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => {
                this.onCurrentBannerChange();
                this.showBannerDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={landingPageBanners}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentBanner[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentBannerChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentBannerChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <BannerDetails
            onCancel={this.hideBannerDetails}
            dispatch={dispatch}
            banner={currentBanner}
          />
        )}
      </div>
    );
  }
}

export default connect(({ landingPageBannerModel }) => ({
  landingPageBannerModel,
}))(AdminLandingPage);
