import React from 'react';
import { Row, Col, Button } from 'antd';

import './index.less';

const HomeRows = (props) => {
  return (
    <>
      <div className="home-row-1-container">
        <Row className="home-row-1 container">
          <Col
            xs={{ span: 6 }}
            className="home-row-1-col-1"
          >
            <h2>Customize</h2>
            <p>
              Allow end-users to develop unique and customizable operational tools tailored to
              specific end-user requirements without investment in the IT infrastructure.
            </p>
          </Col>
          <Col
            xs={{ span: 6, offset: 3 }}
            className="home-row-1-col-2"
          >
            <h2>Innovate</h2>
            <p>
              Allow end-users innovate, experiment and test new service models with dramatically
              lower cost of entry and exit.
            </p>
          </Col>
          <Col
            xs={{ span: 6, offset: 3 }}
            className="home-row-1-col-3"
          >
            <h2>Accelerate</h2>
            <p>
              Accelerate service velocity by creating tools for multi-vendor / multi-layer networks
              de-coupled from individual vendor equipment implementations.
            </p>
          </Col>
        </Row>
      </div>
      <div className="home-row-2-container">
        <img
          src={require('../../../assets/img/home/row-2.png')}
          alt="Path to success"
        />
        <Row className="home-row-2 container">
          <Col
            xs={{ span: 6, offset: 9 }}
            className="home-row-2-col-2"
          >
            <p>
              Ciena Emulation Cloud: An open and new application development environment designed to
              help customers and 3rd party developers create, test and fine-tune customized
              applications.
            </p>
          </Col>
          <Col
            xs={6}
            className="home-row-2-col-3"
          >
            <p>End users can create, test and fine-tune apps based on their own needs</p>
          </Col>
        </Row>
      </div>
      <div className="home-row-3-container">
        <Row className="home-row-3 container">
          <Col xs={{ span: 20, offset: 2 }}>
            <h2>Why Ciena?</h2>
            <ul>
              <li>
                Ciena is the network specialist, recognized by numerous analysts to be a leader in
                optical networking and technology innovation
              </li>
              <li>
                Deep expertise in packet and optical networking and distributed software automation
                to deliver solutions in alignment with Ciena’s OPn architecture for next-generation
                networks.
              </li>
              <li>
                A complete portfolio that enables a high-scale, programmable infrastructure that can
                be controlled and adapted by network-level applications
              </li>
              <li>
                Open interfaces to coordinate computing, storage and network resources in a unified,
                virtualized environment
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="home-row-4-container">
        <h2>Sign up now to get started</h2>
        <Button
          className="btn-action"
          rel="noreferrer"
          href="https://my.ciena.com/CienaPortal/s/SelfRegisterForm"
          target="_blank"
        >
          Create an account
        </Button>
      </div>
    </>
  );
};

export default HomeRows;
