import React from 'react';
import { Modal, Form, Input, Select, Button, Descriptions, Checkbox, Typography } from 'antd';
import { ROW_KEY, permissionList } from '../';

class PermissionDetails extends React.Component {
  formRef = React.createRef();
  subFormRef = React.createRef();

  state = {
    isSaveDisabled: true,
  };

  onValuesChange = () => {
    this.values = {
      ...this.props.permission,
      ...this.formRef.current.getFieldsValue(true),
    };

    this.setState({
      isSaveDisabled:
        !this.values.name || !this.values.permissions || !this.values.permissions.length,
    });
  };

  render() {
    const { permission, onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;
    const isView = this.props.permission[ROW_KEY];

    return (
      <Modal
        visible
        width={600}
        title={`${isView ? 'View' : 'New'} Permission`}
        closable={false}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {!isView && (
              <Button
                type="primary"
                disabled={isSaveDisabled}
                onClick={() => {
                  dispatch({
                    type: 'permissionModel/postUserPermissions',
                    payload: this.values,
                    callback: onCancel,
                  });
                }}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        {isView ? (
          <Descriptions labelStyle={{ width: 120 }}>
            <Descriptions.Item
              span={3}
              label="Name"
            >
              {permission.name}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label="Updated At"
            >
              {permission.updatedAt}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label="Permissions"
            >
              {permissionList(permission.permissions)}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <>
            <Form
              labelAlign="left"
              labelCol={{ span: 5 }}
              ref={this.formRef}
              initialValues={permission}
              onValuesChange={this.onValuesChange}
            >
              <Form.Item
                label="Name"
                name="name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                shouldUpdate
                label="Permissions"
              >
                {({ getFieldValue, setFieldValue }) => {
                  const permissions = getFieldValue('permissions') || [];
                  return permissions.length ? (
                    permissionList(permissions, () => setFieldValue('permissions', permissions))
                  ) : (
                    <Typography.Text
                      className="ant-form-text"
                      type="secondary"
                    >
                      ( No permission yet. )
                    </Typography.Text>
                  );
                }}
              </Form.Item>
            </Form>
            <Form
              className="sub-form"
              labelAlign="left"
              labelCol={{ span: 5 }}
              ref={this.subFormRef}
              onFinish={(values) => {
                const isJSON = (str) => {
                  try {
                    JSON.parse(str);
                  } catch (e) {
                    return false;
                  }
                  return true;
                };
                values.conditions = isJSON(values.conditions)
                  ? JSON.parse(values.conditions)
                  : values.conditions;

                this.formRef.current.setFieldValue('permissions', [
                  ...(this.formRef.current.getFieldValue('permissions') || []),
                  values,
                ]);
                this.subFormRef.current.resetFields();
                this.onValuesChange();
              }}
            >
              <Form.Item
                label="Action"
                name="action"
              >
                <Select
                  showArrow
                  mode="multiple"
                  options={['manage', 'access', 'create', 'read', 'update', 'delete', 'view'].map(
                    (item) => ({ label: item, value: item })
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
              >
                <Select
                  showArrow
                  mode="multiple"
                  options={['all', 'Docs', 'Videos', 'Labs', 'File Uploads', 'Analytics'].map(
                    (item) => ({ label: item, value: item })
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Fields"
                name="fields"
              >
                <Select mode="tags" />
              </Form.Item>
              <Form.Item
                label="Conditions"
                name="conditions"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Reason"
                name="reason"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Inverted"
                name="inverted"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                >
                  Add
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    );
  }
}

export default PermissionDetails;
