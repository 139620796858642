import React from 'react';

class LabTerminal extends React.Component {
  render() {
    const { match } = this.props;

    if (!match.params.webTermLink) return null;

    return (
      <iframe
        title={window.name}
        src={decodeURIComponent(match.params.webTermLink)}
      />
    );
  }
}

export default LabTerminal;
