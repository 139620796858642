import React from 'react';
import { Button, Tabs, Collapse } from 'antd';
import ActionPanel, { setCommitButtonDisabled } from '@/components/sim-actions/action-panel';

const NETWORKID = 'Networks';

class DashboardActions extends React.Component {
  componentDidMount() {
    setCommitButtonDisabled(true);
  }

  render() {
    const { dispatch, simActions, inventory } = this.props;

    return (
      <div>
        <Tabs
          destroyInactiveTabPane
          className="dashboard-actions-container"
        >
          {simActions.map((item) => (
            <Tabs.TabPane
              key={item._id || NETWORKID}
              tab={item.vd_id || NETWORKID}
            >
              {item[NETWORKID] ? (
                <Collapse
                  className="highlight-collapse-header"
                  accordion
                  ghost
                  destroyInactivePanel
                  bordered={false}
                >
                  {item[NETWORKID].map((network) => (
                    <Collapse.Panel
                      header={network.vd_id}
                      key={network._id}
                    >
                      <ActionPanel
                        forwardedRef={(ref) => {
                          this.actionPanelRef = ref;
                        }}
                        dispatch={dispatch}
                        simActions={network}
                        inventory={inventory.find(({ vd_id }) => network.vd_id === vd_id) || {}}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              ) : (
                <ActionPanel
                  forwardedRef={(ref) => {
                    this.actionPanelRef = ref;
                  }}
                  dispatch={dispatch}
                  simActions={item}
                  inventory={inventory.find(({ vd_id }) => item.vd_id === vd_id) || {}}
                />
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <div style={{ marginTop: 10, float: 'right' }}>
          <Button
            id="commit-button"
            type="primary"
            onClick={() => {
              if (!this.actionPanelRef) return;
              dispatch({
                type: 'dashboardModel/postActionsRequest',
                payload: this.actionPanelRef.getActionRequest(),
                callback: this.actionPanelRef.resetActionRequest(),
              });
            }}
          >
            Commit
          </Button>
        </div>
      </div>
    );
  }
}

export default DashboardActions;
