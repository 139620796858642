import React from 'react';
import classNames from 'classnames';
import { Row, Col, Card, Select, Input, InputNumber, Form } from 'antd';

import './index.less';

export const setCommitButtonDisabled = (disabled) => {
  const commitButton = document.getElementById('commit-button');
  if (!commitButton) return;
  commitButton.disabled = disabled;
};

class ActionPanel extends React.Component {
  constructor(props) {
    super(props);

    props.forwardedRef && props.forwardedRef(this);
    this.actionFormRef = React.createRef();
  }

  getActionRequest = () => {
    const { isNetwork, simActions } = this.props;
    const { action, ...actionRequestParams } = this.actionFormRef.current.getFieldsValue(true);

    return Object.assign(isNetwork ? {} : { vd_id: simActions.vd_id }, {
      request_id: simActions.request_id,
      action,
      actionRequestParams,
    });
  };

  resetActionRequest = () => {
    this.actionFormRef.current.resetFields();
  };

  renderActions = (actions = {}) => {
    const responseValueParam = actions.responseValueParam;

    if (!responseValueParam) {
      setCommitButtonDisabled(false);
      return null;
    }

    if (this.actionFormRef.current) {
      // clear form values after form rendering completes
      const value = this.actionFormRef.current.getFieldValue(responseValueParam);

      if (value === undefined || (actions.data && actions.data[value] === undefined)) {
        const newValue = actions.previousSelection ? actions.previousSelection.value : undefined;

        if (value !== newValue) {
          setTimeout(() => {
            newValue === undefined && setCommitButtonDisabled(true);
            this.actionFormRef.current.setFieldValue(responseValueParam, newValue);
          });
        }
      }
    }

    return (
      <Form.Item
        shouldUpdate={(prevValue, curValue) =>
          prevValue[responseValueParam] !== curValue[responseValueParam]
        }
      >
        {({ getFieldValue }) => {
          const value = getFieldValue(responseValueParam);

          let formItemProps = {};
          if (actions.dataContainer === 'pullDownList') {
            formItemProps.children = (
              <Select
                placeholder={actions.sectionLabel}
                options={Object.keys(actions.data).map((key) => ({
                  label: actions.data[key],
                  value: key,
                }))}
              />
            );
          } else if (actions.dataContainer === 'textBox') {
            const isNumType = actions.validation.type === 'double';

            formItemProps.rules = [
              {
                ...actions.validation,
                type: isNumType ? 'number' : actions.validation.type,
              },
            ];

            formItemProps.children = isNumType ? (
              <InputNumber placeholder={actions.sectionLabel} />
            ) : (
              <Input placeholder={actions.sectionLabel} />
            );
          }

          return (
            <>
              <Form.Item
                labelAlign="left"
                labelCol={{ span: 5 }}
                tooltip={actions.comment}
                name={responseValueParam}
                label={actions.dataContainerlabel}
                {...formItemProps}
              />
              {value === undefined
                ? setCommitButtonDisabled(true)
                : this.renderActions(actions[value])}
            </>
          );
        }}
      </Form.Item>
    );
  };

  render() {
    const { simActions, inventory } = this.props;

    return (
      <Row
        wrap={false}
        gutter={[16, 16]}
        className="cols-container"
      >
        <Col span={8}>
          <Card
            type="inner"
            title={
              inventory.inventoryStatuses
                ? `Inventory Modules: ${
                    inventory.inventoryStatuses.filter(({ status }) => status === 'EMPTY').length
                  }/${inventory.inventoryStatuses.length} Available`
                : 'Inventory n/a'
            }
          >
            {(inventory.inventoryStatuses || []).map((item, index) => (
              <div
                key={`inventory-${index}`}
                className="inventory-item"
              >
                <span
                  className={classNames(
                    'inventory-status',
                    `status-${item.status}`,
                    `configstate-${item.configstate}`
                  )}
                />
                <span>
                  {item.label + item.separator + item.location + item.separator}
                  <span className={classNames('configstate', item.configstate)}>
                    {item.configstate}
                  </span>
                </span>
              </div>
            ))}
          </Card>
        </Col>
        <Col span={16}>
          <Card
            type="inner"
            title="Action"
          >
            <Form
              labelWrap
              ref={this.actionFormRef}
            >
              {this.renderActions(simActions.actions || simActions.networkActions)}
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ActionPanel;
