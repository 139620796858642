import {
  getClassroomsActive,
  getClassroomsActiveAdmin,
  getClassroomsGlobalActions,
  getLabsActions,
  getClassroomsGlobalActionsAdmin,
  getLabsActionsAdmin,
  postClassroomsGlobalActionsRequest,
  postLabsActionsRequest,
  getClassroomsGlobalActionsInventory,
  getLabsActionsInventory,
  getClassroomsGlobalActionsInventoryAdmin,
  getLabsActionsInventoryAdmin,
  getClassroomsGlobalActionsStatuses,
  getClassroomsGlobalActionsStatusesAdmin,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'dashboardModel',
  state: {
    classrooms: [],
    currentClassroom: {},
    currentStudent: {},
    simActions: [],
    inventory: [],
    actionStatuses: [],
  },
  subscriptions: {},
  effects: {
    *getClassroomsActive({ isSSE }, { call, select, put }) {
      try {
        if (!isSSE) {
          yield put({
            type: 'appModel/setTableLoading',
            payload: {
              modelName: 'dashboardModel',
              loading: true,
            },
          });
        }

        const { adminRole, currentClassroom } = yield select(({ userModel, dashboardModel }) => ({
          adminRole: userModel.adminRole,
          currentClassroom: dashboardModel.currentClassroom,
        }));

        const data = yield call(adminRole ? getClassroomsActiveAdmin : getClassroomsActive);

        const classroom =
          data.find(({ classroomId }) => classroomId === currentClassroom.classroomId) ||
          data[0] ||
          {};

        yield put({ type: 'setState', payload: { classrooms: data } });
        yield put({ type: 'onCurrentClassroomChange', payload: classroom });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'dashboardModel',
            loading: false,
          },
        });
      }
    },
    *postActionsRequest({ payload, callback }, { put, select, call }) {
      try {
        const { currentClassroom, currentStudent } = yield select(({ dashboardModel }) => ({
          currentClassroom: dashboardModel.currentClassroom,
          currentStudent: dashboardModel.currentStudent,
        }));

        const { data } =
          currentStudent.role === 'global'
            ? yield call(postClassroomsGlobalActionsRequest, {
                classroomId: currentClassroom.classroomId,
                ...payload,
              })
            : yield call(postLabsActionsRequest, payload);

        callback && callback();

        // TODO: need to find a better way to show success or fail
        if (data.fail) {
          message.error(data.fail);
        } else {
          message.success('Success');
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getActions({ payload }, { put, select, call }) {
      try {
        const { adminRole, currentClassroom } = yield select(({ userModel, dashboardModel }) => ({
          adminRole: userModel.adminRole,
          currentClassroom: dashboardModel.currentClassroom,
        }));

        let data = [];
        if (payload.role === 'global') {
          data = adminRole
            ? yield call(getClassroomsGlobalActionsAdmin, {
                classroomId: currentClassroom.classroomId,
              })
            : yield call(getClassroomsGlobalActions, undefined, {
                classroomId: currentClassroom.classroomId,
              });
        } else if (payload.username) {
          data = adminRole
            ? yield call(getLabsActionsAdmin, {
                classroomId: currentClassroom.classroomId,
                bookingOwner: payload.username,
              })
            : yield call(getLabsActions, undefined, {
                classroomId: currentClassroom.classroomId,
                bookingOwner: payload.username,
              });
        }

        yield put({
          type: 'setState',
          payload: { simActions: data },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getInventory({ payload }, { put, select, call }) {
      try {
        const { adminRole, currentClassroom } = yield select(({ userModel, dashboardModel }) => ({
          adminRole: userModel.adminRole,
          currentClassroom: dashboardModel.currentClassroom,
        }));

        let data = [];
        if (payload.role === 'global') {
          data = adminRole
            ? yield call(getClassroomsGlobalActionsInventoryAdmin, {
                classroomId: currentClassroom.classroomId,
              })
            : yield call(getClassroomsGlobalActionsInventory, undefined, {
                classroomId: currentClassroom.classroomId,
              });
        } else if (payload.username) {
          data = adminRole
            ? yield call(getLabsActionsInventoryAdmin, {
                classroomId: currentClassroom.classroomId,
                bookingOwner: payload.username,
              })
            : yield call(getLabsActionsInventory, undefined, {
                classroomId: currentClassroom.classroomId,
                bookingOwner: payload.username,
              });
        }

        yield put({
          type: 'setState',
          payload: { inventory: data },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getClassroomsGlobalActionsStatuses({ payload }, { put, select, call }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        let data = [];
        if (payload.classroomId) {
          data = adminRole
            ? yield call(getClassroomsGlobalActionsStatusesAdmin, {
                classroomId: payload.classroomId,
              })
            : yield call(getClassroomsGlobalActionsStatuses, undefined, {
                classroomId: payload.classroomId,
              });
        }

        yield put({
          type: 'setState',
          payload: { actionStatuses: data },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentClassroomChange({ payload }, { put, select }) {
      try {
        yield put({
          type: 'setState',
          payload: { currentClassroom: payload },
        });

        const { currentStudent } = yield select(({ dashboardModel }) => ({
          currentStudent: dashboardModel.currentStudent,
        }));

        const data = payload.students || [];
        let student = currentStudent;
        if (currentStudent.role !== 'global' && currentStudent.role !== 'instructor') {
          student = data.find(({ username }) => username === currentStudent.username) || {};
        }

        yield put({ type: 'getClassroomsGlobalActionsStatuses', payload });
        yield put({ type: 'onCurrentStudentChange', payload: student });
      } catch (e) {
        console.log(e);
      }
    },
    *onCurrentStudentChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: { currentStudent: payload },
        });
        yield put({ type: 'getActions', payload });
        yield put({ type: 'getInventory', payload });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
