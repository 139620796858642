import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { Modal, Button, Form, Input, Select, Checkbox, DatePicker } from 'antd';
import UserSelect from '@/components/user-select';
import { TIME_FORMAT, DATE_TIME_FORMAT } from '@/constants';

class UpdateBooking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaveDisabled: true,
    };

    this.formRef = React.createRef();

    this.getUserLabDomains(props.lab.bookingOwner);
  }

  getUserLabDomains = (username) => {
    const { dispatch } = this.props;

    dispatch({ type: 'labModel/getUserLabDomains', payload: username }).then((userDomains) => {
      this.setState({ userDomains });
    });
  };

  onValuesChange = () => {
    this.values = this.formRef.current.getFieldsValue(true);

    this.setState({
      isSaveDisabled:
        !this.values.title &&
        !this.values.bookingDomain &&
        !this.values.bookingOwner &&
        !this.values.endTime &&
        this.values.userVisible === undefined,
    });
  };

  setFieldsValue = (values) => {
    this.formRef.current.setFieldsValue(values);
    this.onValuesChange(values);
  };

  render() {
    const {
      lab,
      onCancel,
      userModel: { profile },
      dispatch,
    } = this.props;
    const { isSaveDisabled, userDomains } = this.state;

    return (
      <Modal
        visible
        title="Update Booking"
        width={600}
        closable={false}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                const { bookingId, bookingOwner, bookingDomain, title, endTime, userVisible } = this.values;

                let payload = { bookingId };
                if (bookingOwner !== lab.bookingOwner) {
                  payload.bookingOwner = bookingOwner;
                }
                if (bookingDomain !== lab.bookingDomain) {
                  payload.bookingDomain = bookingDomain;
                }
                if (title !== lab.title) {
                  payload.title = title;
                }
                if (userVisible !== lab.userVisible) {
                  payload.userVisible = userVisible;
                }
                if (!moment(endTime).isSame(moment(lab.endTime))) {
                  payload.endTime = moment(endTime).format();
                }

                dispatch({
                  type: 'scheduleModel/patchLabsBookingsAdminUpdateBooking',
                  payload,
                  callback: onCancel,
                });
              }}
            >
              Update
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          ref={this.formRef}
          initialValues={{ ...lab, endTime: moment(lab.endTime) }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Owner"
            name="bookingOwner"
          >
            <UserSelect
              onChange={(value) => {
                this.getUserLabDomains(value);

                if (profile.portalDomain === 'COMMUNITY') return;
                this.setFieldsValue({ bookingDomain: undefined });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Domain"
            name="bookingDomain"
          >
            <Select
              options={userDomains}
              fieldNames={{ label: 'domainName', value: 'domainName' }}
            />
          </Form.Item>
          <Form.Item
            label="End Time"
            name="endTime"
          >
            <DatePicker
              inputReadOnly
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            name="userVisible"
            valuePropName="checked"
          >
            <Checkbox>User Visible</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default connect(({ userModel }) => ({
  userModel,
}))(UpdateBooking);
