import React from 'react';
import { getSearchParam } from '@/utils';
import { Collapse, Spin, Row, Col } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';

class ContentPanel extends React.Component {
  state = {
    loading: true,
    contents: [],
  };

  hasRead = {};

  getContents = () => {
    const { category, sub_category, dispatch } = this.props;

    dispatch({
      type: 'contentModel/getContentsByParams',
      payload: { category, sub_category },
    }).then((data) => this.setState({ contents: data, loading: false }));
  };

  componentDidMount() {
    this.getContents();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.category !== prevProps.category ||
      this.props.sub_category !== prevProps.sub_category
    ) {
      this.getContents();
    }
  }

  render() {
    const { redirect, category, sub_category } = this.props;
    const { loading, contents } = this.state;

    return (
      <Spin spinning={loading}>
        {contents.length > 0 ? (
          <Collapse
            accordion
            ghost
            bordered={false}
            className="folder-collapse"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
                {isActive ? <FolderOpenOutlined /> : <FolderOutlined />}
              </span>
            )}
            activeKey={getSearchParam('title')}
            onChange={(title) => {
              redirect({ ...getSearchParam(), title });

              /* Matomo */
              if (title && !this.hasRead[title]) {
                let customDimensionsObj = {};
                customDimensionsObj[
                  `dimension${window._platformInfo.customDimensions.ViewDocument}`
                ] = title;
                _paq.push([
                  'trackEvent',
                  'Document',
                  'Read',
                  JSON.stringify({
                    category,
                    subCategory: sub_category,
                    title,
                  }),
                  undefined,
                  customDimensionsObj,
                ]);

                this.hasRead[title] = true;
              }
            }}
          >
            {contents.map((content) => (
              <Collapse.Panel
                key={content.title}
                header={content.title}
              >
                <Row gutter={16}>
                  <Col span={20}>
                    <span dangerouslySetInnerHTML={{ __html: content.body }} />
                  </Col>
                  <Col span={4}>
                    <div className="attachment-container">
                      <h3>Attachments</h3>
                      {content.files && content.files.length > 0 ? (
                        <>
                          {content.files.map((file) => (
                            <a
                              download={file.filename}
                              key={file.filename}
                              href={`/${file.path}/${file.filename}`}
                              title={file.originalName || file.filename}
                              onClick={() => {
                                /* Matomo */
                                _paq.push([
                                  'trackEvent',
                                  'Document',
                                  'Download',
                                  JSON.stringify({
                                    category,
                                    subCategory: sub_category,
                                    title: content.title,
                                    downloadName: file.filename,
                                  }),
                                ]);
                              }}
                            >
                              {file.originalName || file.filename}
                            </a>
                          ))}
                        </>
                      ) : (
                        <p>No attachment</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <span>No Content</span>
        )}
      </Spin>
    );
  }
}

export default ContentPanel;
