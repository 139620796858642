import {
  getClassrooms,
  getClassroomsAdmin,
  patchClassroomAddStudents,
  patchClassroomRemoveStudents,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'studentModel',
  state: {
    classrooms: [],
    currentClassroom: {},
  },
  subscriptions: {},
  effects: {
    *getClassrooms({ isSSE }, { call, put, select }) {
      try {
        if (!isSSE) {
          yield put({
            type: 'appModel/setTableLoading',
            payload: {
              modelName: 'studentModel',
              loading: true,
            },
          });
        }

        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const classrooms = yield call(adminRole ? getClassroomsAdmin : getClassrooms, {
          status: ['IDLE', 'CREATED', 'RUNNING', 'ACTIVE'],
        });

        if (classrooms.length) {
          const { currentClassroom } = yield select(({ studentModel }) => ({
            currentClassroom: studentModel.currentClassroom,
          }));

          yield put({
            type: 'setState',
            payload: {
              classrooms,
              currentClassroom:
                classrooms.find(
                  ({ classroomId }) => classroomId === currentClassroom.classroomId
                ) || classrooms[0],
            },
          });
        } else {
          yield put({
            type: 'setState',
            payload: {
              classrooms,
              currentClassroom: {},
              refineParams: undefined,
            },
          });
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'studentModel',
            loading: false,
          },
        });
      }
    },
    *patchClassroomAddStudents({ payload, callback }, { call, put }) {
      try {
        yield call(patchClassroomAddStudents, payload);

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchClassroomRemoveStudents({ payload }, { call, put }) {
      try {
        yield call(patchClassroomRemoveStudents, payload);

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
