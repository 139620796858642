import React from 'react';
import moment from 'moment';
import { Modal, Form, Input, DatePicker, Checkbox, Button } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import { ROW_KEY } from '../';

class EventDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...this.props.event,
      ...values,
      body: values.body && values.body.editor ? values.body.editor.getData() : values.body,
      startTime: values.startTime ? values.startTime.format() : undefined,
      endTime: values.endTime ? values.endTime.endOf('day').format() : undefined,
      published: !!values.published,
    };

    this.setState({
      isSaveDisabled:
        !this.values.title || !this.values.body || !this.values.startTime || !this.values.endTime,
    });
  };

  render() {
    const {
      event: { title, body, startTime, endTime, published },
      onCancel,
      dispatch,
    } = this.props;
    const { isSaveDisabled } = this.state;
    const isEdit = this.props.event[ROW_KEY];

    return (
      <Modal
        visible
        width={800}
        title={`${isEdit ? 'Edit' : 'New'} Home Page Event`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: `portalEventModel/${isEdit ? 'patchPortalEvents' : 'postPortalEvents'}`,
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 3 }}
          initialValues={{
            title,
            body,
            startTime: moment(startTime),
            endTime: moment(endTime),
            published,
          }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Body"
            name="body"
          >
            <CKEditor initData={body} />
          </Form.Item>
          <Form.Item
            label="Start Time"
            name="startTime"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="End Time"
            name="endTime"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="published"
            valuePropName="checked"
            extra={
              <p className="highlight-info">
                Only published content can be viewed by non admin users.
              </p>
            }
          >
            <Checkbox>Published</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default EventDetails;
