import {
  getFileUploadsFiles,
  getFileUploadsUploadTypesList,
  postFileUploads,
  deleteFileUploads,
  getFileUploadsUploadType,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'fileUploadModel',
  state: {
    fileUploads: {},
    fileUploadTypes: {},
  },
  subscriptions: {},
  effects: {
    *getFileUploadsFiles({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'fileUploadModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ fileUploadModel }) => ({
          refineParams: fileUploadModel.refineParams,
        }));
        const uploadDomain = payload
          ? payload.uploadDomain
          : refineParams && refineParams.uploadDomain;

        const data = yield call(getFileUploadsFiles, payload || refineParams);

        if (!uploadDomain) {
          const fileUploads = data.find(({ uploads }) => uploads.length) || data[0];
          yield put({
            type: 'setState',
            payload: {
              fileUploads,
              refineParams: { uploadDomain: fileUploads.domain },
            },
          });
        } else {
          const fileUploads = data.find(({ domain }) => uploadDomain === domain) || data[0];
          yield put({
            type: 'setState',
            payload: {
              fileUploads,
            },
          });
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'fileUploadModel',
            loading: false,
          },
        });
      }
    },
    *getFileUploadsUploadTypesList(_, { call, put }) {
      try {
        const data = yield call(getFileUploadsUploadTypesList);

        yield put({
          type: 'setState',
          payload: {
            fileUploadTypes: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postFileUploads({ payload, callback }, { call, put }) {
      try {
        const data = yield call(postFileUploads, payload, { domain: payload.uploadDomain });

        yield put({ type: 'getFileUploadsFiles' });
        message.success('Success');
        callback && callback();

        /* Matomo */
        yield put({
          type: 'trackUserFilesEvent',
          payload: { action: 'Upload', nameObj: { ...payload, files: data.files } },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteFileUploads({ payload, callback }, { all, call, put }) {
      try {
        yield call(deleteFileUploads, {
          uploadDomain: payload.uploadDomain,
          uploadNames: payload.uploads.map((item) => item.full_name),
        });

        yield put({ type: 'getFileUploadsFiles' });
        message.success('Success');
        callback && callback();

        /* Matomo */
        yield all(
          payload.uploads.map((upload) =>
            put({
              type: 'trackUserFilesEvent',
              payload: {
                action: 'Delete',
                nameObj: {
                  uploadDomain: payload.uploadDomain,
                  ...upload,
                },
              },
            })
          )
        );
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getFileUploadsUploadType({ payload }, { call, put }) {
      try {
        const data = yield call(getFileUploadsUploadType, payload);
        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return [];
      }
    },
    /* Matomo */
    *trackUserFilesEvent({ payload }) {
      const { action, nameObj } = payload;
      const files = Array.isArray(nameObj.files) ? nameObj.files : Object.keys(nameObj.files);

      _paq.push([
        'trackEvent',
        'User Files',
        action,
        JSON.stringify({
          uploadDomain: nameObj.uploadDomain || nameObj.domain,
          uploadType: nameObj.uploadType || nameObj.type,
          uploadName: nameObj.uploadName || nameObj.name,
          files,
        }),
      ]);

      yield;
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
