import React from 'react';
import { Cascader } from 'antd';

export default class FileUpload extends React.Component {
  state = {
    fileUploadList: [],
  };

  componentDidMount() {
    const { dispatch, uploadType } = this.props;

    dispatch({
      type: 'fileUploadModel/getFileUploadsUploadType',
      payload: { uploadType },
    }).then((data) => {
      let fileUploadList = [];
      data.forEach((item) => {
        if (item.uploads.length) {
          fileUploadList.push(item);
        }
      });
      this.setState({ fileUploadList });
    });
  }

  render() {
    const { uploadDomain, uploadName, onChange } = this.props;
    const { fileUploadList } = this.state;

    return fileUploadList.length ? (
      <Cascader
        expandTrigger="hover"
        fieldNames={{ label: 'uploadName', value: 'uploadFullName' }}
        displayRender={(labels) => labels[labels.length - 1]}
        value={[uploadDomain, uploadName]}
        onChange={(_, values) => {
          onChange &&
            onChange(
              values
                ? {
                    uploadDomain: values[0].uploadFullName,
                    uploadName: values[1].uploadFullName,
                  }
                : null
            );
        }}
        options={fileUploadList
          .filter((item) => item.uploads && item.uploads.length)
          .map((item) => ({
            uploadName: item.domain,
            uploadFullName: item.domain,
            children: item.uploads,
          }))}
      />
    ) : null;
  }
}
