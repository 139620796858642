import React from 'react';
import { Select } from 'antd';
import SwaggerUI from 'swagger-ui';
import Cookie from 'js-cookie';
import { getRequest } from '@/services';
import { SWAGGER_FILE_PATH } from '@/constants';
import 'swagger-ui/dist/swagger-ui.css';
import './index.less';

class SwaggerUIApp extends React.Component {
  constructor(props) {
    super(props);

    this.swaggerRef = React.createRef();
    this.state = {
      loading: true,
      swaggerOptions: [],
    };

    this.getSwaggerFile(props.swagger_file || props.virtualDevice.swagger_file);
  }

  getSwaggerFile = (fileName) => {
    const { link = '', basePath } = this.props;
    const { loading } = this.state;

    !loading && this.setState({ loading: true });

    getRequest(`${SWAGGER_FILE_PATH}/${fileName}`).then((spec) => {
      this.setState({ loading: false });

      if (Array.isArray(spec)) {
        this.setState({ swaggerOptions: spec });
      } else if (spec.swagger) {
        // Remove protocol
        spec.host = link.replace(/(^\w+:|^)\/\//, '');
        spec.schemes = ['https'];
        spec.basePath = spec.basePath || basePath;

        this.initSwaggerUI({ spec });
      } else {
        this.initSwaggerUI({ spec: {} });
      }
    });
  };

  initSwaggerUI = (configs) => {
    const {
      headers,
      cookies,
      virtualDevice: {
        api_6500_cookie,
        api_6500_x_xsrf_token,
        api_token,
        api_username,
        api_password,
      },
    } = this.props;

    SwaggerUI({
      domNode: this.swaggerRef.current,
      docExpansion: 'none',
      requestInterceptor: (request) => {
        request.url = decodeURIComponent(request.url);
        if (headers) {
          Object.entries(headers).forEach(([key, val]) => {
            request.headers[key] = val;
          });
        } else if (api_6500_cookie || api_6500_x_xsrf_token) {
          request.headers['X-XSRF-TOKEN'] = api_6500_x_xsrf_token;
          request.headers['apicookie'] = api_6500_cookie;
        } else if (api_token) {
          request.headers['Authorization'] = 'Bearer ' + api_token;
        } else {
          request.headers['Authorization'] =
            'Basic ' + window.btoa(api_username + ':' + api_password);
        }

        if (cookies) {
          let cookieStr = '';
          Object.entries(cookies).forEach(([key, val]) => {
            let cookieAttr = {};
            if (key.startsWith('__Host-')) {
              cookieAttr = { secure: true, path: '/' };
            } else if (key.startsWith('__Secure-')) {
              cookieAttr = { secure: true };
            }
            Cookie.set(key, val, cookieAttr);

            cookieStr += `${key}=${val};`;
          });
          request.headers['Cookie'] = cookieStr;
        }

        return request;
      },
      ...configs,
    });
  };

  render() {
    const { loading, swaggerOptions } = this.state;

    return (
      <div className="swagger-ui-app-container">
        {swaggerOptions.length > 0 && (
          <Select
            showSearch
            placeholder="Please select an API"
            options={swaggerOptions}
            onChange={(value) => this.getSwaggerFile(value)}
          />
        )}
        {loading && <p>Loading Swagger...</p>}
        <div ref={this.swaggerRef} />
      </div>
    );
  }
}

export default SwaggerUIApp;
