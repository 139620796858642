import React from 'react';
import moment from 'moment';
import {
  Button,
  Input,
  Tabs,
  Row,
  Col,
  Form,
  Checkbox,
  Collapse,
  Timeline,
  Image,
  Tooltip,
} from 'antd';
import AppIcon from '@/components/app-icon';
import { DATE_TIME_FORMAT, DEVICE_IMAGE_PATH } from '@/constants';
import copyToClipboard from '@/utils/copyToClipboard';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_ADMINLABDETAILS, EVENTS_USERLABDETAILS } from '@/services';
import ExternalAccess from '../external-access';

export default class DetailsPanel extends React.Component {
  state = {
    lab: this.props.lab,
    virtualDevices: [],
    virtualDeviceId: '',
  };

  componentDidMount() {
    /* Matomo */
    const { lab, dispatch } = this.props;
    dispatch({
      type: 'labModel/trackLabEvent',
      payload: {
        action: 'Details Page',
        nameObj: { title: lab.title, bookingId: lab.bookingId, configs: lab.labConfigs },
      },
    });
    dispatch({
      type: 'analyticsModel/patchAnalyticsNotificationEventOpenLab',
      payload: {
        visitorId: window._platformInfo.visitorId,
        title: lab.title,
        eventType: 'LabDetails',
      },
    });
  }

  getLabDetails = () => {
    const { dispatch, lab, shouldCallApi } = this.props;

    shouldCallApi &&
      dispatch({
        type: 'labModel/getLabsBookingsByParams',
        payload: { bookingId: lab.bookingId },
      }).then((lab) => this.setState({ lab }));

    dispatch({
      type: 'labModel/getLabsVirtualdevices',
      payload: { bookingId: lab.bookingId },
    }).then((virtualDevices) => this.setState({ virtualDevices }));
  };

  showExternalAccess = (virtualDeviceId) => {
    this.setState({ virtualDeviceId });
  };

  hideExternalAccess = () => {
    this.setState({ virtualDeviceId: '' });
  };

  getColumnFormItemProps = (column, device) => {
    const { virtualDeviceId } = this.state;
    const showURL =
      column.type &&
      (column.type.toLowerCase().indexOf('url') > -1 ||
        column.type.toLowerCase().indexOf('webgui') > -1);

    let children = (
      <Input
        readOnly
        value={column.value}
        suffix={
          column.show_copy ? (
            <AppIcon
              name="export"
              tooltip="Copy URL"
              onClick={() => copyToClipboard(column.value)}
            />
          ) : null
        }
      />
    );

    if (column.type === 'empty_section_text') {
      children = <div dangerouslySetInnerHTML={{ __html: column.value }} />;
    } else if (showURL) {
      children = null;
    } else if (column.type === 'dialog') {
      // Will be deprecated
      device.apiLinks = undefined;

      children = (
        <>
          <Button
            type="primary"
            className="ant-btn-green"
            onClick={() => this.showExternalAccess(device._id)}
          >
            {column.buttonLabel}
          </Button>
          {virtualDeviceId === device._id && (
            <ExternalAccess
              additionalLinks={column.value}
              onCancel={this.hideExternalAccess}
            />
          )}
        </>
      );
    }

    return {
      children,
      className: showURL && 'form-input-hidden',
      tooltip: column.description,
      label: showURL ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={
            column.type.toLowerCase().indexOf('webgui') > -1
              ? column.value.replace(/type=(.[^&]+)/g, 'type=webgui')
              : column.value
          }
        >
          {column.title}
        </a>
      ) : (
        column.title
      ),
    };
  };

  getFields = (field) => {
    const { shouldCallApi } = this.props;
    const lab = shouldCallApi ? this.state.lab : this.props.lab;
    const { key, label } = field;
    const fieldValue = lab[key];

    if (!fieldValue) return;

    switch (key) {
      case 'description':
        return (
          <Form.Item
            key={key}
            label="Description"
          >
            <Input.TextArea
              readOnly
              value={fieldValue && fieldValue.join(', ')}
            />
          </Form.Item>
        );
      case 'startTime':
        return (
          <Form.Item
            key={key}
            label="Start Time"
          >
            <Input
              readOnly
              value={moment(fieldValue).format(DATE_TIME_FORMAT)}
            />
          </Form.Item>
        );
      case 'endTime':
        return (
          <Form.Item
            key={key}
            label="End Time"
          >
            <Input
              readOnly
              value={moment(fieldValue).format(DATE_TIME_FORMAT)}
            />
          </Form.Item>
        );
      case 'ncStatusHistory':
      case 'bookingStatusHistory':
        return (
          <Collapse
            ghost
            key={key}
            bordered={false}
          >
            <Collapse.Panel header={label}>
              <Timeline mode="left">
                {fieldValue.map((item, index) => (
                  <Timeline.Item
                    key={`timeline-${index}`}
                    label={moment(item.date).format(DATE_TIME_FORMAT)}
                  >
                    {item.status}
                  </Timeline.Item>
                ))}
              </Timeline>
            </Collapse.Panel>
          </Collapse>
        );
      case 'userVisible':
        return (
          <Form.Item
            key={key}
            className="user-visible-field"
          >
            <Checkbox
              disabled
              checked={fieldValue}
            >
              User Visible
            </Checkbox>
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={key}
            label={label || key.charAt(0).toUpperCase() + key.slice(1)}
          >
            <Input
              readOnly
              value={fieldValue}
            />
          </Form.Item>
        );
    }
  };

  render() {
    const { shouldCallApi, fields = [] } = this.props;
    const lab = shouldCallApi ? this.state.lab : this.props.lab;
    const { virtualDevices, virtualDeviceId } = this.state;

    return (
      <>
        <Row
          wrap={false}
          className="cols-container lab-details-container"
        >
          <Col flex="30%">
            <Form
              layout="vertical"
              onValuesChange={(_, values) => console.log(values)}
            >
              {fields.map((item) => this.getFields(item))}
            </Form>
          </Col>
          <Col flex="70%">
            <div className="modal-title">Virtual Device</div>
            <Tabs>
              {virtualDevices.map((device) => (
                <Tabs.TabPane
                  key={device._id}
                  tab={
                    !device.status || device.status === 'ACTIVE' ? (
                      device.vd_id
                    ) : (
                      <Tooltip title={device.status}>
                        <span
                          style={{
                            color: device.statusColour,
                            padding: '5px 10px',
                            border: `1px solid ${device.statusColour}`,
                          }}
                        >
                          {device.vd_id}
                        </span>
                      </Tooltip>
                    )
                  }
                >
                  {device.image && device.image !== 'None' && (
                    <Image
                      className="device-img"
                      src={`${DEVICE_IMAGE_PATH}/${device.image}`}
                    />
                  )}

                  {device.sections.map((section, index) => (
                    <Form
                      key={section.id}
                      layout="vertical"
                    >
                      {section.rows.map((row, index) => (
                        <Row
                          key={`row-${index}`}
                          className="device-section-row"
                        >
                          {row.columns.map((column, index) => (
                            <Col
                              flex={1}
                              key={`column-${index}`}
                            >
                              {column && (
                                <Form.Item {...this.getColumnFormItemProps(column, device)} />
                              )}
                            </Col>
                          ))}
                        </Row>
                      ))}
                      {/* Will be deprecated */}
                      {index === 1 &&
                        device.apiLinks &&
                        device.apiLinks.additionalLinks &&
                        device.apiLinks.additionalLinks.length > 0 && (
                          <Form.Item>
                            <Button
                              type="primary"
                              className="ant-btn-green"
                              onClick={() => this.showExternalAccess(device._id)}
                            >
                              External API Access
                            </Button>
                            {virtualDeviceId === device._id && (
                              <ExternalAccess
                                additionalLinks={device.apiLinks.additionalLinks}
                                onCancel={this.hideExternalAccess}
                              />
                            )}
                          </Form.Item>
                        )}
                    </Form>
                  ))}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Col>
        </Row>
        <ServerSideEvent
          url={{
            pathname: EVENTS_USERLABDETAILS,
            params: { bookingId: lab.bookingId },
          }}
          adminUrl={{
            pathname: EVENTS_ADMINLABDETAILS,
            params: { bookingId: lab.bookingId },
          }}
          onmessage={this.getLabDetails}
          onopen={this.getLabDetails}
        />
      </>
    );
  }
}
