import React from 'react';
import { connect } from 'dva';
import { Modal, Button } from 'antd';

import './index.less';

class EulaModal extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'eulaModel/getEulasLatest' }).then(this.enableAcceptButton);

    this.scrollDiv = React.createRef();
    this.state = {
      isAcceptDisabled: true,
    };
  }

  onPrint = () => {
    const {
      eulaModel: { latestEula },
      dispatch,
    } = this.props;

    const windowPrint = window.open('', '', 'width=900,height=650');
    windowPrint.document.write(latestEula.body);
    windowPrint.document.close();
    windowPrint.focus();
    windowPrint.print();
    windowPrint.close();

    /* Matomo */
    dispatch({ type: 'userModel/trackUserEvent', payload: { action: 'Print Eula' } });
  };

  onDownload = () => {
    const {
      eulaModel: { latestEula },
      dispatch,
    } = this.props;

    window.open(`/${latestEula.file.path}/${latestEula.file.filename}`);

    /* Matomo */
    dispatch({ type: 'userModel/trackUserEvent', payload: { action: 'Download Eula' } });
  };

  onCancel = () => {
    const { dispatch } = this.props;

    dispatch({ type: 'appModel/logout' });

    /* Matomo */
    dispatch({ type: 'userModel/trackUserEvent', payload: { action: 'Reject Eula' } });
  };

  onAccept = () => {
    this.props.dispatch({ type: 'userModel/patchUsersEulaVersion' });
  };

  enableAcceptButton = () => {
    if (this.scrollDiv.current) {
      const { scrollTop, scrollHeight, clientHeight } = this.scrollDiv.current;

      if (Math.abs(scrollTop + clientHeight - scrollHeight) < 1) {
        this.setState({ isAcceptDisabled: false });
      }
    }
  };

  render() {
    const {
      eulaModel: { latestEula },
    } = this.props;
    const { isAcceptDisabled } = this.state;

    return (
      <Modal
        visible
        className="eula-modal"
        width={600}
        closable={false}
        title="Accept New EULA"
        onCancel={this.onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={this.onPrint}
            >
              Print EULA
            </Button>
            <Button
              type="text"
              onClick={this.onDownload}
            >
              Download file
            </Button>
            <Button
              type="text"
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={this.onAccept}
              disabled={isAcceptDisabled}
            >
              Accept and Continue
            </Button>
          </>
        }
      >
        <div
          className="eula-body"
          ref={this.scrollDiv}
          onScroll={this.enableAcceptButton}
          dangerouslySetInnerHTML={{
            __html: latestEula.body,
          }}
        />
      </Modal>
    );
  }
}

export default connect(({ eulaModel }) => ({ eulaModel }))(EulaModal);
