import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Collapse } from 'antd';
import DetailsPanel from './details-panel';

import './index.less';

class LabDetails extends React.Component {
  render() {
    const { onCancel, labs, ...others } = this.props;

    return (
      <Modal
        visible
        width={1200}
        title="Lab Details"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </>
        }
      >
        {labs.length > 1 ? (
          <Collapse
            ghost
            accordion
            bordered={false}
            className="highlight-collapse-header"
          >
            {labs.map((lab) => (
              <Collapse.Panel
                key={lab.config}
                header={lab.config}
              >
                <DetailsPanel
                  lab={lab}
                  {...others}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <DetailsPanel
            lab={labs[0]}
            {...others}
          />
        )}
      </Modal>
    );
  }
}

export default connect()(LabDetails);
