import React from 'react';
import moment from 'moment';
import { Modal, Form, Tag, Button } from 'antd';
import { DATE_TIME_FORMAT } from '@/constants';

class VideoDetails extends React.Component {
  render() {
    const { documentationTag, video, category, onCancel } = this.props;

    return (
      <Modal
        visible
        width={800}
        title="Video Details"
        closable={false}
        onCancel={onCancel}
        footer={
          <Button
            type="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 3 }}
        >
          <Form.Item>
            <div className="video-form-item">
              <video
                controls
                controlsList='nodownload'
                preload="metadata"
                poster={video.thumbnailUrl}
                data-matomo-title={video.name}
                onPlay={() => {
                  /* Matomo */
                  if (!this.hasPlayed) {
                    let customDimensionsObj = {};
                    customDimensionsObj[
                      `dimension${window._platformInfo.customDimensions.WatchedDocumentationVideo}`
                    ] = JSON.stringify({
                      tag: documentationTag,
                      title: video.name,
                    });
                    _paq.push([
                      'trackEvent',
                      'Documentation Video',
                      'Play',
                      JSON.stringify({
                        docTag: documentationTag,
                        category,
                        title: video.name,
                      }),
                      undefined,
                      customDimensionsObj,
                    ]);

                    this.hasPlayed = true;
                  }
                }}
                onPause={() => {
                  /* Matomo */
                  if (!this.hasPaused) {
                    _paq.push([
                      'trackEvent',
                      'Documentation Video',
                      'Pause',
                      JSON.stringify({
                        docTag: documentationTag,
                        category,
                        title: video.name,
                      }),
                    ]);

                    this.hasPaused = true;
                  }
                }}
                style={{ width: '100%' }}
              >
                <source
                  type="video/mp4"
                  src={video.dataUrl}
                />
              </video>
              <Button
                style={{ float: 'right' }}
                type="primary"
                htmlType="a"
                download={video.name + '.mp4'}
                href={video.dataUrl}
                onClick={() => {
                  /* Matomo */
                  _paq.push([
                    'trackEvent',
                    'Documentation Video',
                    'Download',
                    JSON.stringify({
                      docTag: documentationTag,
                      category,
                      title: video.name,
                    }),
                  ]);
                }}
              >
                Download
              </Button>
            </div>
          </Form.Item>
          <Form.Item label="Name">
            <span>{video.name}</span>
          </Form.Item>
          <Form.Item label="Description">
            <span>{video.description}</span>
          </Form.Item>
          <Form.Item label="Category">
            <span>{category}</span>
          </Form.Item>
          <Form.Item label="Created At">
            <span>{moment(video.createdAt * 1000).format(DATE_TIME_FORMAT)}</span>
          </Form.Item>
          <Form.Item label="Updated At">
            <span>{moment(video.updatedAt * 1000).format(DATE_TIME_FORMAT)}</span>
          </Form.Item>
          {video.tags && video.tags.length > 0 && (
            <Form.Item label="Tags">
              <span>
                {video.tags.map((item) => (
                  <Tag key={item}>{item}</Tag>
                ))}
              </span>
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

export default VideoDetails;
