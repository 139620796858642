import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import {
  Layout,
  Form,
  Input,
  Select,
  Table,
  Tag,
  Button,
  Tooltip,
  Modal,
  Space,
  Badge,
} from 'antd';
import debounce from 'lodash/debounce';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import { ROUTES, DATE_TIME_FORMAT } from '@/constants';
import copyToClipboard from '@/utils/copyToClipboard';
import DocVideoDetails from './doc-video-details';

import './index.less';

export const videoColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
    render: (value) => (value ? value.map((item) => <Tag key={item}>{item}</Tag>) : ''),
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    render: (value) => (value ? value.map((item) => <Tag key={item}>{item}</Tag>) : ''),
  },
];

export const ROW_KEY = 'encryptedTag';

export const getDocLink = (referenceId) =>
  window.location.origin + ROUTES.DOC_VIDEO + '/' + referenceId;

class MediaDocVideo extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'docVideoModel/getMediaDocumentationMediaListDocumentationReport',
    });
    props.dispatch({
      type: 'docVideoModel/getMediaDocumentationProductTypes',
    });

    this.state = {
      docVideo: null,
    };
  }

  columns = [
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      title: 'Encrypted Tag',
      dataIndex: 'encryptedTag',
      key: 'encryptedTag',
      render: (value, record) => (record.requestor ? value : ''),
    },
    {
      title: 'Requestor',
      dataIndex: 'requestor',
      key: 'requestor',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Videos',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Link',
      dataIndex: 'encryptedTag',
      key: 'link',
      render: (value, record) => {
        if (record.requestor && record.count > 0) {
          return (
            <>
              <Button
                type="text"
                style={{ marginRight: 8 }}
                onClick={(e) => {
                  window.open(getDocLink(value));
                }}
              >
                Open
              </Button>
              <Tooltip title={getDocLink(value)}>
                <Button
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    copyToClipboard(getDocLink(value));
                  }}
                >
                  Copy
                </Button>
              </Tooltip>
              <Button onClick={() => this.showDocLinkDetails(record)}>Edit</Button>
            </>
          );
        }

        if (record.requestor && record.count === 0) {
          return (
            <Button
              ghost
              danger
              style={{ marginRight: 8 }}
              onClick={() => {
                Modal.confirm({
                  title: 'Warning',
                  content: 'This will remove documentation link.',
                  cancelButtonProps: { type: 'text' },
                  onOk: () => {
                    this.props.dispatch({
                      type: 'docVideoModel/deleteMediaDocumentationDocumentationLinks',
                      payload: {
                        tag: record.tag,
                      },
                    });
                  },
                });
              }}
            >
              Delete
            </Button>
          );
        }

        if (!record.requestor && record.count > 0) {
          return (
            <Button
              type="primary"
              className="ant-btn-green"
              style={{ marginRight: 8 }}
              onClick={() => this.showDocLinkDetails(record)}
            >
              Claim
            </Button>
          );
        }
      },
    },
  ];

  showDocLinkDetails = (docVideo) => {
    this.setState({ docVideo });
  };

  hideDocLinkDetails = () => {
    this.setState({ docVideo: null });
  };

  renderSider = () => {
    const {
      dispatch,
      docVideoModel: { refineParams, docProductTypes },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            dispatch({
              type: 'docVideoModel/getMediaDocumentationMediaListDocumentationReport',
              payload: values,
            });
            dispatch({
              type: 'docVideoModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            name="tag"
            label="Tag"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="encryptedTag"
            label="Encrypted Tag"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="productType"
            label="Product Type"
          >
            <Select
              showArrow
              allowClear
              options={docProductTypes.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="index"
            label="Index"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="requestor"
            label="Requestor"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="tag-legend"
            label="Valid tags for documentation links by product type:"
          >
            <Space direction="vertical">
              {docProductTypes.map((val) => (
                <Badge
                  color="#0271bc"
                  text={`doc_${val.toLowerCase()}_<index number>`}
                />
              ))}
            </Space>
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  render() {
    const {
      docVideoModel: { loading, docVideos },
      dispatch,
    } = this.props;
    const { docVideo } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Problem_analysis"
          color="#0271bc"
          title="Documentation Videos"
          subtitle="Video series created by experts to help educate users on common workflows"
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                className="table-row-expand-no-border"
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                pagination={false}
                dataSource={docVideos}
                columns={this.columns}
                expandable={{
                  rowExpandable: (record) => record.count > 0,
                  expandedRowRender: (record) => (
                    <Table
                      className="inner-table"
                      size="small"
                      rowKey="id"
                      pagination={false}
                      dataSource={record.videos}
                      columns={videoColumns}
                    />
                  ),
                }}
              />
            </div>
          </Layout.Content>
        </Layout>
        {docVideo && (
          <DocVideoDetails
            onCancel={this.hideDocLinkDetails}
            dispatch={dispatch}
            docVideo={docVideo}
          />
        )}
      </div>
    );
  }
}

export default connect(({ docVideoModel }) => ({
  docVideoModel,
}))(MediaDocVideo);
