import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { Layout, Form, Select, DatePicker, Input, Table, Button, Modal } from 'antd';
import debounce from 'lodash/debounce';
import { TIME_FORMAT, DATE_TIME_FORMAT, CLASSROOM_STATUS_LIST } from '@/constants';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_INSTRUCTORCLASSROOMMANAGEMENT, EVENTS_ADMINCLASSROOMMANAGEMENT } from '@/services';
import NewClassroom from './new-classroom';
import ClassroomDetails from './classroom-details';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Start Time',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: 'End Time',
    dataIndex: 'endTime',
    key: 'endTime',
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: 'Instructor',
    dataIndex: 'instructor',
    key: 'instructor',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, { statusColour: color }) => <span style={{ color }}>{value}</span>,
  },
];

export const ROW_KEY = 'classroomId';

class InstructorClassroom extends React.Component {
  state = {
    modalType: '',
    selectedKey: null,
  };

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  renderSider = () => {
    const {
      classroomModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.setState({ selectedKey: null });
            this.props.dispatch({
              type: 'classroomModel/getClassrooms',
              payload: values,
            });
            this.props.dispatch({
              type: 'classroomModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Status"
            name="status"
          >
            <Select
              allowClear
              options={CLASSROOM_STATUS_LIST.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Classroom ID"
            name="classroomId"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Instructor"
            name="instructor"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start After"
            name="startAfter"
          >
            <DatePicker
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
            />
          </Form.Item>
          <Form.Item
            label="End Before"
            name="endBefore"
          >
            <DatePicker
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
            />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      classroomModel: { classrooms },
      dispatch,
    } = this.props;
    const { selectedKey } = this.state;

    const selectedObj = classrooms.find((classroom) => classroom[ROW_KEY] === selectedKey) || {};

    const DETAILS = {
      key: 'details',
      label: [
        'Details',
        <AppIcon
          key="icon"
          name="open-tabs"
        />,
      ],
      onClick: () => this.showModal('details'),
    };
    const CANCEL = {
      key: 'cancel-classroom',
      label: 'Cancel',
      type: 'text',
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: 'This will cancel classroom.',
          cancelButtonProps: { type: 'text' },
          onOk: () =>
            dispatch({
              type: 'classroomModel/patchClassroomsCancel',
              payload: { classroomId: selectedKey },
            }),
        });
      },
    };
    const DELETE = {
      key: 'delete-classroom',
      label: 'Delete',
      type: 'text',
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: 'This will remove classroom.',
          cancelButtonProps: { type: 'text' },
          onOk: () =>
            dispatch({
              type: 'classroomModel/deleteClassroomsScheduled',
              payload: { classroomId: selectedKey },
            }),
        });
      },
    };

    let actions = [DETAILS];
    if (selectedObj.status === 'IDLE' || selectedObj.status === 'CREATED') {
      actions = [DELETE, DETAILS];
    } else if (selectedObj.status === 'ACTIVE' || selectedObj.status === 'RUNNING') {
      actions = [CANCEL, DETAILS];
    }

    return selectedObj[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          {actions.map(({ label, type = 'primary', ...others }) => (
            <Button
              type={type}
              {...others}
            >
              {label}
            </Button>
          ))}
        </div>
      </div>
    ) : null;
  };

  onSelect = (selectedKey) => {
    this.setState({ selectedKey: selectedKey === this.state.selectedKey ? null : selectedKey });
  };

  render() {
    const {
      classroomModel: { loading, classrooms },
      userModel: { adminRole, profile },
      dispatch,
    } = this.props;
    const { modalType, selectedKey } = this.state;

    return (
      <div className="classroom-container">
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#0271bc"
          title="Classroom Management"
          subtitle="Add, edit and manage classrooms"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => this.showModal('new'),
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                pagination={false}
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={classrooms}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [selectedKey],
                  onChange: (selectedKeys) => this.onSelect(selectedKeys[0]),
                  renderCell: (checked, record, index, originNode) => (
                    <>
                      {originNode}
                      <div onClick={(e) => e.stopPropagation()}>
                        {record[ROW_KEY] === selectedKey && modalType === 'details' && (
                          <ClassroomDetails
                            dispatch={dispatch}
                            adminRole={adminRole}
                            onCancel={this.hideModal}
                            classroom={record}
                          />
                        )}
                      </div>
                    </>
                  ),
                }}
                onRow={(record) => ({
                  onClick: () => this.onSelect(record[ROW_KEY]),
                })}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {modalType === 'new' && (
          <NewClassroom
            dispatch={dispatch}
            adminRole={adminRole}
            profile={profile}
            onCancel={this.hideModal}
          />
        )}
        <ServerSideEvent
          url={EVENTS_INSTRUCTORCLASSROOMMANAGEMENT}
          adminUrl={EVENTS_ADMINCLASSROOMMANAGEMENT}
          onmessage={() => dispatch({ type: 'classroomModel/getClassrooms', isSSE: true })}
          onopen={() => dispatch({ type: 'classroomModel/getClassrooms' })}
        />
      </div>
    );
  }
}

export default connect(({ classroomModel, userModel }) => ({
  classroomModel,
  userModel,
}))(InstructorClassroom);
