import app from './app';
import models from './models';

import './index.less';

// 2. Plugins

// 3. Model
(models || []).forEach((model) => app.model(model));
// app.model();

// 4. Router
app.router(require('./router').default);

// 5. Start
app.start('#root');
