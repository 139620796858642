import React from 'react';
import { Modal, Form, Button, Input } from 'antd';
import AppIcon from '@/components/app-icon';
import copyToClipboard from '@/utils/copyToClipboard';

class ExternalAccess extends React.Component {
  render() {
    const { onCancel, additionalLinks = [] } = this.props;

    return (
      <Modal
        visible
        title="How to connect to the Lab API"
        width={850}
        closable={false}
        footer={
          <Button
            type="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
        }
      >
        <div>
          <div className="modal-subtitle">
            Access the lab by copy-ing and pasting this link into the browser or postman (or your
            development environment) and change the path=/ to "path=/path/to/api/endpoint" where
            "/path/to/api/endpoint" is the desired API endpoint of the lab.
          </div>
          <div className="modal-info">
            In some cases you may need to provide authorization headers. Please see our
            documentation for how to login / connect using these links
          </div>
          <hr />
          <Form layout="vertical">
            {additionalLinks.map((item, index) => (
              <Form.Item
                key={`external-link-${index}`}
                label={item.title}
                tooltip={item.description}
              >
                <Input
                  readOnly
                  value={item.value}
                  suffix={
                    <AppIcon
                      name="export"
                      onClick={() => copyToClipboard(item.value)}
                    />
                  }
                />
              </Form.Item>
            ))}
          </Form>
        </div>
      </Modal>
    );
  }
}

export default ExternalAccess;
