import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';

import './index.less';

class Pagination extends React.Component {
  render() {
    const { onChange, pageSize, total } = this.props;

    return (
      <AntdPagination
        itemRender={(_, type, originalElement) => {
          if (type === 'prev') {
            return (
              <div className="ant-pagination-item-link pagination-item">
                <DoubleLeftOutlined
                  title="First Page"
                  onClick={(event) => {
                    event.stopPropagation();
                    onChange && onChange(1, pageSize);
                  }}
                />
                <LeftOutlined />
              </div>
            );
          }
          if (type === 'next') {
            return (
              <div className="ant-pagination-item-link pagination-item">
                <RightOutlined />
                <DoubleRightOutlined
                  title="Last Page"
                  onClick={(event) => {
                    event.stopPropagation();
                    onChange && onChange(Math.ceil(total / pageSize), pageSize);
                  }}
                />
              </div>
            );
          }

          return originalElement;
        }}
        {...this.props}
      />
    );
  }
}

export default Pagination;
