import React from 'react';
import { connect } from 'dva';
import { Layout, Spin, Row, Col, Card, Table, Select } from 'antd';
import InfoBar from '@/components/info-bar';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_INSTRUCTORDASHBOARD, EVENTS_ADMINDASHBOARD } from '@/services';
import DashboardStudents, { ROW_KEY } from './dashboard-students';
import DashboardActions from './dashboard-actions';
import DashboardActionStatuses from './dashboard-action-statuses';

import './index.less';

class InstructorDashboard extends React.Component {
  render() {
    const {
      dashboardModel: {
        loading,
        classrooms,
        currentClassroom,
        currentStudent,
        simActions,
        inventory,
        actionStatuses,
      },
      dispatch,
    } = this.props;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Security_audit_dashboard"
          color="#0271bc"
          title="Dashboard"
          subtitle="Global actions dashboard"
        />
        <Layout>
          <Layout.Content className="page dashboard-container">
            <Spin
              style={{ height: 400 }}
              spinning={loading}
            >
              {currentClassroom.classroomId ? (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    Active Classroom:&nbsp;&nbsp;
                    <Select
                      showArrow
                      value={currentClassroom.classroomId}
                      fieldNames={{ label: 'title', value: 'classroomId' }}
                      options={classrooms}
                      onChange={(_, values) => {
                        dispatch({
                          type: 'dashboardModel/onCurrentClassroomChange',
                          payload: values,
                        });
                      }}
                    />
                  </Col>
                  <Col
                    className="cards-container"
                    span={10}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Card title="Students">
                          <DashboardStudents
                            classroom={currentClassroom}
                            currentStudent={currentStudent}
                            dispatch={dispatch}
                          />
                        </Card>
                      </Col>
                      <Col span={24}>
                        <Card title="Action History">
                          <DashboardActionStatuses actionStatuses={actionStatuses} />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Card title="Action View">
                      {currentStudent[ROW_KEY] ? (
                        <DashboardActions
                          simActions={simActions}
                          inventory={inventory}
                          dispatch={dispatch}
                        />
                      ) : (
                        <Table
                          className="no-data-table"
                          showHeader={false}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              ) : (
                <span>No Active Classroom</span>
              )}
            </Spin>
          </Layout.Content>
        </Layout>
        <ServerSideEvent
          url={EVENTS_INSTRUCTORDASHBOARD}
          adminUrl={EVENTS_ADMINDASHBOARD}
          onmessage={() => dispatch({ type: 'dashboardModel/getClassroomsActive', isSSE: true })}
          onopen={() => dispatch({ type: 'dashboardModel/getClassroomsActive' })}
        />
      </div>
    );
  }
}

export default connect(({ dashboardModel }) => ({
  dashboardModel,
}))(InstructorDashboard);
