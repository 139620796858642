import React from 'react';
import { Modal, Form, Input, Radio, Button, Upload } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import { ROW_KEY } from '../';

class BannerDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...this.props.banner,
      ...values,
      body: values.body && values.body.editor ? values.body.editor.getData() : values.body,
      file:
        values.file && values.file.length > 0
          ? values.file.map((item) => item.originFileObj)
          : values.file,
      active: !!values.active,
    };

    this.setState({
      isSaveDisabled: !this.values.title || !this.values.body,
    });
  };

  render() {
    const {
      banner: { title, body, file, active },
      onCancel,
      dispatch,
    } = this.props;
    const { isSaveDisabled } = this.state;
    const isEdit = this.props.banner[ROW_KEY];

    const fileList = [];
    if (file) {
      fileList.push({
        uid: file.filename,
        name: file.originalName,
        url: `/${file.path}/${file.filename}`,
        thumbUrl: `/${file.path}/${file.filename}`,
      });
    }

    return (
      <Modal
        visible
        width={800}
        title={`${isEdit ? 'Edit' : 'New'} Site Content`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: `landingPageBannerModel/${isEdit ? 'patchLandingPage' : 'postLandingPage'}`,
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 3 }}
          initialValues={{
            title,
            body,
            active,
          }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Body"
            name="body"
          >
            <CKEditor initData={body} />
          </Form.Item>
          <Form.Item
            label="Image"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              maxCount={1}
              listType="picture"
              customRequest={({ onSuccess }) => onSuccess()}
              defaultFileList={fileList}
              showUploadList={isEdit ? { showRemoveIcon: false } : undefined}
            >
              {!isEdit && <Button>Choose File</Button>}
            </Upload>
          </Form.Item>
          <Form.Item
            name="active"
            valuePropName="checked"
            extra={
              <p className="highlight-info">
                Turning this on will make this default home page banner.
              </p>
            }
          >
            <Radio>Active</Radio>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default BannerDetails;
