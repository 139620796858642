import React from 'react';
import { Result } from 'antd';
import { getSearchParam } from '@/utils';

import './index.less';

class Error extends React.Component {
  getErrors = () => {
    let errorType = getSearchParam('T') || 'Sorry, something went wrong.';
    let errorReason = getSearchParam('R');

    if (errorType === '_') {
      errorType = 'Access Denied';
      errorReason = '';
    }

    return { errorType, errorReason };
  };

  render() {
    const { errorType, errorReason } = this.getErrors();

    return (
      <div className="full-screen page">
        <Result
          status="warning"
          title={errorType}
          subTitle={errorReason}
        />
      </div>
    );
  }
}

export default Error;
