import React from 'react';
import { connect } from 'dva';
import * as XLSX from 'xlsx';
import uniqBy from 'lodash/uniqBy';
import { Form, Input, Select, Switch, Table, Tooltip, Button, Upload } from 'antd';
import AppIcon from '@/components/app-icon';
import FileUpload from '../file-upload';

import './index.less';

const findLabConfig = (labConfig, labConfigs = []) => {
  return labConfigs.find(
    (item) =>
      item.type === labConfig.type &&
      item.config === labConfig.config &&
      item.version === labConfig.version
  );
};

class LabConfigsStudents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labConfigsSchedule: [],
    };

    props
      .dispatch({ type: 'classroomModel/getUserLabDomains' })
      .then((labConfigsByType) => (this.labConfigsByType = labConfigsByType));

    this.getScheduleGetScheduleForWindow();
  }

  getScheduleGetScheduleForWindow = () => {
    const { dispatch, startTime, endTime } = this.props;

    dispatch({
      type: 'classroomModel/getScheduleGetScheduleForWindow',
      payload: { startTime, endTime },
    }).then((labConfigsSchedule) => this.setState({ labConfigsSchedule }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.startTime !== this.props.startTime || prevProps.endTime !== this.props.endTime) {
      this.getScheduleGetScheduleForWindow();
    }
    if (prevProps.instructor !== this.props.instructor) {
      this.onStudentChange([]);
    }
  }

  labConfigColumns = [
    {
      title: 'Lab Config',
      dataIndex: 'config',
      key: 'config',
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (value) => (value < 0 ? <span style={{ color: 'red' }}>{value}</span> : value),
    },
    {
      title: 'Instructor Only',
      dataIndex: 'instructorOnly',
      key: 'instructorOnly',
      render: (value) => (value ? 'Yes' : ''),
    },
    {
      title: 'File Upload',
      dataIndex: 'fileUpload',
      key: 'fileUpload',
      render: (value, record, index) => {
        const labConfig = findLabConfig(record, this.labConfigsByType[record.type]);

        if (!labConfig || !labConfig.file_upload || !labConfig.file_upload.type) return null;

        return (
          <FileUpload
            dispatch={this.props.dispatch}
            uploadType={labConfig.file_upload.type}
            onChange={(fileUpload) => {
              const { labConfigs, onValuesChange } = this.props;

              onValuesChange &&
                onValuesChange({
                  labConfigs: labConfigs.map((item, i) => {
                    if (i !== index) {
                      return item;
                    }

                    delete item.enableAudit;
                    delete item.auditType;

                    return { ...item, fileUpload };
                  }),
                });
            }}
            {...value}
          />
        );
      },
    },
    {
      title: 'Enable Audit',
      dataIndex: 'auditAvailable',
      key: 'auditAvailable',
      render: (auditAvailable, { enableAudit, auditTypes }, index) => {
        if (!auditAvailable) return null;

        const { labConfigs, onValuesChange } = this.props;
        const onChange = (value) => {
          onValuesChange &&
            onValuesChange({
              labConfigs: labConfigs.map((item, i) => {
                if (i !== index) {
                  return item;
                }
                delete item.fileUpload;

                return { ...item, ...value };
              }),
            });
        };

        return (
          <div className="audit-select-column">
            <Switch
              checked={enableAudit}
              onChange={(checked) => onChange({ enableAudit: checked, auditType: null })}
            />
            {enableAudit && auditTypes && (
              <Select
                placeholder="Please Select"
                options={auditTypes.map((item) => ({ label: item, value: item }))}
                onChange={(value) => onChange({ enableAudit, auditType: value })}
              />
            )}
          </div>
        );
      },
    },
  ];

  studentColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      key: 'actions',
      width: 40,
      render: (_, record, index) => (
        <AppIcon
          name="trashcan"
          color="#0271bc"
          onClick={() => this.onStudentChange([{ ...record, index }], 'delete')}
        />
      ),
    },
  ];

  getLabConfigs = (
    labConfigs = this.props.labConfigs,
    students = this.props.students,
    labConfigsSchedule = this.state.labConfigsSchedule
  ) => {
    const newLabConfigs = labConfigs.map((item) => {
      const schedule = findLabConfig(item, labConfigsSchedule);
      if (!schedule) return item;

      let available = schedule.available || 0;
      const instructorCount = this.props.labConfigDisabled ? 0 : 1;

      if (item.instructorOnly) {
        available = available - instructorCount;
      } else if (students && students.length) {
        available = available - instructorCount - students.length;
      }

      return { ...item, available };
    });

    return newLabConfigs;
  };

  onStudentChange = (items, type = 'add') => {
    const { students, instructor, onValuesChange, dispatch } = this.props;

    const temp1 =
      type === 'add'
        ? [...students, ...items]
        : students.filter(
            (o1, index) => !items.some((o2) => o1.email === o2.email && o2.index === index)
          );

    // validator
    const temp2 = uniqBy(temp1, 'email').filter((student) => student.email !== instructor);
    if (temp2.length !== temp1.length) {
      dispatch({
        type: 'appModel/handleError',
        payload: { message: 'Duplicate emails of students or instructor.' },
      });
    }
    const newStudents = temp2.filter((student) => /\S+@\S+\.\S+/.test(student.email));
    if (newStudents.length !== temp2.length) {
      dispatch({
        type: 'appModel/handleError',
        payload: { message: 'Invalid email format.' },
      });
    }

    onValuesChange &&
      onValuesChange({
        students: newStudents,
        labConfigs: this.getLabConfigs(undefined, newStudents),
      });
  };

  render() {
    const { labConfigsSchedule } = this.state;
    const { labConfigDisabled, labConfigs, studentDisabled, students, onValuesChange, dispatch } =
      this.props;

    return (
      <>
        <Form.Item label="Lab Configs">
          {!labConfigDisabled && (
            <Form.Item className="last-form-item">
              <Select
                showArrow
                mode="multiple"
                fieldNames={{ label: 'config', value: 'config' }}
                options={labConfigsSchedule}
                value={labConfigs.map(({ config }) => config)}
                onChange={(_, value) => {
                  const selectedLabConfigs = this.getLabConfigs(
                    value.map((item) => findLabConfig(item, labConfigs) || item)
                  );
                  onValuesChange && onValuesChange({ labConfigs: selectedLabConfigs });

                  // validator
                  let instructorOnlyLabConfigCount = selectedLabConfigs.filter(
                    ({ instructorOnly }) => instructorOnly
                  ).length;
                  let studentLabConfigCount =
                    selectedLabConfigs.length - instructorOnlyLabConfigCount;
                  let message = '';

                  if (instructorOnlyLabConfigCount > 1) {
                    message = 'A classroom can only have 1 instructor only lab config.';
                  } else if (studentLabConfigCount === 0) {
                    message = 'A classroom must have at least 1 student lab config.';
                  } else if (studentLabConfigCount > 1) {
                    message = 'A classroom can only have 1 student lab config.';
                  }
                  message && dispatch({ type: 'appModel/handleError', payload: { message } });
                }}
              />
            </Form.Item>
          )}
          {labConfigs.length > 0 && (
            <Table
              bordered
              size="small"
              rowKey="config"
              pagination={false}
              dataSource={labConfigDisabled ? this.getLabConfigs() : labConfigs}
              columns={
                labConfigDisabled ? this.labConfigColumns.slice(0, 3) : this.labConfigColumns
              }
            />
          )}
        </Form.Item>
        <Form.Item label="Student Enrolled">
          {!studentDisabled && (
            <Form.Item className="last-form-item">
              <Input.Group compact>
                <Input
                  id="studentInput"
                  style={{ width: 'calc(100% - 92px)' }}
                  placeholder="e.g: email, first name, last name"
                  allowClear={{
                    clearIcon: (
                      <AppIcon
                        name="round-add"
                        onClick={() => {
                          const studentInput = document.getElementById('studentInput');
                          const [email, firstName, lastName] = studentInput.value.split(',');

                          this.onStudentChange([{ email, firstName, lastName }]);
                        }}
                      />
                    ),
                  }}
                />
                <Upload
                  accept=".csv,.xls,.xlsx"
                  maxCount={1}
                  customRequest={({ onSuccess }) => onSuccess()}
                  showUploadList={false}
                  onChange={({ file }) => {
                    if (file.status === 'done') {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        const workbook = XLSX.read(e.target.result, {
                          type: 'array',
                        });

                        workbook.SheetNames.forEach((sheetName) => {
                          this.onStudentChange(
                            XLSX.utils
                              .sheet_to_json(workbook.Sheets[sheetName])
                              .filter((item) => item.email)
                          );
                        });
                      };
                      reader.readAsArrayBuffer(file.originFileObj);
                    }
                  }}
                >
                  <Tooltip title="Import a CSV/XLS file.n Use 'email','firstName', 'lastName' as keys in first row">
                    <Button type="primary">or Import</Button>
                  </Tooltip>
                </Upload>
              </Input.Group>
            </Form.Item>
          )}
          {students.length > 0 && (
            <Table
              bordered
              size="small"
              rowKey={({ email }) => email + Math.random()}
              pagination={false}
              dataSource={students}
              columns={studentDisabled ? this.studentColumns.slice(0, 3) : this.studentColumns}
            />
          )}
        </Form.Item>
      </>
    );
  }
}

export default connect()(LabConfigsStudents);
