import React from 'react';
import { Table, Space, Tooltip, Modal } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import AppIcon from '@/components/app-icon';
import LabDetails from '@/components/lab-details';
import BookingAudit from '@/components/booking-audit';

export const ROW_KEY = 'username';

export default class DashboardStudents extends React.Component {
  state = {
    modalType: '',
    username: '',
  };

  showModal = (username, modalType) => {
    this.setState({ username, modalType });
  };

  hideModal = () => {
    this.setState({ username: '', modalType: '' });
  };

  columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      className: 'first-letter-column',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
      key: 'actions',
      dataIndex: 'username',
      render: (username, { role, email }) => {
        const { classroom, dispatch } = this.props;
        const bookings = classroom.bookings[email] || classroom.bookings[role] || [];
        const enableAudit = classroom.labConfigs.find((item) => item.enableAudit);

        return role !== 'global' ? (
          <Space
            size="middle"
            align="end"
            onClick={(e) => e.stopPropagation()}
          >
            <Tooltip title="Booking Details">
              <AppIcon
                name="info"
                onClick={() => {
                  this.showModal(username, 'lab-details');
                }}
              />
            </Tooltip>
            <Tooltip title="Open Interaction Page">
              <AppIcon
                className="round-icon"
                name="chevron"
                onClick={() => {
                  dispatch({
                    type: 'labModel/gotoLabInteraction',
                    payload: {
                      isClassroom: true,
                      bookingId: bookings.map(({ bookingId }) => bookingId).join(','),
                      config: bookings.map(({ config }) => config).join(','),
                    },
                  });
                }}
              />
            </Tooltip>
            {enableAudit && (
              <Tooltip title="Perform Audit">
                <AppIcon
                  className="round-icon"
                  name="menu"
                  onClick={() => this.showModal(username, 'perform-audit')}
                />
              </Tooltip>
            )}
            {role !== 'instructor' && (
              <Tooltip title="Restart">
                <ReloadOutlined
                  className="cec-icon"
                  onClick={() => {
                    Modal.confirm({
                      title: 'Warning',
                      content: 'This will restart booking.',
                      cancelButtonProps: { type: 'text' },
                      onOk: () => {
                        bookings.map(({ bookingId }) =>
                          dispatch({
                            type: 'classroomModel/patchClassroomRestartBooking',
                            payload: {
                              classroomId: classroom.classroomId,
                              email,
                              bookingId,
                            },
                          })
                        );
                      },
                    });
                  }}
                />
              </Tooltip>
            )}
            {this.state.modalType === 'perform-audit' && this.state.username === username && (
              <BookingAudit
                readOnly
                onCancel={this.hideModal}
                lab={{
                  ...bookings.find(
                    (item) =>
                      item.type === enableAudit.type &&
                      item.config === enableAudit.config &&
                      item.version === enableAudit.version
                  ),
                  bookingInstructor: classroom.instructor,
                }}
              />
            )}
            {this.state.modalType === 'lab-details' && this.state.username === username && (
              <LabDetails
                shouldCallApi
                onCancel={this.hideModal}
                labs={bookings}
                fields={[
                  { key: 'title' },
                  { key: 'description' },
                  { key: 'bookingOwner', label: 'Owner' },
                  { key: 'startTime' },
                  { key: 'endTime' },
                ]}
              />
            )}
          </Space>
        ) : null;
      },
    },
  ];

  onCurrentStudentChange = (record = {}) => {
    const { currentStudent, dispatch } = this.props;

    if (record[ROW_KEY] !== currentStudent[ROW_KEY]) {
      dispatch({ type: 'dashboardModel/onCurrentStudentChange', payload: record });
    }
  };

  render() {
    const { classroom, currentStudent } = this.props;

    const dataSource = [
      {
        role: 'global',
        email: 'n/a',
        username: 'global',
        firstName: 'n/a',
        lastName: '',
      },
      {
        role: 'instructor',
        email: classroom.instructor,
        username: classroom.instructor,
        firstName: '',
        lastName: '',
      },
      ...classroom.students.map((item) => ({ role: 'student', ...item })),
    ];

    return (
      <Table
        size="small"
        scroll={{ y: 200 }}
        rowKey={ROW_KEY}
        pagination={false}
        dataSource={dataSource}
        columns={this.columns}
        rowClassName={({ role }) => role === 'global' && 'global-row'}
        rowSelection={{
          columnWidth: 0,
          renderCell: () => null,
          hideSelectAll: true,
          selectedRowKeys: [currentStudent[ROW_KEY]],
        }}
        onRow={(record) => ({
          onClick: () => this.onCurrentStudentChange(record),
        })}
      />
    );
  }
}
