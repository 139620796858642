import React from 'react';
import Cookie from 'js-cookie';
import { Button, Select, Form, Input, Collapse, List, message } from 'antd';
import proxy from '@/constants/proxy';
import { getRequest, postRequest } from '@/services';

const topic_list = [
  //"__consumer_offsets",
  'bp.aeprocessor.v1.alarmcorrelation',
  'bp.aeprocessor.v1.alarmsforcorrelation',
  'bp.aeprocessor.v1.filters.pool',
  'bp.aeprocessor.v1.geo.sync',
  'bp.aeprocessor.v1.geo.sync_in',
  'bp.aeprocessor.v1.notifyhosts',
  'bp.aeprocessor.v1.operations',
  'bp.aeprocessor.v2_0.alarms',
  'bp.configmgmt.v1.jobStateChange',
  'com.cyaninc.bp.market',
  'bp.aeprocessor.v1.alarms.tapi',
  'bp.ciena.watcher',
  'bp.config.api.v1.backup',
  'bp.config.api.v1.restore',
  'bp.config.api.v1.upgrade',
  'bp.correlation.v1.changes.alarms',
  'bp.correlation.v1.changes.device',
  'bp.correlation.v1.changes.inventoryRelationship',
  'bp.crinoid.v1.changes',
  'bp.crinoid.v1.kompressor',
  'bp.crinoid.v1.kompressor_in',
  'bp.discovery.v2_0.changes.discoverySyncRequest',
  'bp.discovery.v2_0.changes.job',
  'bp.discovery.v2_0.changes.sync',
  'bp.discovery.v3.changes.managementSession',
  'bp.gcs.v1.kompressor',
  'bp.geosync.pm.metrics',
  'bp.geosync.pm.metrics_in',
  'bp.gosftp.api.v1.dir',
  'bp.gosftp.api.v1.dirresponse',
  'bp.gosftp.api.v1.dropcache',
  'bp.hpmra.v1.pm',
  'bp.ifd.api.v1.serviceIntents.update',
  'bp.ifd.api.v2_0.serviceIntents.stateChange',
  //"bp.kafkacomet.v1.channel.administration",
  //"bp.kafkacomet.v1.subscriptions",
  'bp.nsi.v1.changes.group',
  'bp.nsi.v1.changes.physicalLocation',
  'bp.nsi.v1.changes.sync',
  'bp.nsi.v1.es.eg.request',
  'bp.nsi.v1.es.eqp.request',
  'bp.nsi.v1.es.fre.request',
  'bp.nsi.v1.es.nc.request',
  'bp.nsi.v1.es.pl.request',
  'bp.nsi.v1.es.tpe.request',
  'bp.nsi.v1.internal.stitch',
  'bp.nsi.v1.serviceClassification',
  'bp.nsi.v2.changes.geo',
  'bp.nsi.v2.changes.geo_in',
  'bp.nsi.v2_0.changes.nc',
  'bp.nsi.v2_0.validation',
  'bp.nsi.v3.changes.controller',
  'bp.nsi.v3.changes.equipment',
  'bp.nsi.v3.changes.fre',
  'bp.nsi.v3.changes.tpe',
  'bp.nsi.v3.validation',
  'bp.perfg.v1.monitor.fiber',
  'bp.perfg.v1.monitor.servicetrail',
  'bp.perfg.v1.monitor.viability',
  'bp.perfg.v1.timeseries.cmg',
  'bp.perfg.v1.timeseries.ptc',
  'bp.pmp.v1.pm',
  'bp.pmprocessor.v1.alarmstore',
  'bp.pmprocessor.v1.datastore',
  'bp.projectmanagement.v2.cancelled',
  'bp.projectmanagement.v2.commit',
  'bp.projectmanagement.v2.deleted',
  'bp.projectmanagement.v2.list.deleted',
  'bp.projectmanagement.v2.list.projectadded',
  'bp.projectmanagement.v2.list.statechange',
  'bp.projectmanagement.v2.networkchanged',
  'bp.projectmanagement.v2.projectvalidate',
  'bp.projectmanagement.v2.statechange',
  'bp.ra.v1.alarms',
  'bp.ra.v1.backup',
  'bp.ra.v1.diagnostics.loopback',
  'bp.ra.v1.nodecommissioning',
  'bp.ra.v1.pm',
  'bp.ra.v1.pm.thresholds',
  'bp.ra.v1.pmMeasurementEvent',
  'bp.ra.v1.poke',
  'bp.ra.v1.restore',
  'bp.ra.v1.scanDataset',
  'bp.ra.v1.srs',
  'bp.ra.v1.upgrade',
  'bp.ra.v2_0.sync',
  'bp.ra.v2_0.sync.leds',
  'bp.ractrl.v1.changes',
  'bp.ractrl.v1.changes.typegroups',
  'bp.ractrl.v1.raproxy_changes',
  'bp.rasdk.v1.changes',
  'bp.stitcher.v2_0.changes.fre',
  'bp.tdc.v1.tests',
  'bp.trapf.v1.subscriptions',
  'bp.trapf.v1.trapstream',
  'bp.trapf.v1.trapstream.Ciena6200',
  'bp.trapf.v1.trapstream.MPB',
  'bp.tron.v1.changes',
  'bp.tron.v1.kompressor',
  'bp.tron.v1.kompressor_in',
  'chronos.dispatch.queue',
  'chronos.scheduling.queue',
  'chronos.sharding.queues.0',
  'chronos.sharding.queues.1',
  //"com.cyaninc.bp.asset",
  //"com.cyaninc.bp.audit.ingestion",
  //"com.cyaninc.bp.events",
  //"cr",
  'kafka.topics.changes',
  'org.openroadm.service.rev160728.ServiceNotification',
  'org.openroadm.service.rev160728.ServiceRpcResult',
  'org.openroadm.service.status',
  'pm.scheduling',
];

class MCPWebsocket extends React.Component {
  state = {
    wsLogin: false,
    alarmFilters: [],
    output: [],
  };

  connect = () => {
    if (!this.topic) return;
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) return;

    const { virtualDevice } = this.props;
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.util_ip);

    this.websocket = new WebSocket(
      `wss://${window.location.host}` +
        proxy.deprecated_computePath(
          'UTIL_API',
          o1,
          o2,
          virtualDevice.bookingEndTime,
          '/path/mcp_ws/websocket',
          `&user=${virtualDevice.api_username}&pass=${virtualDevice.api_password}&ip=${virtualDevice.api_ip}`
        )
    );

    this.websocket.onopen = () => {
      const message = JSON.stringify({
        topic: 'topics:' + this.topic,
        ref: 1,
        event: 'phx_join',
        payload: {},
      });
      this.websocket.send(message);
      this.renderOutput({ message, type: 'SENT' });
    };

    this.websocket.onmessage = (msg) => {
      const msg_json = JSON.parse(msg.data);
      if (msg_json.event === 'phx_reply' && msg_json.payload.status === 'ok') {
        if (this.websocket.readyState !== WebSocket.OPEN) {
          message.error('Failed to connect to websocket!');
        }

        this.websocket.onmessage = (msg) => {
          this.renderOutput({ message: msg.data, type: 'REC' });
        };

        this.heartbeatInterval = setInterval(() => {
          const message = JSON.stringify({
            topic: 'phoenix',
            ref: 1,
            event: 'heartbeat',
            payload: {},
          });
          this.websocket.send(message);
          this.renderOutput({ message, type: 'SENT' });
        }, 25000);
      }
    };

    this.websocket.onclose = (event) => {
      this.websocket = null;
      this.renderOutput({ message: event.reason || event.code, type: 'CLOSED' });

      if (event.reason === 'reconnect') {
        this.renderOutput();
        this.connect();
      }
    };

    this.websocket.onerror = (err) => {
      message.error('Websocket has errored: ' + err.data);
    };
  };

  disconnect = (code, reason) => {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }

    if (!this.websocket) return;
    if (this.websocket.readyState === WebSocket.CLOSED) {
      this.websocket = null;
      return;
    }

    this.websocket.close(code, reason);
  };

  onSubmit = (values) => {
    const { virtualDevice } = this.props;
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.api_ip);

    postRequest(
      proxy.deprecated_computePath(
        'MCP_EXTERNAL_API',
        o1,
        o2,
        virtualDevice.bookingEndTime,
        `/port/${virtualDevice.api_port}`,
        '&path=/tron/api/v1/tokens'
      ),
      {
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((data) => {
        this.setState({ wsLogin: true });
        this.token = data.token;

        Cookie.set('uac.authorization', data.token);
        Cookie.set('uac.username', 'admin');
        Cookie.set('uac.user_id', data.user);

        this.refreshAlarmFilters(data.token);
      })
      .catch(() => message.error('Failed to login'));
  };

  onTopicChange = (topicVal, filterVal) => {
    this.setState({ topicVal, filterVal });
    this.topic = topicVal || filterVal;

    if (!this.topic) return;

    if (this.websocket) {
      this.disconnect(4999, 'reconnect');
    } else {
      this.connect();
    }
  };

  refreshAlarmFilters = () => {
    const { virtualDevice } = this.props;
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.api_ip);

    getRequest(
      proxy.deprecated_computePath(
        'MCP_EXTERNAL_API',
        o1,
        o2,
        virtualDevice.bookingEndTime,
        `/port/${virtualDevice.api_port}`,
        '&path=/nsa/api/v2_0/alarms/filter'
      ),
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    ).then((data) => this.setState({ alarmFilters: data.data }));
  };

  addAlarmFilter = (values) => {
    const { virtualDevice } = this.props;
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.api_ip);

    postRequest(
      proxy.deprecated_computePath(
        'MCP_EXTERNAL_API',
        o1,
        o2,
        virtualDevice.bookingEndTime,
        `/port/${virtualDevice.api_port}`,
        '&path=/nsa/api/v2_0/alarms/filter'
      ),
      {
        body: values.body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
        },
      }
    ).then(() => this.refreshAlarmFilters());
  };

  renderOutput = (item) => {
    if (!item) {
      this.setState({ output: [] });
      return;
    }

    const output = [...this.state.output, item];
    this.setState({ output });
  };

  render() {
    const { wsLogin, topicVal, filterVal, alarmFilters, output } = this.state;

    return (
      <div className="container page websocket-container">
        {!wsLogin ? (
          <div>
            <div className="page-title">Login to Navigator MC Websocket</div>
            <Form
              layout="vertical"
              onFinish={this.onSubmit}
            >
              <Form.Item
                name="username"
                label="API Username:"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="API Password:"
                rules={[{ required: true }]}
              >
                <Input type="password" />
              </Form.Item>
              <Form.Item
                name="tenant"
                label="Tenant (usually 'master'):"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div>
            <div className="page-title">Navigator MC Event Stream</div>
            <Form layout="vertical">
              <Form.Item label="Topics">
                <Select
                  placeholder="Select a topic"
                  options={topic_list.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  value={topicVal}
                  onChange={(topicVal) => this.onTopicChange(topicVal, undefined)}
                />
              </Form.Item>
              <Form.Item className="button-row">
                <Button
                  type="text"
                  htmlType="button"
                  onClick={() => this.renderOutput()}
                >
                  Clear Output
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.disconnect()}
                >
                  Close Socket
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  disabled={!this.topic}
                  onClick={() => this.connect()}
                >
                  Open Socket
                </Button>
              </Form.Item>
            </Form>
            <Collapse
              ghost
              bordered={false}
            >
              <Collapse.Panel header="Alarm Filter Component">
                <Form
                  layout="vertical"
                  onFinish={this.addAlarmFilter}
                >
                  <Form.Item label="Alarm Filters">
                    <Select
                      placeholder="Please select a filter"
                      options={alarmFilters.map(({ attributes: { channel } }) => ({
                        label: channel,
                        value: channel,
                      }))}
                      value={filterVal}
                      onChange={(filterVal) => this.onTopicChange(undefined, filterVal)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="body"
                    label="body"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item className="button-row">
                    <Button
                      type="text"
                      htmlType="button"
                      onClick={this.refreshAlarmFilters}
                    >
                      Refresh
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Form>
              </Collapse.Panel>
            </Collapse>
            <div>
              {output.length > 0 && (
                <List split={false}>
                  {output.map(({ type, message }, index) => (
                    <List.Item key={index}>
                      <div className={`output-${type}`}>
                        <b>{type}: </b>
                        {message}
                      </div>
                    </List.Item>
                  ))}
                </List>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MCPWebsocket;
