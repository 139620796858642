import React from 'react';
import { Modal, Button, Form } from 'antd';
import LabConfigsStudents from './labconfigs-students';

class ClassroomEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        labConfigs: props.labConfigDisabled ? props.classroom.labConfigs : [],
        students: props.studentDisabled ? props.classroom.students : [],
      },
    };
  }

  onValuesChange = (newValues) => {
    let values = { ...this.state.values, ...newValues };

    this.setState({ values });
  };

  validate = () => {
    const { values } = this.state;

    return (
      !values.labConfigs ||
      !values.labConfigs.length > 0 ||
      values.labConfigs.some(({ available }) => available < 0) ||
      !values.students ||
      !values.students.length > 0
    );
  };

  render() {
    const {
      onCancel,
      onEdit,
      classroom: { startTime, endTime, instructor },
      ...others
    } = this.props;
    const { values } = this.state;

    return (
      <Modal
        visible
        width={1000}
        title="Edit Classroom"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              id="commit-button"
              type="primary"
              disabled={this.validate()}
              onClick={() => onEdit && onEdit(values)}
            >
              Add
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
        >
          <LabConfigsStudents
            startTime={startTime}
            endTime={endTime}
            instructor={instructor}
            onValuesChange={this.onValuesChange}
            {...values}
            {...others}
          />
        </Form>
      </Modal>
    );
  }
}

export default ClassroomEdit;
