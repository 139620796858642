import React from 'react';
import { Link } from 'dva/router';
import classNames from 'classnames';
import { Button } from 'antd';
import AppIcon from '@/components/app-icon';

import './index.less';

class IconButton extends React.Component {
  state = {
    hover: false,
  };

  render() {
    const { needHoverState, to, icon, color, className, children, desc, ...others } = this.props;
    const { hover } = this.state;

    const button = (
      <Button
        className={classNames('icon-button', className)}
        onMouseEnter={() => needHoverState && this.setState({ hover: true })}
        onMouseLeave={() => needHoverState && this.setState({ hover: false })}
        {...others}
      >
        {icon && (
          <AppIcon
            svg="app-bar"
            color={color}
            name={needHoverState && hover ? `${icon}-hover` : icon}
          />
        )}
        {(children || desc) && (
          <div>
            <div className={classNames('first-row', desc ? 'with-second-row' : '')}>{children}</div>
            {desc && <div className="second-row">{desc}</div>}
          </div>
        )}
      </Button>
    );

    return to ? (
      <Link
        to={to}
        tabIndex={-1}
      >
        {button}
      </Link>
    ) : (
      button
    );
  }
}

export default IconButton;
