import {
  getUserLabDomains,
  postUserLabDomains,
  patchUserLabDomains,
  deleteUserLabDomains,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'userLabDomainModel',
  state: {
    labNetworkConfigTypes: [],
    userLabDomains: [],
    currentUserLabDomain: {},
  },
  subscriptions: {},
  effects: {
    *getUserLabDomains({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'userLabDomainModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ userLabDomainModel }) => ({
          refineParams: userLabDomainModel.refineParams,
        }));

        const data = yield call(getUserLabDomains, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            userLabDomains: data,
            currentUserLabDomain: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'userLabDomainModel',
            loading: false,
          },
        });
      }
    },
    *setUserCurrLabDomains({ payload }, { call, put }) {
      try {
        if (payload.username) {
          const data = yield call(getUserLabDomains, {
            username: payload.username,
          });
          if (data[0] && data[0].labDomains) {
            payload.formRef &&
              payload.formRef.current.setFieldValue(
                'labDomains',
                data[0].labDomains.map((item) => item._id)
              );
          }
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postUserLabDomains({ payload, callback }, { call, put }) {
      try {
        yield call(postUserLabDomains, payload);

        yield put({ type: 'getUserLabDomains' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchUserLabDomains({ payload, callback }, { call, put }) {
      try {
        yield call(patchUserLabDomains, payload);

        yield put({ type: 'getUserLabDomains' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteUserLabDomains(_, { call, select, put }) {
      try {
        const { currentUserLabDomain } = yield select(({ userLabDomainModel }) => ({
          currentUserLabDomain: userLabDomainModel.currentUserLabDomain,
        }));

        yield call(deleteUserLabDomains, currentUserLabDomain);

        yield put({ type: 'getUserLabDomains' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentUserLabDomainChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentUserLabDomain: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
