import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Modal } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import DocDetails from './doc-details';

const columns = [
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
  },
  {
    title: 'Documentation',
    dataIndex: 'doc',
    key: 'doc',
    render: (value) => (
      <a
        target="_blank"
        rel="noreferrer"
        href={value || ''}
      >
        {value}
      </a>
    ),
  },
];

export const ROW_KEY = 'version';

class AdminDocMCP extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'contentModel/getMCPDocsAdmin',
    });

    this.state = {
      modalType: '',
      selectedKey: null,
      refineParams: {},
    };
  }

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  renderSider = () => {
    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          onValuesChange={debounce((_, values) => {
            this.setState({ selectedKey: null, refineParams: values });
          }, 500)}
        >
          <Form.Item
            name="version"
            label="Version"
          >
            <Input />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      contentModel: { mcpDocs },
    } = this.props;
    const { selectedKey } = this.state;

    const selectedObj = mcpDocs[selectedKey];

    return selectedObj === undefined ? null : (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove the Navigator MC documentation.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'contentModel/deleteMCPDocs',
                    payload: { version: selectedKey },
                  });
                },
              });
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  };

  onSelect = (selectedKey) => {
    this.setState({ selectedKey: selectedKey === this.state.selectedKey ? null : selectedKey });
  };

  render() {
    const { contentModel, dispatch } = this.props;
    const { modalType, selectedKey, refineParams } = this.state;
    const mcpDocs = refineParams.version
      ? contentModel.mcpDocs[refineParams.version] === undefined
        ? {}
        : { [refineParams.version]: contentModel.mcpDocs[refineParams.version] }
      : contentModel.mcpDocs;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Network_audits"
          color="#0271bc"
          title="Navigator MC Documentation"
          subtitle="Manage the Navigator MC Documentation"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => this.showModal('new'),
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                pagination={false}
                loading={contentModel.loading}
                dataSource={Object.entries(mcpDocs).map(([version, doc]) => ({ version, doc }))}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [selectedKey],
                  onChange: (selectedKeys) => this.onSelect(selectedKeys[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onSelect(record[ROW_KEY]),
                })}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {modalType === 'new' && (
          <DocDetails
            onCancel={this.hideModal}
            dispatch={dispatch}
          />
        )}
      </div>
    );
  }
}

export default connect(({ contentModel }) => ({
  contentModel,
}))(AdminDocMCP);
