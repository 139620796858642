import { TOOLBAR_ICONS, ROUTES, CHATWOOT } from '@/constants';
import ReportIssue from '@/components/report-issue';

const getToolbarItem = (icon, { contentCategories = [], contentEditCategories = [], contentSubCategories }) => {
  const toolbar = {
    [TOOLBAR_ICONS.LAB_DOCS]: {
      key: 'doc_lab',
      icon: 'network',
      label: 'Lab Docs',
      columns: [
        [
          {
            key: 'learning',
            header: 'Learning',
            color: '#0271bc',
            items: (contentCategories || [])
              .filter(category => !['APIs', 'Samples'].includes(category))
              .map(category => ({
                key: category.toLowerCase(),
                icon: 'MCP_app_menu_System_profiles',
                label: category,
                to: ROUTES.DOCLAB_CONTENT + '?category=' + category,
              }))
          },
          {
            key: 'support',
            header: 'Support',
            color: '#0271bc',
            items: [
              {
                key: 'report-issue',
                icon: 'MCP_app_menu_Network_elements_operations',
                label: 'Report Issue',
                component: ReportIssue,
              },
            ],
          },
        ],
        [
          {
            key: 'api-docs',
            header: 'API Documentation',
            color: '#359e35',
            items: (contentSubCategories['APIs'] || []).map((item) => ({
              key: item.key,
              icon: 'MCP_app_menu_API',
              label: item.label,
              to: ROUTES.DOCLAB_CONTENT + '?category=APIs&sub_category=' + item.label,
            })),
          },
        ],
        [
          {
            key: 'samples',
            header: 'Samples',
            color: '#099',
            items: (contentSubCategories['Samples'] || []).map((item) => ({
              key: item.key,
              icon: 'MCP_app_menu_System_services',
              label: item.label,
              to: ROUTES.DOCLAB_CONTENT + '?category=Samples&sub_category=' + item.label,
            })),
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.MCP_DOCS]: {
      key: 'doc_mcp',
      icon: 'adaptive-ip',
      label: 'Navigator NCS Docs',
      columns: [
        [
          {
            key: 'mcp-docs',
            color: '#0271bc',
            items: [
              {
                key: 'mcp-docs',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Navigator MC Documentation',
                to: ROUTES.DOCMCP,
              },
            ],
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.VIDEOS]: {
      key: 'video',
      icon: 'application',
      label: 'Videos',
      columns: [
        [
          {
            key: 'videos',
            color: '#0271bc',
            items: [
              {
                key: 'mcp-workflow-videos',
                icon: 'MCP_app_menu_Problem_analysis',
                label: 'Navigator MC Workflow Videos',
                action: () => window.open(ROUTES.VIDEO),
              },
            ],
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.LABS]: {
      key: 'lab',
      icon: 'planning',
      label: 'Labs',
      columns: [
        [
          {
            key: 'labs',
            color: '#0271bc',
            items: [
              {
                key: 'schedule',
                icon: 'MCP_app_menu_Roles',
                label: 'My lab schedule',
                desc: 'View your upcomping lab events.',
                to: ROUTES.LAB_SCHEDULE,
              },
              {
                key: 'book-lab-time',
                icon: 'MCP_app_menu_Permisssions',
                label: 'Book lab time',
                desc: 'Book some time in the lab',
                to: ROUTES.LAB_SCHEDULE_NEW,
                exclude: { role: ['Student'], managedLongTerm: [true] },
              },
              {
                key: 'user-files',
                icon: 'MCP_app_menu_Export_logs',
                label: 'User Files',
                desc: 'Ciena user files',
                to: ROUTES.LAB_USERFILE,
                exclude: { role: ['Student'], managedLongTerm: [true] },
              },
            ],
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.ANALYTICS]: {
      key: 'analytics',
      icon: 'analytics',
      label: 'Analytics',
      columns: [
        [
          {
            key: 'analytics',
            color: '#0271bc',
            items: [
              {
                key: 'generate-report',
                icon: 'MCP_app_menu_Metrics',
                label: 'Generate Report',
                to: ROUTES.ANALYTICS_GENERATE_REPORT,
              },
            ],
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.MEDIA]: {
      key: 'media',
      icon: 'orchestration',
      label: 'Media',
      columns: [
        [
          {
            key: 'video-portal',
            color: '#0271bc',
            items: [
              {
                key: 'mcp-workflow-videos',
                icon: 'MCP_app_menu_Problem_analysis',
                label: 'Video Portal',
                action: (dispatch) => {
                  dispatch({ type: 'videoModel/getMediaVideoPortalUrl' }).then((url) =>
                    window.open(url)
                  );
                },
              },
            ],
          },
          {
            key: 'documentation-video',
            color: '#0271bc',
            items: [
              {
                key: 'mcp-workflow-videos',
                icon: 'MCP_app_menu_Problem_analysis',
                label: 'Documentation Video',
                to: ROUTES.MEDIA_DOC_VIDEO,
              },
            ],
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.INSTRUCTOR]: {
      key: 'instructor',
      icon: 'nfvo',
      label: 'Instructor',
      columns: [
        [
          {
            key: 'instructors',
            color: '#0271bc',
            items: [
              {
                key: 'classroom-management',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Classroom Management',
                to: ROUTES.INSTRUCTOR_CLASSROOM,
              },
              {
                key: 'student-management',
                icon: 'MCP_app_menu_User_accounts',
                label: 'Student Management',
                to: ROUTES.INSTRUCTOR_STUDENT,
              },
              {
                key: 'dashboard',
                icon: 'MCP_app_menu_Security_audit_dashboard',
                label: 'Dashboard',
                to: ROUTES.INSTRUCTOR_DASHBOARD,
              },
            ],
          },
        ],
      ],
    },
    [TOOLBAR_ICONS.TRAININGADMIN]: {
      key: 'trainingadmin',
      icon: 'system',
      label: 'Training Admin',
      columns: [
        [
          {
            key: 'schedule-management',
            header: 'Schedule Management',
            color: '#8d6ccf',
            items: [
              {
                key: 'schedule-management',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Schedule Management',
                to: ROUTES.ADMIN_SCHEDULE,
              },
            ],
          },
        ], [
          {
            key: 'content-management',
            header: 'Content Management',
            color: '#0271bc',
            items: [
              ...contentEditCategories.map((category) => ({
                key: category,
                icon: 'MCP_app_menu_System_profiles',
                label: category,
                to: ROUTES.ADMIN_CONTENT + '?category=' + category,
              })),
              {
                key: 'sub-categories',
                icon: 'MCP_app_menu_Network_audits',
                label: 'Sub-categories',
                to: ROUTES.ADMIN_SUBCATEGORY,
              },
            ],
          },
        ],
      ]
    },
    [TOOLBAR_ICONS.ADMIN]: {
      key: 'admin',
      icon: 'system',
      label: 'Admin',
      columns: [
        [
          {
            key: 'content-management',
            header: 'Content Management',
            color: '#0271bc',
            items: [
              ...contentCategories.map((category) => ({
                key: category,
                icon: 'MCP_app_menu_System_profiles',
                label: category,
                to: ROUTES.ADMIN_CONTENT + '?category=' + category,
              })),
              {
                key: 'mcp-docs',
                icon: 'MCP_app_menu_Network_audits',
                label: 'Navigator MC Documentation',
                to: ROUTES.ADMIN_DOCMCP,
              },
              {
                key: 'sub-categories',
                icon: 'MCP_app_menu_Network_audits',
                label: 'Sub-categories',
                to: ROUTES.ADMIN_SUBCATEGORY,
              },
            ],
          },
        ],
        [
          {
            key: 'security',
            header: 'Security',
            color: '#359e35',
            items: [
              {
                key: 'active-sessions',
                icon: 'MCP_app_menu_Active_sessions',
                label: 'Active Sessions',
                to: ROUTES.ADMIN_ACTIVESESSION,
              },
              {
                key: 'user-accounts',
                icon: 'MCP_app_menu_User_accounts',
                label: 'User Accounts',
                to: ROUTES.ADMIN_USERACCOUNT,
              },
              {
                key: 'permissions',
                icon: 'MCP_app_menu_Permisssions',
                label: 'Permissions',
                to: ROUTES.ADMIN_PERMISSION,
              },
              {
                key: 'blacklisting',
                icon: 'MCP_app_menu_Security',
                label: 'Blacklisting',
                to: ROUTES.ADMIN_BLACKLIST,
              },
            ],
          },
          {
            key: 'domain-management',
            header: 'Domain Management',
            color: '#359e35',
            items: [
              {
                key: 'domain-management',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Domain Management',
                to: ROUTES.ADMIN_DOMAIN,
              },
              {
                key: 'user-domain-management',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'User Domain Management',
                to: ROUTES.ADMIN_USERDOMAIN,
              },
            ],
          },
        ],
        [
          {
            key: 'site-management',
            header: 'Site Management',
            color: '#8d6ccf',
            items: [
              {
                key: 'portal-eula',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Portal EULA',
                to: ROUTES.ADMIN_EULA,
              },
              {
                key: 'fum-eula',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'FUM EULA',
                to: ROUTES.ADMIN_FILEUPLOADEULA,
              },
              {
                key: 'landing-page',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Landing Page',
                to: ROUTES.ADMIN_LANDINGPAGE,
              },
              {
                key: 'events-schedules',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Event Schedules',
                to: ROUTES.ADMIN_EVENT,
              },
              {
                key: 'swagger-files',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Swagger Files',
                to: ROUTES.ADMIN_SWAGGERFILE,
              },
              {
                key: 'device-images',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Device Images',
                to: ROUTES.ADMIN_DEVICEIMAGE,
              },
            ],
          },
          {
            key: 'schedule-management',
            header: 'Schedule Management',
            color: '#8d6ccf',
            items: [
              {
                key: 'schedule-management',
                icon: 'MCP_app_menu_Audit_logs',
                label: 'Schedule Management',
                to: ROUTES.ADMIN_SCHEDULE,
              },
            ],
          },
        ],
        [
          {
            key: 'video-management',
            header: 'Video Management',
            color: '#099',
            items: [
              {
                key: 'video-portal',
                icon: 'MCP_app_menu_Problem_analysis',
                label: 'Video Portal',
                action: (dispatch) => {
                  dispatch({ type: 'videoModel/getMediaAdminVideoPortalUrl' }).then((url) =>
                    window.open(url)
                  );
                },
              },
            ],
          },
          {
            key: 'analytics',
            header: 'Analytics',
            color: '#099',
            items: [
              {
                key: 'Analytics-portal',
                icon: 'MCP_app_menu_Metrics',
                label: 'Analytics Portal',
                action: (dispatch) => {
                  dispatch({ type: 'analyticsModel/getAnalyticsAdminAnalyticsPortalUrl' }).then(
                    (url) => window.open(url)
                  );
                },
              },
            ],
          },
          {
            key: 'totara-management',
            header: 'Totara Management',
            color: '#099',
            items: [
              {
                key: 'totara-api',
                icon: 'MCP_app_menu_API',
                label: 'Totara API',
                action: () => window.open(ROUTES.ADMIN_TOTARAAPI),
              },
            ],
          },
          {
            key: 'portal-management',
            header: 'Portal Management',
            color: '#099',
            items: [
              {
                key: 'portal-admin-api',
                icon: 'MCP_app_menu_API',
                label: 'Portal Admin API',
                action: () => window.open(ROUTES.ADMIN_PORTALAPI),
              },
            ],
          },
          {
            key: 'report-issue-management',
            header: 'Report Issue Management',
            color: '#099',
            items: [
              {
                key: 'report-issues',
                icon: 'MCP_app_menu_Network_elements_operations',
                label: 'Report Issues',
                to: ROUTES.ADMIN_REPORTISSUE,
              },
              {
                key: 'chatwoot-portal',
                icon: 'MCP_app_menu_Network_elements_operations',
                label: 'Chatwoot Portal',
                action: () => window.open(CHATWOOT.BASE_URL),
                hidden: CHATWOOT === undefined,
              },
            ],
          },
        ],
      ],
    },
  };

  return toolbar[icon];
};

export default getToolbarItem;
