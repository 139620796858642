import React from 'react';
import { connect } from 'dva';
import { Form, Input, Select, Row, Col, Button, Modal } from 'antd';
import InfoBar from '@/components/info-bar';

import './index.less';

class User extends React.Component {
  switchDomain = (portalDomain) => {
    const {
      userModel: { profile },
    } = this.props;

    Modal.confirm({
      title: 'Warning',
      content: 'This will switch portal domain.',
      cancelButtonProps: { type: 'text' },
      onOk: () => {
        this.props.dispatch({
          type: 'appModel/patchAuthSwitchDomain',
          payload: { ...profile, portalDomain },
        });
      },
    });
  };

  render() {
    const {
      userModel: { profile },
    } = this.props;

    return (
      <div>
        <InfoBar
          title="User Profile"
          icon="MCP_app_menu_User_accounts"
          color="#359e35"
          subtitle="Your Ciena user details."
        />
        <div className="container page white-bg account-info-container">
          <div className="page-title">Your Account Info</div>
          <Form layout="vertical">
            <Row gutter={32}>
              <Col flex={1}>
                <Form.Item label="First Name">
                  <Input
                    disabled
                    value={profile.firstName}
                  />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label="Last Name">
                  <Input
                    disabled
                    value={profile.lastName}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Company">
              <Input
                disabled
                value={profile.parentAccountName}
              />
            </Form.Item>
            <Form.Item label="Username">
              <Input
                disabled
                value={profile.username}
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                disabled
                value={profile.email}
              />
            </Form.Item>
            <Form.Item label="Member of Portal Domains">
              <Input
                disabled
                value={profile.memberOfPortalDomains && profile.memberOfPortalDomains.join(', ')}
              />
            </Form.Item>
            <Form.Item label="Portal Domain">
              <Select
                value={profile.portalDomain}
                onChange={this.switchDomain}
                options={
                  profile.memberOfPortalDomains &&
                  profile.memberOfPortalDomains.map((item) => ({
                    label: item,
                    value: item,
                  }))
                }
              />
            </Form.Item>
          </Form>
          <Button
            type="text"
            href="https://portal.ciena.com/portal/server.pt?open=space&name=ChangePassword&psname=PortalSettings&psid=3&cached=true&in_hi_userid=164839&control=EditorStart&editorType=10"
            target="_blank"
          >
            Change Password
          </Button>
          <Button
            type="text"
            href="https://portal.ciena.com/portal/server.pt?open=space&name=UserProfileEditor&psname=PortalSettings&psid=3&cached=true&in_hi_userid=164839&control=EditorStart&editorType=10"
            target="_blank"
          >
            Upgrade Profile
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ userModel }) => ({
  userModel,
}))(User);
