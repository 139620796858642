import React from 'react';
import moment from 'moment';
import { Modal, Form, Table, Button, Timeline, Tooltip } from 'antd';
import AppIcon from '@/components/app-icon';
import ClassroomEdit from '@/components/classroom-edit';
import { DATE_TIME_FORMAT } from '@/constants';
import copyToClipboard from '@/utils/copyToClipboard';

export default class ClassroomDetails extends React.Component {
  state = {
    showModal: false,
  };

  labConfigColumns = [
    {
      title: 'Lab Config',
      dataIndex: 'config',
      key: 'config',
    },
    {
      title: 'Instructor Only',
      dataIndex: 'instructorOnly',
      key: 'instructorOnly',
      render: (value) => (value ? 'Yes' : ''),
    },
    {
      title: 'fileUpload',
      dataIndex: ['fileUpload', 'uploadName'],
      key: 'fileUpload',
    },
    {
      title: 'Enable Audit',
      dataIndex: 'enableAudit',
      key: 'enableAudit',
      render: (value, { auditType }) => (value ? `Yes${auditType ? ', ' + auditType : ''}` : ''),
    },
  ];
  studentColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Login Link',
      dataIndex: 'loginLink',
      key: 'loginLink',
      render: (value, record) => (
        <>
          <Button
            style={{ marginRight: 8 }}
            type="text"
            onClick={() => {
              const { classroom, dispatch } = this.props;

              dispatch({
                type: 'classroomModel/patchClassroomSendStudentEmails',
                payload: {
                  studentEmail: record.email,
                  classroomId: classroom.classroomId,
                },
              });
            }}
          >
            Send
          </Button>
          <Tooltip title={value}>
            <Button
              type="primary"
              onClick={() => copyToClipboard(value)}
            >
              Copy
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  bookingColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Lab Config',
      dataIndex: 'config',
      key: 'config',
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
    },
    {
      title: 'Booking ID',
      dataIndex: 'bookingId',
      key: 'bookingId',
    },
  ];

  showAdd = (type) => {
    this.setState({ showModal: type });
  };

  hideAdd = () => {
    this.setState({ showModal: false });
  };

  renderAddModal = () => {
    const { dispatch, classroom } = this.props;
    const { showModal } = this.state;

    if (!showModal) return null;

    const labConfigDisabled = showModal !== 'labConfigs';
    const studentDisabled = showModal !== 'students';

    return (
      <ClassroomEdit
        classroom={classroom}
        labConfigDisabled={labConfigDisabled}
        studentDisabled={studentDisabled}
        onCancel={this.hideAdd}
        onEdit={({ labConfigs, students }) => {
          !labConfigDisabled &&
            dispatch({
              type: 'classroomModel/patchClassroomAddLabConfigs',
              payload: { classroomId: classroom.classroomId, labConfigs },
              callback: this.hideAdd,
            });
          !studentDisabled &&
            dispatch({
              type: 'studentModel/patchClassroomAddStudents',
              payload: { classroomId: classroom.classroomId, students },
              callback: this.hideAdd,
            });
        }}
      />
    );
  };

  render() {
    const { classroom, onCancel, dispatch, adminRole } = this.props;
    const isEdit = ['IDLE', 'CREATED', 'ACTIVE', 'RUNNING'].includes(classroom.status);

    let bookings = [];
    classroom.bookings &&
      Object.keys(classroom.bookings).forEach((email) => {
        bookings = [...bookings, ...classroom.bookings[email].map((item) => ({ ...item, email }))];
      });

    return (
      <Modal
        visible
        width={1000}
        title="Classroom Details"
        closable={false}
        onCancel={onCancel}
        footer={
          <Button
            type="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
        >
          <Form.Item label="Title">
            <span>{classroom.title}</span>
          </Form.Item>
          <Form.Item label="Description">
            <span>{classroom.description}</span>
          </Form.Item>
          <Form.Item label="Schedule">
            <span>
              {moment(classroom.startTime).format(DATE_TIME_FORMAT) +
                ' to ' +
                moment(classroom.endTime).format(DATE_TIME_FORMAT)}
            </span>
          </Form.Item>
          <Form.Item label="Instructor">
            <span>{classroom.instructor}</span>
          </Form.Item>
          <Form.Item label="Classroom ID">
            <span>{classroom.classroomId}</span>
          </Form.Item>
          <Form.Item label="Lab Configs">
            {isEdit && (
              <Button
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => this.showAdd('labConfigs')}
              >
                Add
              </Button>
            )}
            <Table
              bordered
              size="small"
              rowKey="config"
              pagination={false}
              dataSource={classroom.labConfigs}
              columns={
                isEdit
                  ? [
                      ...this.labConfigColumns,
                      {
                        key: 'actions',
                        width: 40,
                        render: (_, record) => (
                          <AppIcon
                            name="trashcan"
                            color="#0271bc"
                            onClick={() => {
                              Modal.confirm({
                                title: 'Warning',
                                content: 'This will remove lab config.',
                                cancelButtonProps: { type: 'text' },
                                onOk: () => {
                                  dispatch({
                                    type: 'classroomModel/patchClassroomRemoveLabConfigs',
                                    payload: {
                                      classroomId: classroom.classroomId,
                                      labConfigs: [record],
                                    },
                                  });
                                },
                              });
                            }}
                          />
                        ),
                      },
                    ]
                  : this.labConfigColumns
              }
            />
          </Form.Item>
          <Form.Item label="Student Enrolled">
            {isEdit && (
              <Button
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => this.showAdd('students')}
              >
                Add
              </Button>
            )}
            <Table
              bordered
              size="small"
              rowKey="email"
              pagination={false}
              dataSource={classroom.students}
              columns={
                isEdit
                  ? [
                      ...this.studentColumns,
                      {
                        key: 'actions',
                        width: 40,
                        render: (_, record) => (
                          <AppIcon
                            name="trashcan"
                            color="#0271bc"
                            onClick={() => {
                              Modal.confirm({
                                title: 'Warning',
                                content: 'This will remove student.',
                                cancelButtonProps: { type: 'text' },
                                onOk: () => {
                                  dispatch({
                                    type: 'studentModel/patchClassroomRemoveStudents',
                                    payload: {
                                      classroomId: classroom.classroomId,
                                      students: [record],
                                    },
                                  });
                                },
                              });
                            }}
                          />
                        ),
                      },
                    ]
                  : this.studentColumns
              }
            />
          </Form.Item>
          <Form.Item label="Bookings">
            <Table
              bordered
              size="small"
              rowKey="bookingId"
              pagination={false}
              dataSource={bookings}
              columns={
                classroom.status === 'RUNNING' || classroom.status === 'ACTIVE'
                  ? [
                      ...this.bookingColumns,
                      {
                        key: 'actions',
                        render: (_, { userType, email, bookingId }) =>
                          userType !== 'INSTRUCTOR' && (
                            <Button
                              type="text"
                              onClick={() => {
                                Modal.confirm({
                                  title: 'Warning',
                                  content: 'This will restart booking.',
                                  cancelButtonProps: { type: 'text' },
                                  onOk: () => {
                                    dispatch({
                                      type: 'classroomModel/patchClassroomRestartBooking',
                                      payload: {
                                        classroomId: classroom.classroomId,
                                        email,
                                        bookingId,
                                      },
                                    });
                                  },
                                });
                              }}
                            >
                              Restart
                            </Button>
                          ),
                      },
                    ]
                  : this.bookingColumns
              }
            />
          </Form.Item>
          {adminRole && classroom.statusHistory && (
            <Form.Item label="Status History">
              <Timeline style={{ marginTop: 12 }}>
                {classroom.statusHistory.map((item, index) => (
                  <Timeline.Item key={`timeline-${index}`}>
                    {item.status} {moment(item.date).format(DATE_TIME_FORMAT)}
                  </Timeline.Item>
                ))}
              </Timeline>
            </Form.Item>
          )}
        </Form>
        {this.renderAddModal()}
      </Modal>
    );
  }
}
