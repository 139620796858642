import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Select, Table, Button, Modal, Tooltip, Tree } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import FileUploadDetails from './file-upload-details';

import './index.less';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, { name, full_name, files }) => {
      return (
        <Tree.DirectoryTree
          treeData={[
            {
              title: name,
              key: full_name,
              children: files.map((item, index) => ({
                title: item,
                key: item + index,
                isLeaf: true,
              })),
            },
          ]}
        />
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'Valid',
    dataIndex: 'is_valid',
    key: 'is_valid',
    align: 'center',
    render: (_, { is_valid, invalid_reason }) => (
      <Tooltip title={invalid_reason}>
        <AppIcon name={is_valid ? 'status-checkmark' : 'status-fail'} />
      </Tooltip>
    ),
  },
];

export const ROW_KEY = 'full_name';

class LabUserFile extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'fileUploadModel/getFileUploadsFiles' });
    props.dispatch({ type: 'fileUploadModel/getFileUploadsUploadTypesList' });
    props.dispatch({ type: 'fileUploadEulaModel/getEulasLatest' });

    this.state = {
      showModal: false,
      selectedRows: [],
    };
  }

  showFileUploadDetails = () => {
    this.setState({ showModal: true });
  };

  hideFileUploadDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      fileUploadModel: { fileUploadTypes, refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        {refineParams && refineParams.uploadDomain && (
          <Form
            layout="vertical"
            initialValues={refineParams}
            onValuesChange={debounce((_, values) => {
              const { dispatch } = this.props;

              this.setState({ selectedRows: [] });
              dispatch({
                type: 'fileUploadModel/getFileUploadsFiles',
                payload: values,
              });
              dispatch({
                type: 'fileUploadModel/setState',
                payload: {
                  refineParams: values,
                },
              });
            }, 500)}
          >
            <Form.Item
              label="Domain"
              name="uploadDomain"
            >
              <Select
                options={Object.keys(fileUploadTypes).map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </Form.Item>
          </Form>
        )}
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      fileUploadModel: { refineParams },
    } = this.props;
    const { selectedRows } = this.state;

    return selectedRows.length ? (
      <div className="table-footer">
        <div>{selectedRows.length} selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove the file upload.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'fileUploadModel/deleteFileUploads',
                    payload: {
                      uploadDomain: refineParams.uploadDomain,
                      uploads: selectedRows,
                    },
                    callback: () => {
                      this.setState({ selectedRows: [] });
                    },
                  });
                },
              });
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    ) : null;
  };

  render() {
    const {
      fileUploadModel: { loading, fileUploads, fileUploadTypes, refineParams },
      fileUploadEulaModel: { latestEula },
      dispatch,
    } = this.props;
    const { showModal, selectedRows } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Export_logs"
          color="#0271bc"
          title="User Files"
          subtitle="Manage the files that appears in the CEC"
          actions={
            refineParams && refineParams.uploadDomain && fileUploadTypes[refineParams.uploadDomain]
              ? [
                  {
                    label: 'New',
                    icon: <AppIcon name="add" />,
                    onClick: () => {
                      this.showFileUploadDetails();
                    },
                  },
                ]
              : undefined
          }
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={fileUploads.uploads}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: selectedRows.map((item) => item[ROW_KEY]),
                  onChange: (_, selectedRows) => this.setState({ selectedRows }),
                }}
                onRow={(record) => ({
                  onClick: () => {
                    let newSelectedRows = selectedRows.filter(
                      (item) => item[ROW_KEY] !== record[ROW_KEY]
                    );
                    if (newSelectedRows.length === selectedRows.length) {
                      newSelectedRows.push(record);
                    }
                    this.setState({ selectedRows: newSelectedRows });
                  },
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <FileUploadDetails
            fileUpload={refineParams}
            fileUploadTypes={fileUploadTypes}
            eula={latestEula}
            onCancel={this.hideFileUploadDetails}
            dispatch={dispatch}
          />
        )}
      </div>
    );
  }
}

export default connect(({ fileUploadModel, fileUploadEulaModel }) => ({
  fileUploadModel,
  fileUploadEulaModel,
}))(LabUserFile);
