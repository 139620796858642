import moment from 'moment';
import CryptoJS from 'crypto-js';

const proxy = {
  deprecated_rules: {
    EXAMPLE: { secret: 'proxysecretword', path: '/path/to/endpoint', name: 'EXAMPLE' },
    MCP: { secret: 'lakelouise', path: '/mcp', name: 'MCP' },
    MCP_LOGIN: { secret: 'lakelouise', path: '/proxy/mcp/login', name: 'MCP_LOGIN' },
    MDSO_LOGIN: { secret: 'table', path: '/proxy/mdso/login', name: 'MDSO_LOGIN' },
    MCMS_LOGIN: { secret: 'lakelouise', path: '/proxy/mcms/login', name: 'MCMS_LOGIN' },
    Waverouter_LOGIN: { secret: 'lakelouise', path: '/proxy/waverouter/login', name: 'Waverouter_LOGIN' },
    EXTERNAL_API: { secret: 'kickinghorse', path: '/externalApi', name: 'EXTERNAL_API' },
    MDSO_EXTERNAL_API: {
      secret: 'kickinghorse',
      path: '/externalApi/mdso',
      name: 'MDSO_EXTERNAL_API',
    },
    MCP_EXTERNAL_API: {
      secret: 'kickinghorse',
      path: '/externalApi/mcp',
      name: 'MCP_EXTERNAL_API',
    },
    PINS_EXTERNAL_API: {
      secret: 'kickinghorse',
      path: '/externalApi/ome',
      name: 'PINS_EXTERNAL_API',
    },
    RLS_EXTERNAL_API: {
      secret: 'kickinghorse',
      path: '/externalApi/rls',
      name: 'RLS_EXTERNAL_API',
    },
    PLANNER_EXTERNAL_API: {
      secret: 'kickinghorse',
      path: '/externalApi/p2',
      name: 'PLANNER_EXTERNAL_API',
    },
    UTIL_API: { secret: 'sunshine', path: '/util', name: 'UTIL_API' },
    PINS_EVENT_LISTENER: {
      secret: 'jumbo',
      path: '/6500/lab/api/eventListener',
      name: 'PINS_EVENT_LISTENER',
    },
    RLS_EVENT_LISTENER: {
      secret: 'jumbo',
      path: '/rls/lab/api/eventListener',
      name: 'RLS_EVENT_LISTENER',
    },
    PINS_LOGIN: { secret: 'red', path: '/6500/lab/api/login', name: 'PINS_LOGIN' },
    PINS_LOGOUT: { secret: 'blue', path: '/6500/lab/api/logout', name: 'PINS_LOGOUT' },
    RLS_LOGIN: { secret: 'red', path: '/rls/lab/api/login', name: 'RLS_LOGIN' },
    RLS_LOGOUT: { secret: 'blue', path: '/rls/lab/api/logout', name: 'RLS_LOGOUT' },
    PINS_SITE_MGR: { secret: 'apex', path: '/6500/lab/siteManager', name: 'PINS_SITE_MGR' },
    PINS_CONVERTER: { secret: 'cain', path: '/6500/lab/converter', name: 'PINS_CONVERTER' },
    GRPC_EXTERNAL: { secret: 'edgewater', path: '/lab/grpc/lab', name: 'GRPC_EXTERNAL' },
    FUM: { secret: 'montSaintAnne', path: '/fileUploadManager', name: 'FUM' },
    LAB_GENERIC: { secret: 'revelstoke', path: '/lab', name: 'LAB_GENERIC' },
    WS_NOTIF: { secret: 'vale', path: '/lab/waveserver/notification', name: 'WS_NOTIF' },
    WS_DATA: { secret: 'vale', path: '/lab/waveserver/datastore', name: 'WS_DATA' },
    WS_WACSIM_NOTIF: { secret: 'apex', path: '/wacsim', name: 'WS_WACSIM_NOTIF' },
    DNFVI_LOGIN: { secret: 'baldface', path: '/lab/dnfvi/login', name: 'DNFVI_LOGIN' },
    MCP_NOTIF: { secret: 'revelstoke', path: '/mcp/lab/notifications', name: 'MCP_NOTIF' },
    VIDEO_PLATFORM: { secret: 'castle', path: '/external_video', name: 'VIDEO_PLATFORM' },
    SAOS_WEBGUI: { secret: 'westrevvi', path: '/saoswebgui', name: 'SAOS_WEBGUI', wgPath: '/auth/login' },
    RLS_WEBGUI: { secret: 'fortress', path: '/rlswebgui', name: 'RLS_WEBGUI', wgPath: '/' },
    PLANNER_PLUS: { secret: 'lakelouise', path: '/p2', name: 'PLANNER_PLUS' },
    PLANNER_PLUS_LOGIN: {
      secret: 'lakelouise',
      path: '/proxy/p2/login',
      name: 'PLANNER_PLUS_LOGIN',
    },
  },
  mapping: (vdType) => {
    if (vdType.indexOf('MCP') > -1 || vdType.indexOf('Navigator MC') > -1) {
      return 'MCP';
    }
    if (vdType.indexOf('Reconfigurable Line System') > -1) {
      return 'RLS';
    }
    if (vdType.indexOf('6500') > -1) {
      return 'PINS';
    }
    if (vdType.indexOf('Waveserver') > -1) {
      return 'WS';
    }
    if (vdType.indexOf('PlannerPlus') > -1) {
      return 'PLANNER_PLUS';
    }
    if (vdType.indexOf('SAOS') > -1) {
      return 'SAOS';
    }

    return vdType;
  },
  ipToOctets: (ip) => {
    const rawSplitOctets = ip.split(':')[0].split('.');

    return { o1: Number(rawSplitOctets[2]), o2: Number(rawSplitOctets[3]) };
  },
  deprecated_computePath: (ruleKey, o1, o2, bookingEndTime, path = '', params = '') => {
    const rule = proxy.deprecated_rules[ruleKey];
    if (!rule) return;
    
    const expiration = moment(bookingEndTime).unix();
    const secret = rule.secret;

    const pathname = `${rule.path}/o1/${o1}/o2/${o2}${path}`;

    const calcPath = expiration + pathname + ' ' + secret;
    const binaryHash = CryptoJS.MD5(calcPath);
    const base64Value = binaryHash.toString(CryptoJS.enc.Base64);
    const md5 = base64Value
      .replace(new RegExp(/=/g), '')
      .replace(new RegExp(/\+/g), '-')
      .replace(/\//g, '_')
      .replace('=', '');

    const search = `md5=${md5}&expires=${expiration}${params}`;

    return `${pathname}?${search}`;
  },
};

export default proxy;
