import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Select, Table, List, Button, Modal, Tag } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import PermissionDetails from './permission-details';

import './index.less';

const permissionFields = ['action', 'subject', 'fields', 'conditions', 'inverted', 'reason'];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 500,
    render: (value) => (
      <div className="first-column">
        <AppIcon
          svg="security"
          name="user-role"
        />
        <div>
          <div className="primary-row">{value}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Permissions',
    dataIndex: 'permissions',
    key: 'permissions',
    render: (permissions = []) => (
      <>
        <div className="second-column">
          <Tag color="#10a54a">PERMISSION</Tag>
          <div className="">{permissions.length} permissions</div>
        </div>
        {permissionList(permissions)}
      </>
    ),
  },
];

export const permissionList = (permissions, onDelete) => (
  <List
    className="permission-list"
    size="small"
    rowKey={ROW_KEY}
    dataSource={permissions}
    renderItem={(permission, index) => {
      let listItem = '';
      permissionFields.forEach((field) => {
        let value = permission[field];
        if (Array.isArray(value)) {
          value = permission[field].join(', ');
        } else if (typeof value === 'object') {
          value = JSON.stringify(value);
        }

        if (value !== undefined && value !== '') {
          listItem += `<b>${field}: </b>${value}; `;
        }
      });

      let actions = [];
      if (onDelete) {
        actions.push(
          <AppIcon
            name="trashcan"
            color="#0271bc"
            onClick={() => {
              permissions.splice(index, 1);
              onDelete();
            }}
          />
        );
      }

      return (
        <List.Item
          row={permission[ROW_KEY]}
          size="small"
          actions={actions}
        >
          <span dangerouslySetInnerHTML={{ __html: listItem }} />
        </List.Item>
      );
    }}
  />
);

export const ROW_KEY = '_id';

class AdminPermission extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'permissionModel/getUserPermissions' });

    this.state = {
      showModal: false,
      expandedRowKeys: [],
    };
  }

  showPermissionDetails = () => {
    this.setState({ showModal: true });
  };

  hidePermissionDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      permissionModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'permissionModel/getUserPermissions',
              payload: values,
            });
            this.props.dispatch({
              type: 'permissionModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Names"
            name="names"
          >
            <Select mode="tags" />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      permissionModel: { currentPermission },
    } = this.props;

    return currentPermission[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove permission.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'permissionModel/deleteUserPermissions',
                    payload: { _id: currentPermission._id },
                  });
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={this.showPermissionDetails}
          >
            Details
            <AppIcon name="open-tabs" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentPermissionChange = (record = {}) => {
    const {
      permissionModel: { currentPermission },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentPermission[ROW_KEY] ? {} : record;
    dispatch({ type: 'permissionModel/onCurrentPermissionChange', payload });
  };

  render() {
    const {
      permissionModel: { loading, permissions, currentPermission },
      dispatch,
    } = this.props;
    const { showModal, expandedRowKeys } = this.state;

    return (
      <div className="permission-container">
        <InfoBar
          icon="MCP_app_menu_Permisssions"
          color="#359e35"
          title="Permission Management"
          subtitle="Manage permissions in the CEC"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => {
                this.onCurrentPermissionChange();
                this.showPermissionDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <div className="table-toolbar">
                <div />
                <div>
                  <Button
                    type="text"
                    onClick={() => this.setState({ expandedRowKeys: [] })}
                  >
                    Collapse all
                  </Button>
                  <Button
                    type="text"
                    onClick={() =>
                      this.setState({
                        expandedRowKeys: permissions.map((item) => item[ROW_KEY]),
                      })
                    }
                  >
                    Expand all
                  </Button>
                </div>
              </div>
              <Table
                size="small"
                showHeader={false}
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={permissions}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentPermission[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentPermissionChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  className: expandedRowKeys.includes(record[ROW_KEY]) ? 'expanded-row' : '',
                  onClick: () => this.onCurrentPermissionChange(record),
                })}
                expandable={{
                  expandedRowRender: () => {},
                  expandedRowKeys,
                  onExpandedRowsChange: (expandedRowKeys) => {
                    this.setState({ expandedRowKeys });
                  },
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <AppIcon
                      className={`expand-icon ${expanded ? 'expanded' : ''}`}
                      svg="app-bar"
                      name="icons/launch/transition-arrow"
                      onClick={(e) => {
                        e.stopPropagation();
                        onExpand(record, e);
                      }}
                    />
                  ),
                }}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <PermissionDetails
            onCancel={this.hidePermissionDetails}
            dispatch={dispatch}
            permission={currentPermission}
          />
        )}
      </div>
    );
  }
}

export default connect(({ permissionModel }) => ({
  permissionModel,
}))(AdminPermission);
