import React from 'react';
import { Modal, Form, Button, Upload } from 'antd';
import { CKEditor } from 'ckeditor4-react';

class NewEula extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      body: values.body && values.body.editor ? values.body.editor.getData() : values.body,
      file:
        values.file && values.file.length > 0
          ? values.file.map((item) => item.originFileObj)
          : values.file,
    };

    this.setState({
      isSaveDisabled: !this.values.body,
    });
  };

  render() {
    const {
      eula: { body, file },
      onCancel,
      dispatch,
    } = this.props;
    const { isSaveDisabled } = this.state;

    const fileList = [];
    if (file) {
      fileList.push({
        uid: file.filename,
        name: file.originalName,
        url: `/${file.path}/${file.filename}`,
      });
    }

    return (
      <Modal
        visible
        width={1000}
        title="New File Upload EULA"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={() => {
                Modal.confirm({
                  title: 'Warning',
                  content: 'Unsaved data will be lost.',
                  cancelButtonProps: { type: 'text' },
                  onOk: onCancel,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: 'fileUploadEulaModel/postEulas',
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Create
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 3 }}
          initialValues={{
            body,
          }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Body"
            name="body"
          >
            <CKEditor initData={body} />
          </Form.Item>
          <Form.Item
            name="file"
            label="Attachment"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              maxCount={1}
              customRequest={({ onSuccess }) => onSuccess()}
              defaultFileList={fileList}
            >
              <Button>Choose File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default NewEula;
