import React from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

class SubCategoryDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = values;

    this.setState({
      isSaveDisabled: !this.values.label || !this.values.category,
    });
  };

  render() {
    const { categories, category, onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;

    return (
      <Modal
        visible
        width={800}
        title="New Sub-Category"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: 'contentModel/postSubCategories',
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 3 }}
          initialValues={{ category }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Category"
            name="category"
          >
            <Select
              showArrow
              options={categories.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          <Form.Item
            label="Label"
            name="label"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default SubCategoryDetails;
