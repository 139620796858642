import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { ROW_KEY } from '../';

class BlacklistDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...this.props.blacklist,
      ...values,
    };

    this.setState({ isSaveDisabled: !this.values.domain });
  };

  render() {
    const { blacklist, onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;
    const isEdit = this.props.blacklist[ROW_KEY];

    return (
      <Modal
        visible
        width={600}
        title={`${isEdit ? 'Edit' : 'New'} Blacklist`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: 'blacklistModel/postDomainBlacklist',
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 6 }}
          initialValues={blacklist}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Domain"
            name="domain"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default BlacklistDetails;
