import React from 'react';
import { Modal, Form, Button, Input, Select, Upload, Checkbox } from 'antd';

class FileUploadDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaveDisabled: true,
    };

    this.values = props.fileUpload;
  }

  onValuesChange = (values) => {
    this.values = {
      ...values,
      file:
        values.file && values.file.length > 0
          ? values.file.map((item) => item.originFileObj)
          : values.file,
    };

    this.setState({
      isSaveDisabled:
        !this.values.uploadName ||
        !this.values.uploadDomain ||
        !this.values.uploadType ||
        !this.values.file ||
        !this.values.file.length > 0,
    });
  };

  render() {
    const { fileUploadTypes, eula, onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;

    return (
      <Modal
        className="file-upload-modal"
        visible
        width={600}
        title="Upload Files"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: 'fileUploadModel/postFileUploads',
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          initialValues={this.values}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            className="eula-form-item"
            label="File Upload End User License Agreement"
          >
            <div
              className="eula-body"
              dangerouslySetInnerHTML={{ __html: eula.body }}
            />
            <Form.Item
              name="acceptEula"
              valuePropName="checked"
            >
              <Checkbox>I accept these terms</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Name"
            name="uploadName"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domain"
            name="uploadDomain"
          >
            <Select
              options={Object.keys(fileUploadTypes).map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="uploadType"
          >
            <Select
              options={(fileUploadTypes[this.values.uploadDomain] || []).map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="file"
            label="Upload Files"
            tooltip=".json, .slat, .xml, .yaml, .txt"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              multiple
              customRequest={({ onSuccess }) => onSuccess()}
              accept=".json, .slat, .xml, .yaml, .txt"
            >
              <Button>Choose File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default FileUploadDetails;
