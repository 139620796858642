import React from 'react';
import { Modal, Form, Input, Button, Upload } from 'antd';
import UserSelect from '@/components/user-select';

const DEFAULT_NOTIFICATION = {
  subject: 'Ciena Emulation Cloud Notification',
};

class NotifyBCC extends React.Component {
  formRef = React.createRef();

  render() {
    const { onCancel, dispatch } = this.props;

    return (
      <Modal
        visible
        width={600}
        title="Notification"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.formRef.current && this.formRef.current.submit();
              }}
            >
              Send
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 5 }}
          initialValues={DEFAULT_NOTIFICATION}
          ref={this.formRef}
          onFinish={(values) => {
            if (values.bcc) {
              values.bcc = values.bcc.join(',');
            }
            if (values.files && values.files.length > 0) {
              values.files = values.files.map((item) => item.originFileObj);
            }

            dispatch({
              type: 'reportIssueModel/postEmailSendNotifyToBCCList',
              payload: values,
              callback: onCancel,
            });
          }}
        >
          <Form.Item
            label="BCC"
            name="bcc"
            rules={[{ required: true }]}
          >
            <UserSelect mode="tags" />
          </Form.Item>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Text"
            name="text"
            rules={[{ required: true }]}
          >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 15 }} />
          </Form.Item>
          <Form.Item
            name="files"
            label="Attachments"
            tooltip=".txt, .text, .xml, .json, .jpg, .jpeg, .png, .gif"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              multiple
              customRequest={({ onSuccess }) => onSuccess()}
              accept=".txt, .text, .xml, .json, .jpg, .jpeg, .png, .gif"
            >
              <Button>Choose File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default NotifyBCC;
