import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Alert, List, Avatar } from 'antd';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_STUDENTLABAUDIT } from '@/services';

import './index.less';

class BookingAudit extends React.Component {
  state = {
    bookingAudit: this.props.lab.bookingAudit || {},
  };

  getLabsBookingsAudit = () => {
    const { lab, dispatch } = this.props;

    dispatch({
      type: 'labModel/getLabsBookingsAudit',
      payload: { bookingId: lab.bookingId },
    }).then((bookingAudit) => this.setState({ bookingAudit }));
  };

  render() {
    const { onCancel, lab, readOnly, dispatch } = this.props;
    const { bookingAudit } = this.state;

    let auditButton = {
      auditPhase: '',
      text: 'Audit',
      disabled: true,
    };
    if (bookingAudit.phases) {
      const currentAuditPhase = bookingAudit.phases.find((item) => item.status !== 'Complete');

      if (currentAuditPhase) {
        auditButton.auditPhase = currentAuditPhase.name;
        auditButton.text = 'Audit ' + currentAuditPhase.name;
        auditButton.disabled = !['Idle', 'Retry'].includes(currentAuditPhase.status);
      } else {
        auditButton.text = 'Audit Completed';
      }
    }

    return (
      <Modal
        visible
        width={1200}
        className="audit-container"
        title="Perform Audit"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {!readOnly && (
              <Button
                type="primary"
                disabled={auditButton.disabled}
                onClick={() => {
                  dispatch({
                    type: 'labModel/patchLabsBookingsAudit',
                    payload: { bookingId: lab.bookingId, auditPhase: auditButton.auditPhase },
                  });
                }}
              >
                {auditButton.text}
              </Button>
            )}
          </>
        }
      >
        <div className="modal-title">
          Phases
          <span>
            <div>
              Status: <strong>{bookingAudit.status}</strong>
            </div>
            {bookingAudit.grade && (
              <div>
                Grade: <strong>{bookingAudit.grade}</strong>
              </div>
            )}
          </span>
        </div>
        {['Finished', 'Complete'].includes(bookingAudit.status) && (
          <Alert
            showIcon
            type="info"
            message="Audit is complete, please see your email for a report."
            description={
              lab.bookingInstructor
                ? 'If you are a student, please see your instructor to review your results.'
                : ''
            }
          />
        )}
        <List
          bordered
          dataSource={bookingAudit.phases}
          renderItem={(item, index) => (
            <List.Item
              className="phase-item"
              style={{ color: item.phaseColour }}
            >
              <List.Item.Meta
                avatar={<Avatar icon={index + 1} />}
                title={item.name}
                description={
                  <>
                    {item.status}
                    {item.errorMsg && (
                      <Alert
                        showIcon
                        type="warning"
                        message={item.errorMsg}
                      />
                    )}
                  </>
                }
              />
              {item.grade && (
                <div>
                  Grade: <strong>{item.grade}</strong>
                </div>
              )}
              <div
                className="background-div"
                style={{ backgroundColor: item.phaseColour }}
              />
            </List.Item>
          )}
        />
        <ServerSideEvent
          url={{
            pathname: EVENTS_STUDENTLABAUDIT,
            params: { bookingId: lab.bookingId },
          }}
          onmessage={this.getLabsBookingsAudit}
          onopen={this.getLabsBookingsAudit}
        />
      </Modal>
    );
  }
}

export default connect()(BookingAudit);
