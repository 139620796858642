import React from 'react';
import classNames from 'classnames';
import { Card as AntCard, Dropdown, Menu } from 'antd';
import AppIcon from '@/components/app-icon';

import './index.less';

const Card = (props) => {
  const { className, ...others } = props;

  return (
    <div
      className={classNames(className, 'card-container')}
      {...others}
    />
  );
};

Card.Item = (props) => {
  const { title = ' ', details = [], children } = props;
  const actions = (props.actions || []).filter((item) => !item.hidden);

  return (
    <AntCard
      title={title}
      actions={
        actions.length > 0
          ? [
              (<Dropdown
                overlay={
                  <Menu
                    className="action-menu"
                    items={actions}
                  />
                }
              >
                <AppIcon name="more" />
              </Dropdown>),
            ]
          : undefined
      }
    >
      {details.map(({ label, content, visible = true, className }, index) =>
        visible ? (
          <div
            key={'card-item' + index}
            className={classNames('detail-item', className)}
          >
            <div className="detail-label">{label}</div>
            <div className="detail-content">{content}</div>
          </div>
        ) : null
      )}
      {children}
    </AntCard>
  );
};

export default Card;
