import React from 'react';
import { Badge, Tooltip, Table, notification, Modal } from 'antd';
import Countdown from '@/components/Countdown';
import IconButton from '@/components/icon-button';
import AppIcon from '@/components/app-icon';
import LabDetails from '@/components/lab-details';
import SimActions from '@/components/sim-actions';
import BookingAudit from '@/components/booking-audit';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_USERNOTIFICATIONEVENTS, EVENTS_USERLABSCHEDULE } from '@/services';

import './index.less';

const ROW_KEY = 'bookingId';

class Notification extends React.Component {
  state = {
    showNotification: false,
    labBookingActive: [],
    modalType: '',
    bookingId: '',
  };

  showModal = (bookingId, modalType) => {
    this.setState({ bookingId, modalType, showNotification: false });
  };

  hideModal = () => {
    this.setState({ bookingId: '', modalType: '' });
  };

  getLabsBookingsActive = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'labModel/getLabsBookingsActive',
    }).then((labBookingActive) => this.setState({ labBookingActive }));
  };

  render() {
    const { dispatch } = this.props;
    const { showNotification, labBookingActive, modalType, bookingId } = this.state;

    return (
      <Tooltip
        overlayClassName="notification-wrapper"
        placement="bottomRight"
        trigger={['click']}
        visible={showNotification}
        onVisibleChange={(showNotification) => this.setState({ showNotification })}
        title={
          <Table
            className="notification-table"
            size="small"
            rowKey={ROW_KEY}
            dataSource={labBookingActive}
            showHeader={false}
            pagination={false}
            columns={[
              {
                key: 'title',
                render: (_, { title, description }) => (
                  <div>
                    <div className="table-title">{title}</div>
                    <div>{description && description.join(', ')}</div>
                  </div>
                ),
              },
              {
                key: 'time-remaining',
                dataIndex: 'endTime',
                render: (value) => (
                  <div>
                    <div>Time Remaining:</div>
                    <Countdown value={value} />
                  </div>
                ),
              },
              {
                key: 'actions',
                render: (_, lab) => (
                  <>
                    <div className="actions-wrapper">
                      <Tooltip title="Perform Sim Action">
                        <AppIcon
                          className="round-icon"
                          name="setting"
                          onClick={() => this.showModal(lab.bookingId, 'perform-sim-action')}
                        />
                      </Tooltip>
                      <Tooltip title="Lab Details">
                        <AppIcon
                          name="info"
                          onClick={() => {
                            this.showModal(lab.bookingId, 'lab-details');
                          }}
                        />
                      </Tooltip>
                      {lab.maintenanceMode !== undefined && (
                        <Tooltip title={`Maintenance ${lab.maintenanceMode ? 'ON' : 'OFF'}`}>
                          <AppIcon
                            className="round-icon"
                            name="map-legend-maintenance"
                            onClick={() => {
                              this.showModal(lab.bookingId, '');
                              Modal.confirm({
                                title: 'Warning',
                                content: `This will ${
                                  lab.maintenanceMode ? 'disable' : 'enable'
                                } the maintenance mode.`,
                                cancelButtonProps: { type: 'text' },
                                onOk: () => {
                                  dispatch({
                                    type: 'labModel/patchLabsBookingsMaintenanceMode',
                                    payload: lab,
                                  });
                                },
                              });
                            }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="Open Interaction Page">
                        <AppIcon
                          className="round-icon"
                          name="chevron"
                          onClick={() => {
                            dispatch({
                              type: 'labModel/gotoLabInteraction',
                              payload: lab,
                            });
                          }}
                        />
                      </Tooltip>
                      {lab.enableAudit && (
                        <Tooltip title="Perform Audit">
                          <AppIcon
                            className="round-icon"
                            name="menu"
                            onClick={() => this.showModal(lab.bookingId, 'perform-audit')}
                          />
                        </Tooltip>
                      )}
                    </div>
                    {modalType === 'perform-sim-action' && bookingId === lab.bookingId && (
                      <SimActions
                        onCancel={this.hideModal}
                        lab={lab}
                      />
                    )}
                    {modalType === 'perform-audit' && bookingId === lab.bookingId && (
                      <BookingAudit
                        onCancel={this.hideModal}
                        lab={lab}
                      />
                    )}
                    {modalType === 'lab-details' && bookingId === lab.bookingId && (
                      <LabDetails
                        onCancel={this.hideModal}
                        labs={[lab]}
                        fields={[
                          { key: 'title' },
                          { key: 'description' },
                          { key: 'bookingOwner', label: 'Owner' },
                          { key: 'startTime' },
                          { key: 'endTime' },
                        ]}
                      />
                    )}
                  </>
                ),
              },
            ]}
          />
        }
      >
        <Badge
          size="small"
          color="#359e35"
          count={labBookingActive.length}
          offset={[-20, 15]}
        >
          <IconButton icon="notification" />
          <ServerSideEvent
            url={EVENTS_USERNOTIFICATIONEVENTS}
            onmessage={(e) => {
              const data = JSON.parse(e.data);
              notification.info({
                message: data.payload && data.payload.notificationMsg,
                duration: 8,
              });
            }}
          />
          <ServerSideEvent
            url={EVENTS_USERLABSCHEDULE}
            onmessage={() => {
              dispatch({ type: 'labModel/getLabsBookings', isSSE: true });
              this.getLabsBookingsActive();
            }}
            onopen={() => {
              dispatch({ type: 'labModel/getLabsBookings' });
              this.getLabsBookingsActive();
            }}
          />
        </Badge>
      </Tooltip>
    );
  }
}

export default Notification;
