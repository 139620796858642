import React from 'react';
import { connect } from 'dva';
import moment from 'moment';
import { Layout, Spin, Form, Input, Select, Modal, DatePicker } from 'antd';
import debounce from 'lodash/debounce';
import Countdown from '@/components/Countdown';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import Card from '@/components/card';
import { TIME_FORMAT, DATE_TIME_FORMAT, LAB_STATUS_LIST } from '@/constants';
import LabDetails from '@/components/lab-details';
import SimActions from '@/components/sim-actions';
import BookingAudit from '@/components/booking-audit';
import NewLab from './new-lab';

class LabSchedule extends React.Component {
  state = {
    modalType: '',
    bookingId: '',
  };

  showModal = (bookingId, modalType) => {
    this.setState({ bookingId, modalType });
  };

  hideModal = () => {
    this.setState({ bookingId: '', modalType: '' });
  };

  renderSider = () => {
    const {
      userModel: { profile },
      labModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'labModel/getLabsBookings',
              payload: values,
            });
            this.props.dispatch({
              type: 'labModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Status"
            name="labStatus"
          >
            <Select
              allowClear
              options={LAB_STATUS_LIST.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          {profile.labDomains && profile.labDomains.length > 1 && (
            <Form.Item
              label="Domain"
              name="bookingDomain"
            >
              <Select
                allowClear
                options={profile.labDomains}
                fieldNames={{ label: 'domainName', value: 'domainName' }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start After"
            name="startAfter"
          >
            <DatePicker
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
            />
          </Form.Item>
          <Form.Item
            label="End Before"
            name="endBefore"
          >
            <DatePicker
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
            />
          </Form.Item>
          <Form.Item
            label="Max Bookings"
            name="maxBookings"
          >
            <Input placeholder="Default is 10" />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  showNewLab = (showNewLab, newLab) => {
    this.props.dispatch({
      type: 'labModel/setState',
      payload: {
        showNewLab,
        newLab: newLab || {},
      },
    });
  };

  getActions = (lab) => {
    const {
      labModel: { labDomainUserTypes },
      userModel: { profile, adminRole },
      dispatch,
    } = this.props;
    const hidden =
      ['Student'].includes(profile.role) ||
      profile.managedLongTerm ||
      (!adminRole && labDomainUserTypes[lab.bookingDomain] === 'LONGTERM');

    const DIVIDER = { type: 'divider' };
    const PERFORMACTION = {
      key: 'perform-sim-action',
      label: 'Perform Sim Action',
      onClick: () => this.showModal(lab.bookingId, 'perform-sim-action'),
    };
    const OPENINTERACTION = {
      key: 'open-interaction-page',
      label: 'Open Interaction Page',
      onClick: () => {
        dispatch({
          type: 'labModel/gotoLabInteraction',
          payload: lab,
        });
      },
    };
    const AUDIT = {
      hidden: !lab.enableAudit,
      key: 'perform-audit',
      label: 'Perform Audit',
      onClick: () => this.showModal(lab.bookingId, 'perform-audit'),
    };
    const DETAILS = {
      key: 'lab-details',
      label: 'Lab Details',
      onClick: () => {
        this.showModal(lab.bookingId, 'lab-details');
      },
    };
    const CANCEL = {
      hidden: lab.classroomId || hidden,
      key: 'cancel-lab',
      label: 'Cancel Lab',
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: 'This will cancel the lab booking.',
          cancelButtonProps: { type: 'text' },
          onOk: () => {
            dispatch({
              type: 'labModel/patchLabsBookingsCancel',
              payload: lab,
            });
          },
        });
      },
    };
    const RESTART = {
      hidden: !lab.classroomId || hidden,
      key: 'restart',
      label: 'Restart',
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: 'This will restart booking.',
          cancelButtonProps: { type: 'text' },
          onOk: () => {
            dispatch({
              type: 'classroomModel/patchClassroomRestartBooking',
              payload: {
                classroomId: lab.classroomId,
                email: lab.bookingOwner.slice(lab.bookingOwner.indexOf('_') + 1),
                bookingId: lab.bookingId,
              },
            });
          },
        });
      },
    };
    const BOOK = {
      hidden,
      key: 'book-again',
      label: 'Book Again',
      onClick: () =>
        this.showNewLab(true, {
          bookAgain: true,
          title: lab.title,
          bookingOwner: lab.bookingOwner,
          bookingDomain: lab.bookingDomain,
          bookingRequestData: {
            labConfigs: lab.labConfigs,
          },
        }),
    };
    const DELETE = {
      hidden,
      key: 'delete-lab',
      label: 'Delete Lab',
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: 'This will delete the lab booking.',
          cancelButtonProps: { type: 'text' },
          onOk: () => {
            dispatch({
              type: 'labModel/deleteLabsBookingsScheduled',
              payload: lab,
            });
          },
        });
      },
    };
    const MAINTENANCE = {
      hidden: lab.maintenanceMode === undefined,
      key: 'maintenance',
      label: (
        <>
          Maintenance{' '}
          <span style={{ color: lab.maintenanceMode ? '#359e35' : '#414141', fontWeight: 500 }}>
            {lab.maintenanceMode ? 'ON' : 'OFF'}
          </span>
        </>
      ),
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: `This will ${lab.maintenanceMode ? 'disable' : 'enable'} the maintenance mode.`,
          cancelButtonProps: { type: 'text' },
          onOk: () => {
            dispatch({
              type: 'labModel/patchLabsBookingsMaintenanceMode',
              payload: lab,
            });
          },
        });
      },
    };

    switch (lab.status) {
      case 'Scheduled':
        return [DELETE];
      case 'Starting':
      case 'Configuring':
      case 'Pausing before restart':
        return [CANCEL];
      case 'Active':
        return [
          PERFORMACTION,
          OPENINTERACTION,
          AUDIT,
          DIVIDER,
          DETAILS,
          MAINTENANCE,
          DIVIDER,
          CANCEL,
        ];
      case 'Error':
        return [RESTART, CANCEL];
      case 'Ended':
      case 'Ended with error':
        return [BOOK];
      default:
        return null;
    }
  };

  render() {
    const {
      labModel: { loading, labBookings, showNewLab },
      labModel,
      userModel,
      dispatch,
    } = this.props;
    const { bookingId, modalType } = this.state;

    return (
      <div className="lab-schedule-container">
        <InfoBar
          icon="MCP_app_menu_Roles"
          color="#0271bc"
          title="My Lab Schedule"
          subtitle="You can book a lab or access a lab or view your upcoming and passed lab events."
          actions={
            ['Student'].includes(userModel.profile.role) || userModel.profile.managedLongTerm
              ? undefined
              : [
                  {
                    label: 'New',
                    icon: <AppIcon name="add" />,
                    onClick: () => this.showNewLab(true),
                  },
                ]
          }
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="title">Recent Labs</div>
            <Spin
              style={{ height: 400 }}
              spinning={loading}
            >
              <Card>
                {labBookings.map((lab) => (
                  <Card.Item
                    key={lab.bookingId}
                    title={lab.title}
                    actions={this.getActions(lab)}
                    details={[
                      {
                        label: 'Description',
                        content: lab.description && lab.description.join(', '),
                      },
                      { label: 'Owner', content: lab.bookingOwner },
                      {
                        label: 'Start Time',
                        content: lab.startTime && moment(lab.startTime).format(DATE_TIME_FORMAT),
                      },
                      {
                        label: 'End Time',
                        content: lab.endTime && moment(lab.endTime).format(DATE_TIME_FORMAT),
                      },
                      {
                        label: 'Time Remaining',
                        content: <Countdown value={lab.endTime} />,
                        visible: moment(lab.endTime) > moment() && lab.showRemainingTime,
                      },
                      {
                        label: 'Status',
                        content: <span style={{ color: lab.statusColour }}>{lab.status}</span>,
                        className: 'primary',
                      },
                    ]}
                  >
                    {modalType === 'perform-sim-action' && bookingId === lab.bookingId && (
                      <SimActions
                        onCancel={this.hideModal}
                        lab={lab}
                      />
                    )}
                    {modalType === 'perform-audit' && bookingId === lab.bookingId && (
                      <BookingAudit
                        onCancel={this.hideModal}
                        lab={lab}
                      />
                    )}
                    {modalType === 'lab-details' && bookingId === lab.bookingId && (
                      <LabDetails
                        onCancel={this.hideModal}
                        labs={[lab]}
                        fields={[
                          { key: 'title' },
                          { key: 'description' },
                          { key: 'bookingOwner', label: 'Owner' },
                          { key: 'startTime' },
                          { key: 'endTime' },
                        ]}
                      />
                    )}
                  </Card.Item>
                ))}
              </Card>
            </Spin>
          </Layout.Content>
        </Layout>
        {showNewLab && userModel.profile.username && (
          <NewLab
            {...labModel}
            username={userModel.profile.username}
            portalDomain={userModel.profile.portalDomain}
            adminRole={userModel.adminRole}
            onCancel={() => this.showNewLab(false)}
            dispatch={dispatch}
          />
        )}
      </div>
    );
  }
}

export default connect(({ labModel, userModel }) => ({
  labModel,
  userModel,
}))(LabSchedule);
