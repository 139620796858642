import React from 'react';
import Cookie from 'js-cookie';
import { connect } from 'dva';
import { Select } from 'antd';
import InfoBar from '@/components/info-bar';

class DocMCP extends React.Component {
  state = {
    mcpDocs: {},
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch({ type: 'contentModel/getMCPDocs' }).then((mcpDocs) => this.setState({ mcpDocs }));
  }

  render() {
    const {
      userModel: { profile },
    } = this.props;
    const { mcpDocs } = this.state;

    return (
      <div>
        <InfoBar
          title="Navigator MC Documentation"
          icon="MCP_app_menu_Audit_logs"
          color="#0271bc"
          subtitle="Select the version Navigator MC Documentation to review."
        />
        <div className="container page white-bg account-info-container">
          <h3>Select the Navigator MC Version</h3>
          <p>Click below to select the version of Navigator MC Documentation you wish to review.</p>
          <Select
            showSearch
            allowClear
            style={{ width: 250 }}
            placeholder="Navigator MC Version"
            options={Object.entries(mcpDocs).map(([label, value]) => ({ label, value }))}
            onSelect={(value, { label }) => {
              // nginx looks for this cookie before redirects to /mcp-documentation page, 
              Cookie.set('mcp_doc_page', true);
              value && window.open(value);

              /* Matomo */
              value &&
                _paq.push([
                  'trackEvent',
                  'MCP Document',
                  'Read',
                  JSON.stringify({
                    version: label,
                    username: profile.username,
                  }),
                ]);
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect(({ userModel }) => ({
  userModel,
}))(DocMCP);
