import React from 'react';
import { Collapse } from 'antd';
import InfoBar from '@/components/info-bar';
import { getSearchParam } from '@/utils';
import LabInteractionPanel from './lab-interaction-panel';

class LabInteraction extends React.Component {
  render() {
    const { match, dispatch } = this.props;
    const bookingIds = match.params.bookingId.split(',');
    const configs = getSearchParam('config') ? getSearchParam('config').split(',') : [];

    return (
      <div className="lab-schedule-container">
        <InfoBar
          icon="MCP_app_menu_Roles"
          color="#0271bc"
          title="My Lab Schedule"
          subtitle="Interact with the lab through one of the available methods"
        />
        <div className="container page white-bg">
          {bookingIds.length > 1 ? (
            <Collapse
              ghost
              accordion
              bordered={false}
              className="highlight-collapse-header"
            >
              {bookingIds.map((bookingId, index) => (
                <Collapse.Panel
                  key={bookingId}
                  header={configs[index] || bookingId}
                >
                  <LabInteractionPanel
                    dispatch={dispatch}
                    bookingId={bookingId}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          ) : (
            <LabInteractionPanel
              dispatch={dispatch}
              bookingId={bookingIds[0]}
            />
          )}
        </div>
      </div>
    );
  }
}

export default LabInteraction;
