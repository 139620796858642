import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { Layout, Form, Input, Select, Table, Button, Modal, DatePicker } from 'antd';
import debounce from 'lodash/debounce';
import Countdown from '@/components/Countdown';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import AppIcon from '@/components/app-icon';
import ServerSideEvent from '@/components/server-side-event';
import {
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  LAB_STATUS_LIST,
  NC_STATUS_LIST,
  BOOKING_STATUS_LIST,
} from '@/constants';
import { EVENTS_ADMINLABSCHEDULE } from '@/services';
import LabDetails from '@/components/lab-details';
import SimActions from '@/components/sim-actions';
import BookingAudit from '@/components/booking-audit';
import UpdateBooking from './update-booking';

import './index.less';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    className: 'description-column',
    render: (value) => value && value.join('\n'),
  },
  {
    title: 'Start Time',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: 'End Time',
    dataIndex: 'endTime',
    key: 'endTime',
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, { statusColour: color }) => <span style={{ color }}>{value}</span>,
  },
  {
    title: 'NC Status',
    dataIndex: 'ncStatus',
    key: 'ncStatus',
  },
  {
    title: 'Booking Status',
    dataIndex: 'bookingStatus',
    key: 'bookingStatus',
  },
  {
    title: 'Owner',
    dataIndex: 'bookingOwner',
    key: 'bookingOwner',
  },
  {
    title: 'Time Remaining',
    key: 'time-remaining',
    render: (_, { endTime, showRemainingTime }) => {
      if (moment(endTime) > moment() && showRemainingTime) {
        return <Countdown value={endTime} />;
      }
    },
  },
  {
    title: 'User Visible',
    dataIndex: 'userVisible',
    key: 'userVisible',
    align: 'center',
    render: (checked) => <AppIcon name={checked ? 'status-checkmark' : 'status-fail'} />,
  },
];

export const ROW_KEY = 'bookingId';
class AdminSchedule extends React.Component {
  state = {
    modalType: '',
  };

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  renderSider = () => {
    const {
      scheduleModel: { refineParams },
      dispatch,
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            dispatch({
              type: 'scheduleModel/onRefineParamsChange',
              payload: values,
            });
          }, 500)}
        >
          <Form.Item
            label="Status"
            name="labStatus"
          >
            <Select
              allowClear
              options={LAB_STATUS_LIST.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          <Form.Item
            label="NC Status"
            name="ncStatus"
          >
            <Select
              allowClear
              options={NC_STATUS_LIST.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          <Form.Item
            label="Booking Status"
            name="bookingStatus"
          >
            <Select
              allowClear
              options={BOOKING_STATUS_LIST.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          <Form.Item
            label="Booking ID"
            name="bookingId"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Owner"
            name="bookingOwner"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start After"
            name="startAfter"
          >
            <DatePicker
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
            />
          </Form.Item>
          <Form.Item
            label="End Before"
            name="endBefore"
          >
            <DatePicker
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
            />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  getActions = () => {
    const {
      scheduleModel: { currentLab },
      dispatch,
    } = this.props;

    const PERFORMACTION = {
      key: 'perform-sim-action',
      label: 'Perform Sim Action',
      onClick: () => this.showModal('perform-sim-action'),
    };
    const OPENINTERACTION = {
      key: 'open-interaction-page',
      label: 'Open Interaction Page',
      onClick: () => {
        dispatch({
          type: 'labModel/gotoLabInteraction',
          payload: currentLab,
        });
      },
    };
    const AUDIT = currentLab.enableAudit
      ? {
          key: 'perform-audit',
          label: 'Perform Audit',
          onClick: () => this.showModal('perform-audit'),
        }
      : {};
    const DETAILS = {
      key: 'details',
      label: [
        'Details',
        <AppIcon
          key="icon"
          name="open-tabs"
        />,
      ],
      onClick: () => {
        this.showModal('lab-details');
      },
    };
    const CANCEL = !currentLab.classroomId
      ? {
          key: 'cancel-lab',
          label: 'Cancel Lab',
          type: 'text',
          onClick: () => {
            Modal.confirm({
              title: 'Warning',
              content: 'This will cancel the lab booking.',
              cancelButtonProps: { type: 'text' },
              onOk: () => {
                dispatch({
                  type: 'scheduleModel/patchLabsBookingsAdminCancel',
                  payload: currentLab,
                });
              },
            });
          },
        }
      : {};
    const RESTART = currentLab.classroomId
      ? {
          key: 'restart',
          label: 'Restart',
          onClick: () => {
            Modal.confirm({
              title: 'Warning',
              content: 'This will restart booking.',
              cancelButtonProps: { type: 'text' },
              onOk: () => {
                dispatch({
                  type: 'classroomModel/patchClassroomRestartBooking',
                  payload: {
                    classroomId: currentLab.classroomId,
                    email: currentLab.bookingOwner.slice(currentLab.bookingOwner.indexOf('_') + 1),
                    bookingId: currentLab.bookingId,
                  },
                });
              },
            });
          },
        }
      : {};
    const DELETE = {
      key: 'delete-lab',
      label: 'Delete Lab',
      onClick: () => {
        Modal.confirm({
          title: 'Warning',
          content: 'This will delete the lab booking.',
          cancelButtonProps: { type: 'text' },
          onOk: () => {
            this.props.dispatch({
              type: 'scheduleModel/deleteLabsBookingsScheduledAdmin',
              payload: currentLab,
            });
          },
        });
      },
    };
    const BOOK = {
      key: 'book-again',
      label: 'Book Again',
      onClick: () => dispatch({ type: 'scheduleModel/bookAgain' }),
    };
    const UPDATE = {
      key: 'update',
      label: [
        'Update',
        <AppIcon
          key="icon"
          name="edit"
        />,
      ],
      onClick: () => {
        this.showModal('update-booking');
      },
    };
    const MAINTENANCE =
      currentLab.maintenanceMode === undefined
        ? {}
        : {
            key: 'maintenance',
            label: `Maintenance ${currentLab.maintenanceMode ? 'ON' : 'OFF'}`,
            onClick: () => {
              Modal.confirm({
                title: 'Warning',
                content: `This will ${
                  currentLab.maintenanceMode ? 'disable' : 'enable'
                } the maintenance mode.`,
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  dispatch({
                    type: 'labModel/patchLabsBookingsMaintenanceMode',
                    payload: currentLab,
                  });
                },
              });
            },
          };

    switch (currentLab.status) {
      case 'Scheduled':
        return [DELETE, UPDATE, DETAILS];
      case 'Starting':
      case 'Configuring':
        return [CANCEL, RESTART, UPDATE, DETAILS];
      case 'Pausing before restart':
        return [CANCEL, DETAILS];
      case 'Active':
        return [CANCEL, PERFORMACTION, OPENINTERACTION, AUDIT, MAINTENANCE, UPDATE, DETAILS];
      case 'Error':
        return [CANCEL, RESTART, DETAILS];
      case 'Ended':
      case 'Ended with error':
        return [BOOK, DETAILS];
      default:
        return [DETAILS];
    }
  };

  renderTableFooter = () => {
    const {
      scheduleModel: { currentLab },
    } = this.props;

    return currentLab[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          {this.getActions().map(({ label, type = 'primary', ...others }) =>
            label ? (
              <Button
                type={type}
                {...others}
              >
                {label}
              </Button>
            ) : null
          )}
        </div>
      </div>
    ) : null;
  };

  onCurrentLabChange = (record = {}) => {
    const {
      scheduleModel: { currentLab },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentLab[ROW_KEY] ? {} : record;
    dispatch({ type: 'scheduleModel/onCurrentLabChange', payload });
  };

  render() {
    const {
      scheduleModel: { loading, labBookings, currentLab },
      dispatch,
    } = this.props;
    const { modalType } = this.state;

    return (
      <div className="lab-schedule-container">
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#8d6ccf"
          title="Schedule Management"
          subtitle="Manage the lab access. Edit or delete labs that may already be booked but not started or that may have passed."
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={labBookings}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentLab[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentLabChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentLabChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {modalType === 'perform-sim-action' && (
          <SimActions
            onCancel={this.hideModal}
            lab={currentLab}
          />
        )}
        {modalType === 'perform-audit' && (
          <BookingAudit
            onCancel={this.hideModal}
            lab={currentLab}
          />
        )}
        {modalType === 'lab-details' && (
          <LabDetails
            onCancel={this.hideModal}
            labs={[currentLab]}
            fields={[
              { key: 'title' },
              { key: 'description' },
              { key: 'bookingOwner', label: 'Owner' },
              { key: 'bookingCreator', label: 'Creator' },
              { key: 'startTime' },
              { key: 'endTime' },
              { key: 'ncStatus', label: 'NC Status' },
              { key: 'bookingStatus', label: 'Booking Status' },
              { key: 'bookingId', label: 'Booking ID' },
              { key: 'ncStatusHistory', label: 'NC Status History' },
              { key: 'bookingStatusHistory', label: 'Booking Status History' },
              { key: 'userVisible' },
            ]}
          />
        )}
        {modalType === 'update-booking' && (
          <UpdateBooking
            onCancel={this.hideModal}
            lab={currentLab}
          />
        )}
        <ServerSideEvent
          url={EVENTS_ADMINLABSCHEDULE}
          onmessage={() =>
            dispatch({ type: 'scheduleModel/getLabsBookingsAdmin', isSSE: true })
          }
          onopen={() => dispatch({ type: 'scheduleModel/getLabsBookingsAdmin' })}
        />
      </div>
    );
  }
}

export default connect(({ scheduleModel }) => ({
  scheduleModel,
}))(AdminSchedule);
