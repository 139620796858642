import { message } from 'antd';
import {
  getUsersReportIssuesAdminListIssues,
  getUsersReportIssuesSupportedFileTypes,
  postUsersReportIssues,
  patchUsersReportIssuesAdminSendResponse,
  postEmailSendNotifyToBCCList,
} from '@/services';

const model = {
  namespace: 'reportIssueModel',
  state: {
    reportIssues: [],
    currentReportIssue: {},
  },
  subscriptions: {},
  effects: {
    *getUsersReportIssuesAdminListIssues({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'reportIssueModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ reportIssueModel }) => ({
          refineParams: reportIssueModel.refineParams,
        }));

        let params = { ...(payload || refineParams) };
        if (params && params.reproducible) {
          params.reproducible =
            params.reproducible.length === 1 ? params.reproducible[0] : undefined;
        }

        const data = yield call(getUsersReportIssuesAdminListIssues, params);

        yield put({
          type: 'setState',
          payload: {
            reportIssues: data,
            currentReportIssue: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'reportIssueModel',
            loading: false,
          },
        });
      }
    },
    *getUsersReportIssuesSupportedFileTypes(_, { put, call }) {
      try {
        const data = yield call(getUsersReportIssuesSupportedFileTypes);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return [];
      }
    },
    *postUsersReportIssues({ payload, callback }, { call, put }) {
      try {
        yield call(postUsersReportIssues, payload);

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchUsersReportIssuesAdminSendResponse({ payload, callback }, { call, put }) {
      try {
        yield call(patchUsersReportIssuesAdminSendResponse, payload);

        yield put({ type: 'getUsersReportIssuesAdminListIssues' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postEmailSendNotifyToBCCList({ payload, callback }, { call, put }) {
      try {
        yield call(postEmailSendNotifyToBCCList, payload);

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentReportIssueChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentReportIssue: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
