import React from 'react';
import { connect } from 'dva';

import './index.less';

const Footer = ({ appModel: { singlePage } }) => {
  return singlePage ? null : (
    <div className="footer">
      <p className="container">© 2015 - {new Date().getFullYear()} Ciena Corporation. All rights reserved.</p>
    </div>
  );
};

export default connect(({ appModel }) => ({ appModel }))(Footer);
