import { getDeviceImages, postDeviceImages, deleteDeviceImages } from '@/services';
import { DEVICE_IMAGE_PATH } from '@/constants';
import { message } from 'antd';

const model = {
  namespace: 'deviceImageModel',
  state: {
    files: [],
    currentFile: {},
  },
  subscriptions: {},
  effects: {
    *getFiles(_, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'deviceImageModel',
            loading: true,
          },
        });

        const data = yield call(getDeviceImages);
        yield put({
          type: 'setState',
          payload: {
            files: data.map((item) => ({
              fileName: item,
              path: DEVICE_IMAGE_PATH,
            })),
            currentFile: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'deviceImageModel',
            loading: false,
          },
        });
      }
    },
    *postFiles({ payload, callback }, { call, put }) {
      try {
        yield call(postDeviceImages, payload);

        yield put({ type: 'getFiles' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteFiles(_, { call, select, put }) {
      try {
        const { currentFile } = yield select(({ deviceImageModel }) => ({
          currentFile: deviceImageModel.currentFile,
        }));

        yield call(deleteDeviceImages, currentFile);

        yield put({ type: 'getFiles' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentFileChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentFile: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
