import moment from 'moment';

export const getDuration = (start, end) => {
  const duration = end ? moment(end) - moment(start) : start;
  const days = moment.duration(duration).days();

  let daysStr = '';
  if (days === 1) {
    daysStr = days + ' day ';
  } else if (days > 1) {
    daysStr = days + ' days ';
  }

  return daysStr + moment.utc(duration).format('HH:mm:ss');
};

export const addDuration = (start, duration) => {
  const startMoment = moment(start);
  const [hours, days] = duration
    .replace(/(\s*)d(\S*)(\s*)/gi, '.')
    .split('.')
    .reverse();

  startMoment.add(days, 'days');
  startMoment.add(hours, 'hours');

  return startMoment.format();
};

export const compareDuration = (duration1, duration2) => {
  const durationMoment1 = moment.duration(duration1, 'hours');
  const durationMoment2 = moment.duration(duration2, 'hours');

  return durationMoment1 >= durationMoment2;
};
