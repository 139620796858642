import {
  getMediaDocumentationMediaList,
  getMediaDocumentationProductTypes,
  getMediaDocumentationMediaListDocumentationReport,
  postMediaDocumentationDocumentationLinks,
  patchMediaDocumentationDocumentationLinks,
  deleteMediaDocumentationDocumentationLinks,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'docVideoModel',
  state: {
    docProductTypes: [],
    docVideos: [],
  },
  subscriptions: {},
  effects: {
    *getMediaDocumentationMediaList({ payload }, { call, put }) {
      try {
        const data = yield call(getMediaDocumentationMediaList, undefined, payload.referenceId);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return [];
      }
    },
    *getMediaDocumentationProductTypes(_, { call, put }) {
      try {
        const data = yield call(getMediaDocumentationProductTypes);

        yield put({
          type: 'setState',
          payload: {
            docProductTypes: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getMediaDocumentationMediaListDocumentationReport({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'docVideoModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ docVideoModel }) => ({
          refineParams: docVideoModel.refineParams,
        }));

        const data = yield call(
          getMediaDocumentationMediaListDocumentationReport,
          payload || refineParams
        );

        yield put({
          type: 'setState',
          payload: {
            docVideos: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'docVideoModel',
            loading: false,
          },
        });
      }
    },
    *postMediaDocumentationDocumentationLinks({ payload, callback }, { call, put }) {
      try {
        yield call(postMediaDocumentationDocumentationLinks, payload);

        yield put.resolve({ type: 'getMediaDocumentationMediaListDocumentationReport' });
        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchMediaDocumentationDocumentationLinks({ payload, callback }, { call, put }) {
      try {
        yield call(patchMediaDocumentationDocumentationLinks, payload);

        yield put.resolve({ type: 'getMediaDocumentationMediaListDocumentationReport' });
        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteMediaDocumentationDocumentationLinks({ payload }, { call, put }) {
      try {
        yield call(deleteMediaDocumentationDocumentationLinks, payload);

        yield put.resolve({ type: 'getMediaDocumentationMediaListDocumentationReport' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
