import React from 'react';
import { connect } from 'dva';

const formatUrl = (url) => {
  if (typeof url === 'string') return url;

  let { pathname, params = {} } = url;
  let urlSearchParams = [];

  for (let key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach((item) => {
        urlSearchParams.push(`${key}=${item}`);
      });
    } else if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
      urlSearchParams.push(`${key}=${params[key]}`);
    }
  }

  return urlSearchParams.length > 0 ? `${pathname}?${urlSearchParams.join('&')}` : pathname;
};

class ServerSideEvent extends React.Component {
  constructor(props) {
    super(props);

    this.connect();
  }

  connect = () => {
    const {
      url,
      adminUrl = url,
      userModel: { adminRole },
    } = this.props;

    this.eventSource = new EventSource(formatUrl(adminRole ? adminUrl : url));
    this.eventSource.onmessage = (e) => {
      if (e.lastEventId === 'PING' || e.data === '{}') return;
      this.props.onmessage && this.props.onmessage(e);
    };
    this.eventSource.onopen = this.props.onopen;
    this.eventSource.onerror = (e) => {
      console.log(e);
      if (this.eventSource.readyState === EventSource.CLOSED) {
        setTimeout(this.connect, 5000);
      }
    };
  };

  componentWillUnmount() {
    this.eventSource.close();
  }

  render() {
    return null;
  }
}

export default connect(({ userModel }) => ({
  userModel,
}))(ServerSideEvent);
