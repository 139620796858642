import React from 'react';
import { Modal, Button, Dropdown, Menu } from 'antd';
import { Link } from 'dva/router';
import IconButton from '@/components/icon-button';
import AppIcon from '@/components/app-icon';
import ReportIssue from '@/components/report-issue';
import { AUTH_LOGIN_SSO } from '@/services';
import { ROUTES } from '@/constants';

import './index.less';

class Account extends React.Component {
  state = {
    modalType: '',
  };

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  loginWithCiena = () => {
    document.getElementById('loginWithCiena').submit();
  };

  render() {
    const { firstName, dispatch } = this.props;
    const { modalType } = this.state;

    return firstName ? (
      <>
        <Dropdown
          arrow
          placement="bottom"
          trigger={['click']}
          overlay={
            <Menu
              selectable={false}
              className="account-menu"
              items={[
                {
                  key: 'profile',
                  label: <Link to={ROUTES.USER}>User Profile</Link>,
                },
                {
                  type: 'divider',
                },
                {
                  key: 'logIssue',
                  label: 'Report Issue',
                  onClick: () => this.showModal('reportIssue'),
                },
                {
                  type: 'divider',
                  className: 'thick-divider',
                },
                {
                  key: 'logout',
                  className: 'logout-item',
                  label: 'Logout',
                  onClick: () => {
                    dispatch({ type: 'appModel/logout' });
                  },
                },
              ]}
            />
          }
        >
          <div className="account">
            <IconButton icon="user">{firstName}</IconButton>
          </div>
        </Dropdown>
        {modalType === 'reportIssue' && <ReportIssue onCancel={this.hideModal} />}
      </>
    ) : (
      <div>
        <IconButton
          className={modalType === 'login' ? 'active' : ''}
          icon="user"
          onClick={() => this.showModal('login')}
        >
          Login
        </IconButton>
        <Modal
          className="unset-height-modal"
          closable={false}
          footer={null}
          visible={modalType === 'login'}
          onCancel={this.hideModal}
        >
          <div className="cec-login">
            <div className="modal-strip" />
            <AppIcon
              svg="ciena"
              name="ciena"
            />
            <div className="app-name">Emulation Cloud</div>
            <div className="login-buttons">
              <Button onClick={this.loginWithCiena}>
                <span className="button-logo ciena" />
                Log in with Ciena
              </Button>
              {/* <Button>
                <span className="button-logo salesforce" />
                Log in with Salesforce
              </Button> */}
            </div>
            <form
              id="loginWithCiena"
              method="GET"
              action={AUTH_LOGIN_SSO}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Account;
