import React from 'react';
import { Tabs } from 'antd';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_USERLABINTERACTIONS } from '@/services';
import InteractionPanel from '../interaction-panel';

class LabInteractionPanel extends React.Component {
  state = {
    labInteractions: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.bookingId !== prevProps.bookingId) {
      this.getLabInteractions();
    }
  }

  getLabInteractions = () => {
    const { dispatch, bookingId } = this.props;

    dispatch({
      type: 'labModel/getLabsInteractions',
      payload: { bookingId },
    }).then((labInteractions) => this.setState({ labInteractions }));
  };

  render() {
    const { labInteractions } = this.state;
    const { bookingId, dispatch } = this.props;

    return (
      <>
        <Tabs destroyInactiveTabPane>
          {labInteractions.map((item) => (
            <Tabs.TabPane
              key={item._id}
              tab={item.vd_id}
            >
              <InteractionPanel
                virtualDevice={item}
                dispatch={dispatch}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
        <ServerSideEvent
          url={{
            pathname: EVENTS_USERLABINTERACTIONS,
            params: { bookingId },
          }}
          onmessage={this.getLabInteractions}
          onopen={this.getLabInteractions}
        />
      </>
    );
  }
}

export default LabInteractionPanel;
