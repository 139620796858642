import {
  getLandingPage,
  postLandingPage,
  patchLandingPage,
  deleteLandingPage,
  getLandingPageActive,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'landingPageBannerModel',
  state: {
    activeBanner: {
      title: 'Shift app development into high gear.',
      body: 'Accelerate DevOps and ease web-scale IT integration with <b>Ciena Emulation Cloud</b>.',
      img: require('@/assets/img/home/banner-img.png'),
    },
    landingPageBanners: [],
    currentBanner: {},
  },
  subscriptions: {},
  effects: {
    *getLandingPageActive(_, { call, select, put }) {
      try {
        const data = yield call(getLandingPageActive);
        if (data && data.length > 0) {
          const activeBanner = yield select(({ landingPageBannerModel }) => ({
            ...landingPageBannerModel.activeBanner,
            ...data[0],
          }));
          if (data[0].file) {
            activeBanner.img = `/${data[0].file.path}/${data[0].file.filename}`;
          }

          yield put({
            type: 'setState',
            payload: { activeBanner },
          });
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getLandingPage({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'landingPageBannerModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ landingPageBannerModel }) => ({
          refineParams: landingPageBannerModel.refineParams,
        }));

        let params = { ...(payload || refineParams) };
        if (params && params.active) {
          params.active = params.active.length === 1 ? params.active[0] : undefined;
        }
        const data = yield call(getLandingPage, params);

        yield put({
          type: 'setState',
          payload: {
            landingPageBanners: data,
            currentBanner: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'landingPageBannerModel',
            loading: false,
          },
        });
      }
    },
    *postLandingPage({ payload, callback }, { call, put }) {
      try {
        yield call(postLandingPage, payload);

        yield put({ type: 'getLandingPage' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchLandingPage({ payload, callback }, { call, put }) {
      try {
        yield call(patchLandingPage, payload);

        yield put({ type: 'getLandingPage' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteLandingPage(_, { call, select, put }) {
      try {
        const { currentBanner } = yield select(({ landingPageBannerModel }) => ({
          currentBanner: landingPageBannerModel.currentBanner,
        }));

        yield call(deleteLandingPage, currentBanner);

        yield put({ type: 'getLandingPage' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentBannerChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentBanner: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
