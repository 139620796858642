import fetch from 'dva/fetch';

function parseJSON(response) {
  const contentType = response.headers.get('content-type');

  if (!contentType || contentType.indexOf('application/json') === -1) {
    return response.text();
  }
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  return parseJSON(response).then((err) => {
    throw err;
  });
}

function formatUrl(path, options = {}) {
  let url = path;
  let urlSearchParams = new URLSearchParams();

  if (options.pathname) {
    if (typeof options.pathname === 'string') {
      url += `/${options.pathname}`;
    } else {
      Object.keys(options.pathname).forEach((key) => {
        url += `/${key}/${options.pathname[key]}`;
      });
    }
  }

  if (options.params && (!options.method || options.method === 'GET')) {
    for (let key in options.params) {
      if (Array.isArray(options.params[key])) {
        options.params[key].forEach((item) => {
          urlSearchParams.append(key, item);
        });
      } else if (
        options.params[key] !== undefined &&
        options.params[key] !== null &&
        options.params[key] !== ''
      ) {
        urlSearchParams.set(key, options.params[key]);
      }
    }
  }

  return urlSearchParams.toString() ? `${url}?${urlSearchParams.toString()}` : url;
}

/**
 * Requests a URL, and returns a promise.
 *
 * @param  {string} url       The url to request
 * @param  {object} [options] The options passed to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(path, options) {
  return fetch(formatUrl(path, options), options)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
}
