import React from 'react';
import { connect } from 'dva';
import { Select, Input } from 'antd';

class UserSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameList: [],
    };

    if (this.isEditable()) {
      props
        .dispatch({ type: `userModel/${props.request || 'getUsersAdminUsernameList'}` })
        .then((usernameList) => this.setState({ usernameList }));
    }
  }

  isEditable = () => {
    const {
      editable,
      userModel: { adminRole },
    } = this.props;

    return editable === undefined ? adminRole : editable;
  };

  render() {
    const {
      editable,
      userModel: { profile },
      dispatch,
      value,
      ...others
    } = this.props;
    const { usernameList } = this.state;

    return this.isEditable() ? (
      <Select
        showSearch
        options={usernameList.map((item) => ({ label: item, value: item }))}
        value={value}
        {...others}
      />
    ) : (
      <Input
        readOnly
        value={value || profile.username}
        {...others}
      />
    );
  }
}

export default connect(({ userModel }) => ({
  userModel,
}))(UserSelect);
