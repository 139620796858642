import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Modal, Tag } from 'antd';
import debounce from 'lodash/debounce';
import { DATE_TIME_FORMAT } from '@/constants';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import UserDetails from '@/pages/admin-user-account/user-details';

const columns = [
  {
    key: 'active',
    dataIndex: 'active',
    render: (value) =>
      value ? (
        <Tag
          color="green"
          style={{ margin: 0 }}
        >
          Active
        </Tag>
      ) : null,
  },
  {
    title: 'Name',
    key: 'name',
    render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Company',
    dataIndex: 'parentAccountName',
    key: 'parentAccountName',
  },
  {
    title: 'Contact Number',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Eula Version',
    dataIndex: 'eulaVersion',
    key: 'eulaVersion',
  },
  {
    title: 'Accepted Date',
    dataIndex: 'eulaAcceptedDate',
    key: 'eulaAcceptedDate',
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
];

export const ROW_KEY = '_id';

class AdminActiveSession extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'userModel/getUsersActive' });

    this.state = {
      showModal: false,
    };
  }

  showUserDetails = () => {
    this.setState({ showModal: true });
  };

  hideUserDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      userModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'userModel/getUsersActive',
              payload: values,
            });
            this.props.dispatch({
              type: 'userModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="First Name"
            name="firstName"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email Domain"
            name="emailDomain"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="parentAccountName"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company ID"
            name="parentAccountNumber"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contact Number"
            name="phone"
          >
            <Input />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      userModel: { currentUser },
    } = this.props;

    return currentUser[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove user.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'userModel/deleteUsers',
                  });
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={this.showUserDetails}
          >
            Edit
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentUserChange = (record = {}) => {
    const {
      userModel: { currentUser },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentUser[ROW_KEY] ? {} : record;
    dispatch({ type: 'userModel/onCurrentUserChange', payload });
  };

  render() {
    const {
      userModel: { loading, users, currentUser },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Roles"
          color="#359e35"
          title="Active Sessions"
          subtitle="Manage active users in the CEC"
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={users}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentUser[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentUserChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentUserChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <UserDetails
            onCancel={this.hideUserDetails}
            dispatch={dispatch}
            user={currentUser}
          />
        )}
      </div>
    );
  }
}

export default connect(({ userModel }) => ({
  userModel,
}))(AdminActiveSession);
