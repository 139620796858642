import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Modal } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import FileDetails from './file-details';

const columns = [
  {
    title: 'Filename',
    dataIndex: 'fileName',
    key: 'fileName',
  },
];

export const ROW_KEY = 'fileName';

class AdminSwaggerFile extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'swaggerFileModel/getFiles',
    });

    this.state = {
      showModal: false,
    };
  }

  showFileDetails = () => {
    this.setState({ showModal: true });
  };

  hideFileDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      swaggerFileModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'swaggerFileModel/setState',
              payload: { refineParams: values },
            });
          }, 500)}
        >
          <Form.Item
            name="fileName"
            label="Filename"
          >
            <Input />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      swaggerFileModel: { currentFile },
    } = this.props;

    return currentFile[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove the file.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'swaggerFileModel/deleteFiles',
                  });
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={this.showFileDetails}
          >
            Details
            <AppIcon name="open-tabs" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentFileChange = (record = {}) => {
    const {
      swaggerFileModel: { currentFile },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentFile[ROW_KEY] ? {} : record;
    dispatch({ type: 'swaggerFileModel/onCurrentFileChange', payload });
  };

  render() {
    const {
      swaggerFileModel: { loading, currentFile, refineParams },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    let files = this.props.swaggerFileModel.files.filter((item) => {
      for (let param in refineParams) {
        if (item[param].indexOf(refineParams[param]) === -1) {
          return false;
        }
      }
      return true;
    });

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#8d6ccf"
          title="Swagger Files Management"
          subtitle="Manage the content that appears in the CEC"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => {
                this.onCurrentFileChange();
                this.showFileDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={files}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentFile[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentFileChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentFileChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <FileDetails
            onCancel={this.hideFileDetails}
            dispatch={dispatch}
            file={currentFile}
          />
        )}
      </div>
    );
  }
}

export default connect(({ swaggerFileModel }) => ({
  swaggerFileModel,
}))(AdminSwaggerFile);
