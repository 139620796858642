import React from 'react';
import { Modal, Form, Input, DatePicker, Button } from 'antd';
import moment from 'moment';
import UserSelect from '@/components/user-select';
import LabConfigsStudents from '@/components/classroom-edit/labconfigs-students';
import { TIME_FORMAT, DATE_TIME_FORMAT } from '@/constants';

export default class NewClassroom extends React.Component {
  state = {
    values: {
      labConfigs: [],
      students: [],
    },
  };

  onValuesChange = (newValues, value) => {
    let values = { ...this.state.values, ...newValues };

    if (value && value.hasOwnProperty('timeRange')) {
      values.labConfigs = [];
    }

    this.setState({ values });
  };

  validate = () => {
    const { values } = this.state;
    const { adminRole } = this.props;

    return (
      !values.title ||
      !values.timeRange ||
      (adminRole && !values.instructor) ||
      !values.labConfigs ||
      !values.labConfigs.length > 0 ||
      values.labConfigs.some(({ available }) => available < 0) ||
      !values.students ||
      !values.students.length > 0
    );
  };

  render() {
    const { onCancel, dispatch, adminRole, profile } = this.props;
    const { values } = this.state;

    return (
      <Modal
        visible
        width={1000}
        title="New Classroom"
        closable={false}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={this.validate()}
              onClick={() => {
                dispatch({
                  type: 'classroomModel/postClassrooms',
                  payload: values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
          onValuesChange={(value, values) => this.onValuesChange(values, value)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Schedule"
            name="timeRange"
          >
            <DatePicker.RangePicker
              inputReadOnly
              placeholder={['Start Time', 'End Time']}
              showToday={false}
              showSecond={false}
              showNow={false}
              minuteStep={15}
              format={DATE_TIME_FORMAT}
              showTime={{ format: TIME_FORMAT }}
              disabledTime={(current) => {
                const now = moment();
                if (now.diff(current) < 0) return;

                const range = (start, end) => {
                  const result = [];
                  for (let i = start; i < end; i++) {
                    result.push(i);
                  }
                  return result;
                };

                return {
                  disabledHours: () => range(0, now.hour()),
                  disabledMinutes: () => range(0, now.minute()),
                };
              }}
              disabledDate={(current) => current && current < moment().startOf('day')}
            />
          </Form.Item>
          {adminRole && (
            <Form.Item
              label="Instructor"
              name="instructor"
            >
              <UserSelect request="getUsersInstructorList" />
            </Form.Item>
          )}
          <LabConfigsStudents
            startTime={values.timeRange && values.timeRange[0].format()}
            endTime={values.timeRange && values.timeRange[1].format()}
            labConfigs={values.labConfigs}
            students={values.students}
            instructor={adminRole ? values.instructor : profile.email}
            onValuesChange={(value) => this.onValuesChange(value)}
          />
        </Form>
      </Modal>
    );
  }
}
