import React from 'react';
import moment from 'moment';
import { Modal, Form, Input, Button, Descriptions, Upload } from 'antd';
import { DATE_TIME_FORMAT } from '@/constants';

class ReportIssueDetail extends React.Component {
  formRef = React.createRef();

  render() {
    const { reportIssue, onCancel, dispatch } = this.props;

    return (
      <Modal
        visible
        width={600}
        title="View Issue"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.formRef.current && this.formRef.current.submit();
              }}
            >
              Send Response
            </Button>
          </>
        }
      >
        <Descriptions labelStyle={{ width: 120 }}>
          <Descriptions.Item
            span={3}
            label="Type"
          >
            {reportIssue.type}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Severity"
          >
            {reportIssue.severity}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Reproducible"
          >
            {'' + reportIssue.reproducible}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Description"
          >
            {reportIssue.problem}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Suggestion"
          >
            {reportIssue.suggestedFix}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="User"
          >
            {reportIssue.username}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Issue Number"
          >
            {reportIssue.issueNumber}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Created At"
          >
            {moment(reportIssue.createdAt).format(DATE_TIME_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Updated At"
          >
            {moment(reportIssue.updatedAt).format(DATE_TIME_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Responder"
          >
            {reportIssue.responder}
          </Descriptions.Item>
        </Descriptions>
        <Form
          labelAlign="left"
          labelCol={{ span: 5 }}
          ref={this.formRef}
          onFinish={(values) => {
            values.id = reportIssue._id;

            if (values.files && values.files.length > 0) {
              values.files = values.files.map((item) => item.originFileObj);
            }

            dispatch({
              type: 'reportIssueModel/patchUsersReportIssuesAdminSendResponse',
              payload: values,
              callback: onCancel,
            });
          }}
        >
          <Form.Item
            label="Response"
            name="responseText"
            initialValue={reportIssue.responseText}
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="files"
            label="Attachments"
            tooltip=".txt, .text, .xml, .json, .jpg, .jpeg, .png, .gif"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              multiple
              customRequest={({ onSuccess }) => onSuccess()}
              accept='.txt, .text, .xml, .json, .jpg, .jpeg, .png, .gif'
            >
              <Button>Choose File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ReportIssueDetail;
