import React from 'react';
import { connect } from 'dva';
import { Layout, Button, Collapse, Menu, Form } from 'antd';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import AppIcon from '@/components/app-icon';
import NewEula from './new-eula';

import './index.less';

class AdminEula extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'eulaModel/getEulasLatest',
    });
    props.dispatch({
      type: 'eulaModel/getEulas',
    });

    this.state = {
      showModal: false,
    };
  }

  showNewEula = () => {
    this.setState({ showModal: true });
  };

  hideNewEula = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      eulaModel: { latestEula, eulas },
      dispatch,
    } = this.props;

    return (
      <SidePanel>
        <Button
          type="link"
          onClick={() => {
            dispatch({
              type: 'eulaModel/onEulaSelect',
              payload: latestEula,
            });
          }}
        >
          Current EULA
        </Button>
        <Collapse
          ghost
          bordered={false}
        >
          <Collapse.Panel header="All EULA's">
            <Menu
              items={eulas.map((item) => ({
                label: `Version ${item.version}`,
                onClick: () => {
                  dispatch({
                    type: 'eulaModel/onEulaSelect',
                    payload: item,
                  });
                },
              }))}
            />
          </Collapse.Panel>
        </Collapse>
      </SidePanel>
    );
  };

  render() {
    const {
      eulaModel: { eula, latestEula },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className="admin-eula-container">
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#8d6ccf"
          title="EULA Management"
          subtitle="Manage the End User Legal Agreement presented before a user is permitted to login."
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: this.showNewEula,
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content>
            <div className="title">Version {eula.version}</div>
            <Form
              labelAlign="left"
              labelCol={{ span: 3 }}
            >
              <Form.Item label="Updated by">{eula.author}</Form.Item>
              <Form.Item label="Updated Date">{eula.updatedAt}</Form.Item>
              <Form.Item>
                <div dangerouslySetInnerHTML={{ __html: eula.body }} />
              </Form.Item>
              <Form.Item
                className="ant-form-vertical"
                label="Attachment"
              >
                {eula.file ? (
                  <a
                    download
                    href={`/${eula.file.path}/${eula.file.filename}`}
                  >
                    {eula.file.originalName}
                  </a>
                ) : (
                  <p className="highlight-info">No attachment</p>
                )}
              </Form.Item>
            </Form>
          </Layout.Content>
        </Layout>
        {showModal && (
          <NewEula
            onCancel={this.hideNewEula}
            dispatch={dispatch}
            eula={latestEula}
          />
        )}
      </div>
    );
  }
}

export default connect(({ eulaModel }) => ({
  eulaModel,
}))(AdminEula);
