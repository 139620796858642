import React from 'react';
import { Table } from 'antd';

export const ROW_KEY = '_id';

export default class DashboardActionStatuses extends React.Component {
  columns = [
    {
      title: 'Owner',
      dataIndex: 'actionOwner',
      key: 'actionOwner',
    },
    {
      title: 'Label',
      dataIndex: 'actionLabel',
      key: 'actionLabel',
    },
    {
      title: 'Target',
      dataIndex: 'actionTarget',
      key: 'actionTarget',
    },
    {
      title: 'Detail',
      dataIndex: 'actionDetail',
      key: 'actionDetail',
    },
    {
      title: 'Status',
      className: 'action-status-column',
      dataIndex: 'actionStatus',
      key: 'actionStatus',
      render: (value) => <span className={value.replaceAll(' ', '')}>{value}</span>,
    },
    {
      title: 'Initiator',
      dataIndex: 'initiator',
      key: 'initiator',
    },
  ];

  onCurrentStudentChange = (record = {}) => {
    const { currentStudent, dispatch } = this.props;

    const payload = record[ROW_KEY] === currentStudent[ROW_KEY] ? {} : record;
    dispatch({ type: 'dashboardModel/onCurrentStudentChange', payload });
  };

  render() {
    const { actionStatuses } = this.props;

    return (
      <Table
        size="small"
        scroll={{ y: 200 }}
        rowKey={ROW_KEY}
        pagination={false}
        dataSource={actionStatuses}
        columns={this.columns}
      />
    );
  }
}
