import {
  getAnalyticsEventsMatrix,
  getAnalyticsReportTypes,
  getAnalyticsVisitReport,
  getAnalyticsLabsBooked,
  getAnalyticsAdminLabsBooked,
  getAnalyticsAdminLabsBookedCounts,
  getAnalyticsAdminViewDocumentsCounts,
  getAnalyticsAdminWatchedVideosCounts,
  getAnalyticsAdminDocumentationVideoLinkCounts,
  getAnalyticsAdminWatchedDocumentationVideoCounts,
  getAnalyticsEnrolledUsers,
  getAnalyticsAdminEnrolledUsers,
  getAnalyticsAdminAnalyticsPortalUrl,
  patchAnalyticsNotificationEventOpenLab,
  patchAnalyticsNotificationEventWatchVideo,
  patchAnalyticsNotificationEventReadDoc,
  getAnalyticsApiTrackerFieldLists,
  getAnalyticsApiTrackerArchiveCounts,
  getAnalyticsApiTrackerCounts,
} from '@/services';

const model = {
  namespace: 'analyticsModel',
  state: {
    eventsMatrix: {},
    reportTypes:[],
    fieldLists: {},
    visitsReport: {},
    labsBookedReport: [],
    labsBookedCountsReport: [],
    viewDocumentsCountsReport: [],
    watchedVideosCountsReport: [],
    documentationVideoLinkCountsReport: [],
    watchedDocumentationVideoCountsReport: [],
    apiTrackerArchiveCountsReport: [],
    apiTrackerCountsReport: [],
    enrolledUsersReport: [],
    refineParams: {},
  },
  subscriptions: {},
  effects: {
    *getAnalyticsReportTypes({ payload }, { call, put }) {
      try {
        const data = yield call(getAnalyticsReportTypes, payload);

        yield put({
          type: 'setState',
          payload: {
            reportTypes: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getAnalyticsEventsMatrix({ payload }, { call, put }) {
      try {
        const data = yield call(getAnalyticsEventsMatrix, payload);

        yield put({
          type: 'setState',
          payload: {
            eventsMatrix: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getAnalyticsLabsBooked({ payload }, { call, select, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const { analyticsAdminRole } = yield select(({ userModel }) => ({
          analyticsAdminRole: userModel.analyticsAdminRole,
        }));

        const data = yield call(
          analyticsAdminRole ? getAnalyticsAdminLabsBooked : getAnalyticsLabsBooked,
          payload
        );

        yield put({
          type: 'setState',
          payload: {
            labsBookedReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsLabsBookedCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsAdminLabsBookedCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            labsBookedCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsVisits({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsVisitReport, payload);

        yield put({
          type: 'setState',
          payload: {
            visitsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsViewDocumentsCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsAdminViewDocumentsCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            viewDocumentsCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsWatchedVideosCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsAdminWatchedVideosCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            watchedVideosCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsDocumentationVideoLinkCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsAdminDocumentationVideoLinkCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            documentationVideoLinkCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsWatchedDocumentationVideoCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsAdminWatchedDocumentationVideoCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            watchedDocumentationVideoCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsEnrolledUsers({ payload }, { call, select, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const { analyticsAdminRole } = yield select(({ userModel }) => ({
          analyticsAdminRole: userModel.analyticsAdminRole,
        }));

        const data = yield call(
          analyticsAdminRole ? getAnalyticsAdminEnrolledUsers : getAnalyticsEnrolledUsers,
          payload
        );

        yield put({
          type: 'setState',
          payload: {
            enrolledUsersReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsAdminAnalyticsPortalUrl(_, { call, put }) {
      try {
        const data = yield call(getAnalyticsAdminAnalyticsPortalUrl);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return '';
      }
    },
    *patchAnalyticsNotificationEventOpenLab({ payload }, { call, put }) {
      try {
        yield call(patchAnalyticsNotificationEventOpenLab, payload);
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchAnalyticsNotificationEventWatchVideo({ payload }, { call, put }) {
      try {
        yield call(patchAnalyticsNotificationEventWatchVideo, payload);
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchAnalyticsNotificationEventReadDoc({ payload }, { call, put }) {
      try {
        yield call(patchAnalyticsNotificationEventReadDoc, payload);
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getAnalyticsApiTrackerFieldLists(_, { call, put }) {
      try {
        const data = yield call(getAnalyticsApiTrackerFieldLists);

        yield put({
          type: 'setState',
          payload: {
            fieldLists: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getAnalyticsApiTrackerArchiveCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsApiTrackerArchiveCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            apiTrackerArchiveCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
    *getAnalyticsApiTrackerCounts({ payload }, { call, put }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: true,
          },
        });

        const data = yield call(getAnalyticsApiTrackerCounts, payload);

        yield put({
          type: 'setState',
          payload: {
            apiTrackerCountsReport: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'analyticsModel',
            loading: false,
          },
        });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
