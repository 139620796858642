import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Select, Table, Button, Modal } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import DomainDetails from './domain-details';

const columns = [
  {
    title: 'Domain Name',
    dataIndex: 'domainName',
    key: 'domainName',
  },
  {
    title: 'Restricted',
    dataIndex: 'restricted',
    key: 'restricted',
    render: (value) => (!!value).toString(),
  },
  {
    title: 'User Type',
    dataIndex: 'labDomainUserType',
    key: 'labDomainUserType',
  },
];

export const ROW_KEY = '_id';

class AdminDomain extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'labDomainModel/getLabsNetworkconfigTypesAdmin' });
    props.dispatch({ type: 'labDomainModel/getLabDomains' });

    this.state = {
      showModal: false,
    };
  }

  showDomainDetails = () => {
    this.setState({ showModal: true });
  };

  hideDomainDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      labDomainModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'labDomainModel/getLabDomains',
              payload: values,
            });
            this.props.dispatch({
              type: 'labDomainModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Domain Names"
            name="domainNames"
          >
            <Select mode="tags" />
          </Form.Item>
          <Form.Item
            label="Domain Name"
            name="domainName"
          >
            <Input />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      labDomainModel: { currentLabDomain },
    } = this.props;

    return currentLabDomain[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          {currentLabDomain.domainName !== 'excludelabdomain' &&
            currentLabDomain.domainName !== 'defaultlabdomain' && (
              <Button
                type="text"
                onClick={() => {
                  Modal.confirm({
                    title: 'Warning',
                    content: 'This will remove the lab domain.',
                    cancelButtonProps: { type: 'text' },
                    onOk: () => {
                      this.props.dispatch({
                        type: 'labDomainModel/deleteLabDomains',
                      });
                    },
                  });
                }}
              >
                Delete
              </Button>
            )}
          <Button
            type="primary"
            onClick={this.showDomainDetails}
          >
            {currentLabDomain.domainName === 'defaultlabdomain' ? 'Details' : 'Edit'}
            {currentLabDomain.domainName === 'defaultlabdomain' && <AppIcon name="open-tabs" />}
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentLabDomainChange = (record = {}) => {
    const {
      labDomainModel: { currentLabDomain },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentLabDomain[ROW_KEY] ? {} : record;
    dispatch({ type: 'labDomainModel/onCurrentLabDomainChange', payload });
  };

  render() {
    const {
      labDomainModel: { loading, labNetworkConfigTypes, labDomains, currentLabDomain },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#359e35"
          title="Domain Management"
          subtitle="Manage the domain that appears in the CEC"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => {
                this.onCurrentLabDomainChange();
                this.showDomainDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={labDomains}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentLabDomain[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentLabDomainChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentLabDomainChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <DomainDetails
            onCancel={this.hideDomainDetails}
            dispatch={dispatch}
            labNetworkConfigTypes={labNetworkConfigTypes}
            domain={currentLabDomain}
          />
        )}
      </div>
    );
  }
}

export default connect(({ labDomainModel }) => ({
  labDomainModel,
}))(AdminDomain);
