import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { Spin, Layout, Collapse, Table, Tag } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { getDuration } from '@/utils/duration';
import { DATE_TIME_FORMAT } from '@/constants';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import VideoDetails from './video-details';

class DocVideo extends React.Component {
  state = {
    mediaList: undefined,
    categoryActiveKey: {},
    selectedKey: '',
  };

  getMediaDocumentationMediaList = () => {
    const { match, dispatch } = this.props;
    const referenceId = match.params.referenceId;

    dispatch({
      type: 'docVideoModel/getMediaDocumentationMediaList',
      payload: { referenceId },
    }).then((data) => {
      let categoryActiveKey = {};

      const getActiveKey = (obj, level) => {
        if (obj.subCategories && obj.subCategories.length > 0) {
          categoryActiveKey[`level${level}`] = [];
          obj.subCategories.forEach((subCategory) => {
            categoryActiveKey[`level${level}`].push(subCategory.id);
            getActiveKey(subCategory, level + 1);
          });
        }
      };
      getActiveKey({ subCategories: data }, 0);

      this.setState({ mediaList: data, categoryActiveKey });
    });
  };

  componentDidMount() {
    this.getMediaDocumentationMediaList();

    // Wait until platform info ready
    /* Matomo */
    setTimeout(() => {
      const { mediaList } = this.state;
      const { dispatch } = this.props;

      const callback = () => {
        let customDimensionsObj = {};
        customDimensionsObj[
          `dimension${window._platformInfo.customDimensions.DocumentationVideoLink}`
        ] = mediaList.length > 0 ? mediaList[0].documentationTag : 'null';
        _paq.push(['trackPageView', document.title, customDimensionsObj]);
      };

      window._platformInfo
        ? callback()
        : dispatch({ type: 'appModel/setMatomoTracker' }).then(callback);
    }, 2000);
  }

  showModal = (selectedKey) => {
    this.setState({ selectedKey });
  };

  hideModal = (event) => {
    event.stopPropagation();

    this.setState({ selectedKey: '' });
  };

  renderCategories = (obj, level) => {
    const { categoryActiveKey, selectedKey } = this.state;

    return (
      <>
        {obj.mediaEntries && obj.mediaEntries.length > 0 && (
          <Table
            bordered
            size="small"
            rowKey="id"
            pagination={false}
            dataSource={obj.mediaEntries}
            onRow={(record) => ({
              onClick: () => this.showModal(record.id),
            })}
            columns={[
              {
                title: 'Thumbnail',
                dataIndex: 'thumbnailUrl',
                key: 'thumbnailUrl',
                width: 100,
                render: (value) => (
                  <img
                    src={value}
                    alt="Thumbnail"
                    style={{ width: '100%' }}
                  />
                ),
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 300,
              },
              {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                render: (value) => (
                  <span
                    className="video-description"
                    title={value}
                  >
                    {value}
                  </span>
                ),
              },
              {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 150,
                render: (value) => moment(value * 1000).format(DATE_TIME_FORMAT),
              },
              {
                title: 'Duration',
                dataIndex: 'msDuration',
                key: 'msDuration',
                width: 80,
                render: (value) => getDuration(value),
              },
              {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                width: 200,
                render: (value) => (value ? value.map((item) => <Tag key={item}>{item}</Tag>) : ''),
              },
              {
                dataIndex: 'id',
                key: 'actions',
                align: 'center',
                width: 40,
                render: (value, record) => (
                  <>
                    <AppIcon
                      className="video-actions"
                      name="more"
                    />
                    {value === selectedKey && (
                      <VideoDetails
                        onCancel={this.hideModal}
                        category={obj.fullName}
                        video={record}
                        documentationTag={obj.documentationTag}
                      />
                    )}
                  </>
                ),
              },
            ]}
          />
        )}
        {obj.subCategories && obj.subCategories.length > 0 && (
          <Collapse
            ghost
            bordered={false}
            className="folder-collapse"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
                {isActive ? <FolderOpenOutlined /> : <FolderOutlined />}
              </span>
            )}
            activeKey={categoryActiveKey[`level${level}`]}
            onChange={(activeKey) => {
              this.setState({
                categoryActiveKey: {
                  ...categoryActiveKey,
                  [`level${level}`]: activeKey,
                },
              });
            }}
          >
            {obj.subCategories.map((subCategory) => (
              <Collapse.Panel
                key={subCategory.id}
                header={subCategory.name}
              >
                {this.renderCategories(subCategory, level + 1)}
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      </>
    );
  };

  render() {
    const { mediaList } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Problem_analysis"
          color="#0271bc"
          title="Documentation Videos"
          subtitle="Video series created by experts to help educate users on common workflows"
        />
        {mediaList === undefined ? (
          <Spin
            tip="Loading"
            size="large"
          >
            <Layout />
          </Spin>
        ) : (
          <Layout>
            <Layout.Content className="video-container">
              {mediaList && mediaList.length > 0 ? (
                this.renderCategories({ subCategories: mediaList }, 0)
              ) : (
                <span>No Content</span>
              )}
            </Layout.Content>
          </Layout>
        )}
      </div>
    );
  }
}

export default connect()(DocVideo);
