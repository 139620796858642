import React from 'react';
import { connect } from 'dva';
import { Row, Col } from 'antd';

import './index.less';

class Poster extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'landingPageBannerModel/getLandingPageActive',
    });

    props.dispatch({
      type: 'portalEventModel/getPortalEventsActive',
    });
  }

  render() {
    const {
      portalEventModel: { activeEvents },
      landingPageBannerModel: { activeBanner },
    } = this.props;

    return (
      <Row className="poster">
        <Col flex="47%">
          <div className="poster-left">
            <div className="poster-table">
              <h1 className="poster-title">{activeBanner.title}</h1>
              <p
                className="poster-body"
                dangerouslySetInnerHTML={{ __html: activeBanner.body }}
              ></p>
            </div>
          </div>
        </Col>
        <Col flex="53%">
          <div
            className="poster-right"
            style={{ backgroundImage: `url(${activeBanner.img})` }}
          />
        </Col>
        {activeEvents.length > 0 && (
          <div className="events-container">
            <div className="container">
              {activeEvents.map((event, i) => (
                <div
                  className="event-item"
                  key={`event-${i}`}
                >
                  <div className="event-title">{event.title}</div>
                  <div
                    className="event-body"
                    dangerouslySetInnerHTML={{ __html: event.body }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Row>
    );
  }
}

export default connect(({ portalEventModel, landingPageBannerModel }) => ({
  portalEventModel,
  landingPageBannerModel,
}))(Poster);
