import { getDomainBlacklist, postDomainBlacklist, deleteDomainBlacklist } from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'blacklistModel',
  state: {
    blacklists: [],
    currentBlacklist: {},
  },
  subscriptions: {},
  effects: {
    *getDomainBlacklist({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'blacklistModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ blacklistModel }) => ({
          refineParams: blacklistModel.refineParams,
        }));

        const data = yield call(getDomainBlacklist, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            blacklists: data,
            currentBlacklist: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'blacklistModel',
            loading: false,
          },
        });
      }
    },
    *postDomainBlacklist({ payload, callback }, { call, put }) {
      try {
        yield call(postDomainBlacklist, payload);

        yield put({ type: 'getDomainBlacklist' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteDomainBlacklist(_, { call, select, put }) {
      try {
        const { currentBlacklist } = yield select(({ blacklistModel }) => ({
          currentBlacklist: blacklistModel.currentBlacklist,
        }));

        yield call(deleteDomainBlacklist, currentBlacklist);

        yield put({ type: 'getDomainBlacklist' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentBlacklistChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentBlacklist: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
