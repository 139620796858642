import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Select, Checkbox, Collapse } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import NotifyBCC from './notify-bcc';
import ReportIssueDetails from './report-issue-details';

import './index.less';

const columns = [
  {
    title: 'Severity',
    dataIndex: 'severity',
    key: 'severity',
    className: 'severity-cell',
    width: 80,
    render: (value) => <span className={value}>{value}</span>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Reproducible',
    dataIndex: 'reproducible',
    key: 'reproducible',
    render: (value) => '' + value,
  },
  {
    title: 'Description',
    dataIndex: 'problem',
    key: 'problem',
  },
  {
    title: 'Suggestion',
    dataIndex: 'suggestedFix',
    key: 'suggestedFix',
  },
  {
    title: 'User',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Issue Number',
    dataIndex: 'issueNumber',
    key: 'issueNumber',
  },
  {
    title: 'Responder',
    dataIndex: 'responder',
    key: 'responder',
    align: 'center',
    render: (value) => value || <AppIcon name="status-fail" />,
  },
];

export const ROW_KEY = '_id';

class AdminReportIssue extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({
      type: 'reportIssueModel/getUsersReportIssuesAdminListIssues',
    });

    this.state = {
      modalType: '',
    };
  }

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  renderSider = () => {
    const {
      reportIssueModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            const { dispatch } = this.props;
            dispatch({
              type: 'reportIssueModel/getUsersReportIssuesAdminListIssues',
              payload: values,
            });
            dispatch({
              type: 'reportIssueModel/setState',
              payload: { refineParams: values },
            });
          }, 500)}
        >
          <Form.Item
            name="type"
            label="Type"
          >
            <Select
              allowClear
              options={[
                'Sample Code Error',
                'API Error',
                'Documentation',
                'Portal',
                'Query',
                'Other',
              ].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="severity"
            label="Severity"
          >
            <Select
              allowClear
              options={['Critical', 'Major', 'Minor'].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="username"
            label="User"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="responder"
            label="Responder"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="issueNumber"
            label="Issue Number"
          >
            <Input />
          </Form.Item>
          <Collapse
            ghost
            defaultActiveKey={['reproducible']}
          >
            <Collapse.Panel
              header="Reproducible"
              key="reproducible"
            >
              <Form.Item name="reproducible">
                <Checkbox.Group>
                  <Checkbox value={true}>true</Checkbox>
                  <Checkbox value={false}>false</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      reportIssueModel: { currentReportIssue },
    } = this.props;

    return currentReportIssue[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="primary"
            onClick={() => this.showModal('response')}
          >
            Details
            <AppIcon name="open-tabs" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentReportIssueChange = (record = {}) => {
    const {
      reportIssueModel: { currentReportIssue },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentReportIssue[ROW_KEY] ? {} : record;
    dispatch({ type: 'reportIssueModel/onCurrentReportIssueChange', payload });
  };

  render() {
    const {
      reportIssueModel: { loading, reportIssues, currentReportIssue },
      dispatch,
    } = this.props;
    const { modalType } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Network_elements_operations"
          color="#099"
          title="Report Issue Management"
          subtitle="Manage the reported issues that appears in the CEC"
          actions={[
            {
              label: 'Notify',
              icon: <AppIcon name="email" />,
              onClick: () => {
                this.showModal('notifyBCC');
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={reportIssues}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentReportIssue[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentReportIssueChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentReportIssueChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {modalType === 'notifyBCC' && (
          <NotifyBCC
            onCancel={this.hideModal}
            dispatch={dispatch}
          />
        )}
        {modalType === 'response' && (
          <ReportIssueDetails
            onCancel={this.hideModal}
            dispatch={dispatch}
            reportIssue={currentReportIssue}
          />
        )}
      </div>
    );
  }
}

export default connect(({ reportIssueModel }) => ({
  reportIssueModel,
}))(AdminReportIssue);
