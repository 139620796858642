import React from 'react';
import Cookie from 'js-cookie';
import { getSearchParam } from '@/utils';
import proxy from '@/constants/proxy';
import MCPWebsocket from './mcp-websocket';

import './index.less';

class LabCraft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualDevice: {},
    };

    this.getVirtualDeviceInfo();
  }

  getVirtualDeviceInfo = () => {
    const { dispatch } = this.props;
    const params = getSearchParam();

    dispatch({
      type: 'labModel/getLabsVirtualdevices',
      payload: { bookingId: params.bookingId, vd_id: params.vdId },
    }).then((data) => this.setState({ virtualDevice: data[0] }));
  };

  renderContent = () => {
    const { virtualDevice } = this.state;

    const type = getSearchParam('type');
    if (!type || !virtualDevice.type) return null;

    if (type === 'login') {
      return this.loginType(virtualDevice);
    }
    if (type === 'sitemanager') {
      return this.sitemanagerType(virtualDevice);
    }
    if (type === 'websocket') {
      return this.websocketType(virtualDevice);
    }
    if (type === 'webgui') {
      return this.webguiType(virtualDevice);
    }

    return null;
  };

  loginType = (virtualDevice) => {
    const vdType = proxy.mapping(virtualDevice.type);
    const ruleKey = `${vdType}_LOGIN`;
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.api_ip);
    const cookieName = ruleKey === 'PLANNER_PLUS_LOGIN' ? 'P2' : vdType;

    Cookie.set(`${cookieName}_o1`, o1);
    Cookie.set(`${cookieName}_o2`, o2);
    if (['MCP', 'MDSO', 'PLANNER_PLUS'].includes(vdType))
      Cookie.set('VD_TYPE', cookieName);

    const url = window.location.origin + proxy.deprecated_computePath(ruleKey, o1, o2, virtualDevice.bookingEndTime);

    return virtualDevice.type === 'MCP-AIPA' ? (
      window.location.replace(url)
    ) : (
      <iframe
        title="login"
        src={url}
      />
    );
  };

  sitemanagerType = (virtualDevice) => {
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.sitemanager_ip);

    Cookie.set('CRAFT_o1', o1);
    Cookie.set('CRAFT_o2', o2);

    return (
      <iframe
        title="Site Manager"
        src={
          window.location.origin +
          proxy.deprecated_computePath(
            'PINS_SITE_MGR',
            o1,
            o2,
            virtualDevice.bookingEndTime,
            '/path/'
          )
        }
      />
    );
  };

  websocketType = (virtualDevice) => {
    const vdType = proxy.mapping(virtualDevice.type);
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.api_ip);

    switch (vdType) {
      case 'PINS':
        Cookie.set('XSRF-TOKEN', virtualDevice.api_6500_x_xsrf_token);
        Cookie.set('IP_6500_o1', o1);
        Cookie.set('IP_6500_o2', o2);
        Cookie.set('VD_TYPE', '6500');
        document.cookie = virtualDevice.api_6500_cookie + ';path=/status';

        return (
          <iframe
            title="6500 Websocket"
            src={
              window.location.origin +
              proxy.deprecated_computePath(
                `${vdType}_EVENT_LISTENER`,
                o1,
                o2,
                virtualDevice.bookingEndTime,
                '/'
              )
            }
          />
        );
      case 'WS':
        return (
          <iframe
            title="Waveserver Websocket"
            src={`${window.location.origin + proxy.deprecated_rules.WS_WACSIM_NOTIF.path
              }/o1/${o1}/o2/${o2}/path/scheduleId/${virtualDevice._id}/vdId/${virtualDevice.vd_id
              }/wgPath/notification/recv.html`}
          />
        );
      case 'MCP':
        return <MCPWebsocket virtualDevice={virtualDevice} />;
      default:
        return null;
    }
  };

  webguiType = (virtualDevice) => {
    const ruleKey = `${proxy.mapping(virtualDevice.type)}_WEBGUI`;
    const { o1, o2 } = proxy.ipToOctets(virtualDevice.api_ip);

    return (
      <iframe
        title={ruleKey}
        src={
          window.location.origin +
          proxy.deprecated_computePath(
            ruleKey,
            o1,
            o2,
            virtualDevice.bookingEndTime,
            `/path/scheduleId/${virtualDevice._id}/vdId/${virtualDevice.vd_id}/wgPath${proxy.deprecated_rules[ruleKey].wgPath}`
          )
        }
      />
    );
  };

  render() {
    return this.renderContent();
  }
}

export default LabCraft;
