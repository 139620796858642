import React from 'react';
import ReactFileViewer from 'react-file-viewer';
import ReactJsonViewer from 'react-json-view';
import { getRequest } from '@/services';

const getFileExtension = (name) => name.split('.').pop().toLowerCase();

class FileViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      json: {},
    };

    if (getFileExtension(props.filePath) === 'json') {
      getRequest(props.filePath).then((json) => this.setState({ json }));
    }
  }

  render() {
    const { filePath } = this.props;
    const fileType = getFileExtension(filePath);

    return fileType === 'json' ? (
      <ReactJsonViewer
        name={false}
        displayDataTypes={false}
        collapsed={1}
        src={this.state.json}
      />
    ) : (
      <ReactFileViewer
        fileType={fileType}
        filePath={filePath}
      />
    );
  }
}

export default FileViewer;
