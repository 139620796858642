import React from 'react';
import { Modal, Form, Input, Button, Upload } from 'antd';

class DocDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...values,
      file:
        values.file && values.file.length > 0
          ? values.file.map((item) => item.originFileObj)
          : values.file,
    };

    this.setState({
      isSaveDisabled: !this.values.version || !(this.values.file && this.values.file.length > 0),
    });
  };

  render() {
    const { onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;

    return (
      <Modal
        visible
        width={600}
        title="New Navigator MC Documentation"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: 'contentModel/postMCPDocs',
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            name="version"
            label="Version"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="file"
            label="Upload File"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              maxCount={1}
              customRequest={({ onSuccess }) => onSuccess()}
            >
              <Button>Choose File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default DocDetails;
