import React from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Layout, Spin, Form, Input, Collapse } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import debounce from 'lodash/debounce';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import ContentCategory from '@/components/content-category';
import { getSearchParam } from '@/utils';
import ContentPanel from './content-panel';

import './index.less';

class DocLabContent extends React.Component {
  state = {
    subCategories: [],
  };

  refineFormRef = React.createRef();

  getSubCategories = () => {
    this.setState({ loading: true });

    this.props
      .dispatch({
        type: 'contentModel/getSubCategoriesByParams',
        payload: { category: getSearchParam('category') },
      })
      .then((data) => this.setState({ subCategories: data, loading: false }));
  };

  setRefineFormValues = () => {
    if (this.refineFormRef.current) {
      this.refineFormRef.current.resetFields();
      this.refineFormRef.current.setFieldsValue(getSearchParam());
    }
  };

  redirect = ({ category, sub_category, title }) => {
    let search = [];

    if (category) search.push(`category=${encodeURIComponent(category)}`);
    if (sub_category) search.push(`sub_category=${encodeURIComponent(sub_category)}`);
    if (title) search.push(`title=${encodeURIComponent(title)}`);

    this.props.dispatch(routerRedux.push({ search: search.join('&') }));
  };

  componentDidMount() {
    this.getSubCategories();
    this.setRefineFormValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      getSearchParam('category', this.props.location.search) !==
        getSearchParam('category', prevProps.location.search) && this.getSubCategories();
      this.setRefineFormValues();
    }
  }

  renderSider = () => {
    const {
      contentModel: { categories },
    } = this.props;
    const { subCategories } = this.state;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          ref={this.refineFormRef}
          onValuesChange={debounce((_, values) => this.redirect(values), 500)}
        >
          <ContentCategory
            categories={categories}
            subCategories={subCategories}
          />
          <Form.Item
            noStyle
            shouldUpdate
          >
            {({ getFieldValue }) => {
              const category = getFieldValue('category');
              const sub_category = getFieldValue('sub_category');

              if (!category || !sub_category) return null;

              return (
                <Form.Item
                  label="Title"
                  name="title"
                >
                  <Input />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  render() {
    const { dispatch } = this.props;
    const { loading, subCategories, activeContentKey } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_System_profiles"
          color="#0271bc"
          title="Documentation"
          subtitle="Documents relating to Help, FAQ, APIs and etc."
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="doc-container">
            <Spin
              style={{ height: 400 }}
              spinning={loading}
            >
              {subCategories.length > 0 ? (
                <Collapse
                  accordion
                  ghost
                  bordered={false}
                  className="folder-collapse"
                  expandIcon={({ isActive }) => (
                    <span>
                      {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
                      {isActive ? <FolderOpenOutlined /> : <FolderOutlined />}
                    </span>
                  )}
                  activeKey={getSearchParam('sub_category')}
                  onChange={(sub_category) => this.redirect({ ...getSearchParam(), sub_category })}
                >
                  {subCategories.map((subCategory) => (
                    <Collapse.Panel
                      key={subCategory.label}
                      header={`${subCategory.category} / ${subCategory.label}`}
                    >
                      <ContentPanel
                        dispatch={dispatch}
                        redirect={this.redirect}
                        activeKey={activeContentKey}
                        onChange={(activeContentKey) => this.setState({ activeContentKey })}
                        category={subCategory.category}
                        sub_category={subCategory.label}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              ) : (
                <span>No Content</span>
              )}
            </Spin>
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

export default connect(({ contentModel }) => ({
  contentModel,
}))(DocLabContent);
