import React from 'react';
import { Button } from 'antd';
import AppIcon from '@/components/app-icon';

import './index.less';

class InfoBar extends React.Component {
  render() {
    const { icon, color, title, subtitle, actions } = this.props;

    return (
      <div className="info-bar">
        <div className="info-bar-left">
          {icon && (
            <AppIcon
              svg="app-bar"
              name={icon}
              color={color}
            />
          )}
          <div>
            <div className="primary-title">{title}</div>
            {subtitle && <div className="sub-title">{subtitle}</div>}
          </div>
        </div>
        {actions && (
          <div className="info-bar-right">
            {actions.map(({ label, icon, ...others }) => (
              <Button
                key={label}
                type="text"
                {...others}
              >
                {icon}
                <div>{label}</div>
              </Button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default InfoBar;
