import moment from 'moment';
import { routerRedux } from 'dva/router';
import { message } from 'antd';
import { ROUTES } from '@/constants';
import {
  getLabsBookings,
  getLabsBookingsAdmin,
  patchLabsBookingsAudit,
  getLabsBookingsAudit,
  patchLabsBookingsCancel,
  patchLabsBookingsMaintenanceMode,
  deleteLabsBookingsScheduled,
  getUserLabDomains,
  getScheduleGetSchedule,
  putScheduleVerifyBooking,
  postScheduleScheduleLab,
  postScheduleScheduleLabAdmin,
  getLabsVirtualdevices,
  getLabsVirtualdevicesAdmin,
  getLabsActions,
  getLabsActionsAdmin,
  postLabsActionsRequest,
  getLabsActionsInventory,
  getLabsActionsInventoryAdmin,
  getLabsVirtualdevicesInteractions,
  getLabsVirtualdevicesInteractionsAdmin,
  postProxyComputePath,
} from '@/services';
import { addDuration, getDuration } from '@/utils/duration';

const model = {
  namespace: 'labModel',
  state: {
    labBookings: [],
    labDomainUserTypes: {},
    labConfigsSchedule: [],
    newLab: {},
    // UI states
    showNewLab: false,
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(() => {
        dispatch({
          type: 'setState',
          payload: {
            newLab: {},
            showNewLab: history.location.search.indexOf(ROUTES.LAB_SCHEDULE_NEW.split('?')[1]) > -1,
          },
        });
      });
    },
  },
  effects: {
    *getLabsBookings({ payload, isSSE }, { put, call, select }) {
      try {
        if (!isSSE) {
          yield put({
            type: 'appModel/setTableLoading',
            payload: {
              modelName: 'labModel',
              loading: true,
            },
          });
        }

        const { refineParams } = yield select(({ labModel }) => ({
          refineParams: labModel.refineParams,
        }));

        const data = yield call(getLabsBookings, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            labBookings: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'labModel',
            loading: false,
          },
        });
      }
    },
    *getLabsBookingsActive(_, { put, call }) {
      try {
        const data = yield call(getLabsBookings, { labStatus: 'Active', maxBookings: 'null' });

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return [];
      }
    },
    *getLabsBookingsByParams({ payload }, { put, select, call }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = yield call(adminRole ? getLabsBookingsAdmin : getLabsBookings, payload);

        return data[0] || {};
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
        return {};
      }
    },
    *patchLabsBookingsAudit({ payload }, { call, put }) {
      try {
        const data = yield call(patchLabsBookingsAudit, payload);

        return data || {};
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return {};
      }
    },
    *getLabsBookingsAudit({ payload }, { call, select, put }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = yield call(
          getLabsBookingsAudit,
          undefined,
          adminRole ? `bookingId/${payload.bookingId}/admin` : { bookingId: payload.bookingId }
        );

        return data[0] || {};
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return {};
      }
    },
    *patchLabsBookingsCancel({ payload }, { put, call }) {
      try {
        yield call(patchLabsBookingsCancel, { bookingId: payload.bookingId });

        message.success('Success');

        /* Matomo */
        yield put({
          type: 'trackLabEvent',
          payload: {
            action: 'Cancel',
            nameObj: {
              title: payload.title,
              bookingId: payload.bookingId,
              configs: payload.labConfigs,
            },
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchLabsBookingsMaintenanceMode({ payload }, { put, call }) {
      try {
        yield call(patchLabsBookingsMaintenanceMode, {
          bookingId: payload.bookingId,
          maintenanceMode: !payload.maintenanceMode,
        });

        /* Matomo */
        yield put({
          type: 'trackLabEvent',
          payload: {
            action: `Maintenance ${payload.maintenanceMode ? 'OFF' : 'ON'}`,
            nameObj: {
              title: payload.title,
              bookingId: payload.bookingId,
            },
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteLabsBookingsScheduled({ payload }, { put, call }) {
      try {
        yield call(deleteLabsBookingsScheduled, { bookingId: payload.bookingId });

        message.success('Success');

        /* Matomo */
        yield put({
          type: 'trackLabEvent',
          payload: {
            action: 'Delete',
            nameObj: {
              title: payload.title,
              bookingId: payload.bookingId,
              configs: payload.labConfigs,
            },
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getUserLabDomains({ payload }, { put, select, call }) {
      try {
        const { profile } = yield select(({ userModel }) => ({
          profile: userModel.profile,
        }));

        const data = yield call(getUserLabDomains, undefined, {
          user: payload || profile.username,
        });

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *onNewLabChange({ payload }, { put, select }) {
      try {
        const { newLab: labData } = yield select(({ labModel }) => ({
          newLab: labModel.newLab,
        }));
        let newLab = { ...labData, ...payload };

        if (newLab.startTime) {
          const startMoment = moment(newLab.startTime);
          newLab.startTime = startMoment.format();
        }

        newLab.endTime =
          newLab.startTime && newLab.duration
            ? addDuration(newLab.startTime, newLab.duration)
            : undefined;

        yield put({ type: 'setState', payload: { newLab } });

        const error = yield put.resolve({ type: 'putScheduleVerifyBooking' });
        if (error) {
          throw error;
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getScheduleGetSchedule(_, { call, put }) {
      try {
        const data = yield call(getScheduleGetSchedule);
        yield put({
          type: 'setState',
          payload: {
            labConfigsSchedule: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *putScheduleVerifyBooking({ payload }, { call, select }) {
      try {
        const { newLab } = yield select(({ labModel }) => ({
          newLab: labModel.newLab,
        }));

        const labConfigs =
          payload || (newLab.bookingRequestData && newLab.bookingRequestData.labConfigs);
        if (labConfigs && newLab.startTime && newLab.endTime) {
          yield call(putScheduleVerifyBooking, {
            labConfigs: labConfigs.filter((item) => item && item.type),
            maxScheduledHours: 10,
            scheduleIntervalHours: 24,
            startTime: newLab.startTime,
            endTime: newLab.endTime,
            bookingOwner: newLab.bookingOwner,
          });
        }
      } catch (e) {
        return e;
      }
    },
    *postScheduleLab(_, { put, select, call }) {
      try {
        const { newLab, profile, adminRole } = yield select(({ labModel, userModel }) => ({
          newLab: labModel.newLab,
          profile: userModel.profile,
          adminRole: userModel.adminRole,
        }));

        const error = yield put.resolve({ type: 'putScheduleVerifyBooking' });
        if (error) {
          throw error;
        }

        const data = yield call(
          adminRole ? postScheduleScheduleLabAdmin : postScheduleScheduleLab,
          {
            ...newLab,
            bookingDomain:
              profile.portalDomain === 'COMMUNITY' ? profile.username : newLab.bookingDomain,
            bookingRequestData: {
              ...newLab.bookingRequestData,
              labConfigs: newLab.bookingRequestData.labConfigs.map(
                ({ config, description, fileUpload, type, version }) => ({
                  config,
                  description,
                  fileUpload,
                  type,
                  version,
                })
              ),
            },
          }
        );

        message.success('Success');
        yield put(
          routerRedux.push({
            pathname: ROUTES.LAB_SCHEDULE,
          })
        );

        /* Matomo */
        let customDimensionsObj = {};
        data.bookingRequestData.labConfigs.forEach(({ type, config, version }, index) => {
          customDimensionsObj[
            `dimension${window._platformInfo.customDimensions.BookedLabConfiguration}`
          ] = JSON.stringify({ type, config, version });
          index !== data.bookingRequestData.labConfigs.length - 1 &&
            _paq.push(['trackPageView', document.title, customDimensionsObj]);
        });

        customDimensionsObj[`dimension${window._platformInfo.customDimensions.BookedLabDuration}`] =
          getDuration(newLab.startTime, newLab.endTime);
        customDimensionsObj[`dimension${window._platformInfo.customDimensions.BookedLabDomain}`] =
          data.bookingDomain;

        /* Matomo */
        yield put({
          type: 'trackLabEvent',
          payload: {
            action: newLab.bookAgain ? 'Book Again' : 'Book',
            nameObj: {
              title: data.title,
              bookingId: data.bookingId,
              configs: data.bookingRequestData.labConfigs,
            },
            customDimensionsObj,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postLabsActionsRequest({ payload, callback }, { put, call }) {
      try {
        const { data } = yield call(postLabsActionsRequest, payload);

        callback && callback();

        // TODO: need to find a better way to show success or fail
        if (data.fail) {
          message.error(data.fail);
        } else {
          message.success('Success');

          /* Matomo */
          yield put({
            type: 'trackLabEvent',
            payload: {
              action: 'SIM Action',
              nameObj: { vdId: payload.vd_id, actionRequest: payload.actionRequestParams },
            },
          });
        }
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getLabsVirtualdevices({ payload }, { put, select, call }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));
        const { bookingId, ...params } = payload;

        const data = adminRole
          ? yield call(getLabsVirtualdevicesAdmin, payload)
          : yield call(getLabsVirtualdevices, params, { bookingId });

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *getLabsActions({ payload }, { put, select, call }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = adminRole
          ? yield call(getLabsActionsAdmin, { bookingId: payload.bookingId })
          : yield call(getLabsActions, undefined, { bookingId: payload.bookingId });

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *getLabsActionsInventory({ payload }, { put, select, call }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = adminRole
          ? yield call(getLabsActionsInventoryAdmin, { bookingId: payload.bookingId })
          : yield call(getLabsActionsInventory, undefined, { bookingId: payload.bookingId });

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *getLabsInteractions({ payload }, { put, select, call }) {
      try {
        const { bookingId } = payload;

        if (!bookingId) {
          const e = {
            message: 'Invalid bookingId',
          };
          throw e;
        }

        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const data = adminRole
          ? yield call(getLabsVirtualdevicesInteractionsAdmin, { bookingId })
          : yield call(getLabsVirtualdevicesInteractions, undefined, { bookingId });

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *gotoLabInteraction({ payload }, { put }) {
      const pathname = ROUTES.LAB_INTERACTION + '/' + payload.bookingId;
      const search = payload.config ? '?config=' + payload.config : '';

      window.open(pathname + search);

      /* Matomo */
      if (!payload.isClassroom) {
        yield put({
          type: 'trackLabEvent',
          payload: {
            action: 'Interactions Page',
            nameObj: {
              title: payload.title,
              bookingId: payload.bookingId,
              configs: payload.labConfigs,
            },
          },
        });
        yield put({
          type: 'analyticsModel/patchAnalyticsNotificationEventOpenLab',
          payload: {
            visitorId: window._platformInfo.visitorId,
            title: payload.title,
            eventType: 'InteractionPage',
          },
        });
      }
    },
    *postProxyComputePath({ payload }, { put, call }) {
      try {
        const data = yield call(postProxyComputePath, payload);

        return data;
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return '';
      }
    },
    /* Matomo */
    *trackLabEvent({ payload }) {
      const { action, nameObj, customDimensionsObj } = payload;

      if (nameObj.configs) {
        nameObj.configs = nameObj.configs.map(({ type, config, version }) => ({
          type,
          config,
          version,
        }));
      }

      _paq.push([
        'trackEvent',
        'Lab',
        action,
        JSON.stringify(nameObj),
        undefined,
        customDimensionsObj,
      ]);

      yield;
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
