import React from 'react';
import { Modal, Form, Input, Select, Button, Checkbox, Table } from 'antd';
import { ROW_KEY } from '../';

const columns = [
  {
    title: 'Lab Name',
    dataIndex: 'config',
    key: 'config',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
  },
  Table.SELECTION_COLUMN,
];

class DomainDetails extends React.Component {
  formRef = React.createRef();

  state = {
    isSaveDisabled: true,
    selectedRowKeys: (this.props.domain.labConfigs || []).map((item) => item[ROW_KEY]),
  };

  onValuesChange = () => {
    this.values = {
      ...this.props.domain,
      ...this.formRef.current.getFieldsValue(true),
    };

    this.setState({
      isSaveDisabled: !this.values.domainName || !this.values.labDomainUserType,
    });
  };

  render() {
    const { labNetworkConfigTypes, domain, onCancel, dispatch } = this.props;
    const { isSaveDisabled, selectedRowKeys } = this.state;
    const isEdit = this.props.domain[ROW_KEY];

    return (
      <Modal
        visible
        width={800}
        title={`${isEdit ? 'Edit' : 'New'} Lab Domain`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: `labDomainModel/${isEdit ? 'patchLabDomains' : 'postLabDomains'}`,
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          ref={this.formRef}
          labelCol={{ span: 4 }}
          initialValues={domain}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Domain Name"
            name="domainName"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User Type"
            name="labDomainUserType"
          >
            <Select
              options={['USER', 'SHORTTERM', 'LONGTERM'].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Restricted"
            name="restricted"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item label="Lab Configs" />
          <Table
            bordered
            size="small"
            rowKey={ROW_KEY}
            dataSource={labNetworkConfigTypes}
            columns={columns}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });

                this.formRef.current.setFieldValue('labConfigs', selectedRows);
                this.onValuesChange();
              },
            }}
            pagination={false}
          />
        </Form>
      </Modal>
    );
  }
}

export default DomainDetails;
