import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Select, Input, Table, Button, Tooltip, Modal } from 'antd';
import debounce from 'lodash/debounce';
import copyToClipboard from '@/utils/copyToClipboard';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import ClassroomEdit from '@/components/classroom-edit';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_INSTRUCTORSTUDENTMANAGEMENT, EVENTS_ADMINSTUDENTMANAGEMENT } from '@/services';
import StudentDetails from './student-details';

export const ROW_KEY = 'username';

class InstructorStudent extends React.Component {
  state = {
    modalType: '',
    selectedKey: null,
  };

  columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Login Link',
      dataIndex: 'loginLink',
      key: 'loginLink',
      render: (value, record) => (
        <>
          <Button
            style={{ marginRight: 8 }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              const {
                studentModel: { currentClassroom },
                dispatch,
              } = this.props;
              dispatch({
                type: 'classroomModel/patchClassroomSendStudentEmails',
                payload: {
                  studentEmail: record.email,
                  classroomId: currentClassroom.classroomId,
                },
              });
            }}
          >
            Send
          </Button>
          <Tooltip title={value}>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(value);
              }}
            >
              Copy
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  renderSider = () => {
    const {
      studentModel: { classrooms, currentClassroom, refineParams },
      dispatch,
    } = this.props;

    return (
      <SidePanel type="filter">
        {currentClassroom && currentClassroom.classroomId ? (
          <Form
            layout="vertical"
            initialValues={refineParams}
            onValuesChange={debounce((_, values) => {
              this.setState({ selectedKey: null });
              dispatch({
                type: 'studentModel/setState',
                payload: { refineParams: values },
              });
            }, 500)}
          >
            <Form.Item label="Classroom">
              <Select
                showArrow
                fieldNames={{ label: 'title', value: 'classroomId' }}
                options={classrooms}
                value={currentClassroom.classroomId}
                onChange={(_, values) => {
                  this.setState({ selectedKey: null });
                  dispatch({
                    type: 'studentModel/setState',
                    payload: { currentClassroom: values },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="First Name"
              name="firstName"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Form>
        ) : (
          <span style={{ padding: '0 15px' }}>No scheduled or active classrooms</span>
        )}
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      studentModel: { currentClassroom },
      dispatch,
    } = this.props;
    const { selectedKey } = this.state;

    const selectedObj = (currentClassroom.students || []).find(
      (student) => student[ROW_KEY] === selectedKey
    );

    return currentClassroom.classroomId ? (
      <div className="table-footer">
        <div>{selectedObj && '1 selected'}</div>
        <div>
          {selectedObj && (
            <Button
              type="text"
              onClick={() => {
                Modal.confirm({
                  title: 'Warning',
                  content: 'This will remove student.',
                  cancelButtonProps: { type: 'text' },
                  onOk: () => {
                    dispatch({
                      type: 'studentModel/patchClassroomRemoveStudents',
                      payload: {
                        classroomId: currentClassroom.classroomId,
                        students: [selectedObj],
                      },
                    });
                  },
                });
              }}
            >
              Delete
            </Button>
          )}
          {selectedObj && (
            <Button
              type="primary"
              onClick={() => this.showModal('details')}
            >
              Details
              <AppIcon name="open-tabs" />
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              dispatch({
                type: 'classroomModel/patchClassroomSendStudentEmails',
                payload: {
                  classroomId: currentClassroom.classroomId,
                },
              });
            }}
          >
            Send Login Links
          </Button>
        </div>
      </div>
    ) : null;
  };

  onSelect = (selectedKey) => {
    this.setState({ selectedKey: selectedKey === this.state.selectedKey ? null : selectedKey });
  };

  render() {
    const {
      studentModel: { loading, currentClassroom, refineParams },
      dispatch,
    } = this.props;
    const { modalType, selectedKey } = this.state;

    const students = (currentClassroom.students || []).filter((item) => {
      for (const key in refineParams) {
        if (!refineParams[key]) continue;
        if (refineParams[key] !== item[key]) return false;
      }
      return true;
    });

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_User_accounts"
          color="#359e35"
          title="Student Management"
          subtitle="Add, edit and manage students"
          actions={
            currentClassroom && currentClassroom.classroomId
              ? [
                  {
                    label: 'New',
                    icon: <AppIcon name="add" />,
                    onClick: () => this.showModal('new'),
                  },
                ]
              : null
          }
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                pagination={false}
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={students}
                columns={this.columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [selectedKey],
                  onChange: (selectedKeys) => this.onSelect(selectedKeys[0]),
                  renderCell: (checked, record, index, originNode) => (
                    <>
                      {originNode}
                      <div onClick={(e) => e.stopPropagation()}>
                        {record[ROW_KEY] === selectedKey && modalType === 'details' && (
                          <StudentDetails
                            dispatch={dispatch}
                            onCancel={this.hideModal}
                            student={record}
                            classroom={currentClassroom}
                          />
                        )}
                      </div>
                    </>
                  ),
                }}
                onRow={(record) => ({
                  onClick: () => this.onSelect(record[ROW_KEY]),
                })}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {modalType === 'new' && (
          <ClassroomEdit
            labConfigDisabled
            classroom={currentClassroom}
            onCancel={this.hideModal}
            onEdit={({ students }) => {
              dispatch({
                type: 'studentModel/patchClassroomAddStudents',
                payload: { classroomId: currentClassroom.classroomId, students },
                callback: this.hideModal,
              });
            }}
          />
        )}
        <ServerSideEvent
          url={EVENTS_INSTRUCTORSTUDENTMANAGEMENT}
          adminUrl={EVENTS_ADMINSTUDENTMANAGEMENT}
          onmessage={() => dispatch({ type: 'studentModel/getClassrooms', isSSE: true })}
          onopen={() => dispatch({ type: 'studentModel/getClassrooms' })}
        />
      </div>
    );
  }
}

export default connect(({ studentModel }) => ({
  studentModel,
}))(InstructorStudent);
