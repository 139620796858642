import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import debounce from 'lodash/debounce';
import {
  Layout,
  Spin,
  Form,
  Input,
  DatePicker,
  Cascader,
  Select,
  Collapse,
  Table,
  Button,
  Tag,
} from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { getDuration } from '@/utils/duration';
import { DATE_TIME_FORMAT } from '@/constants';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import VideoDetails from './video-details';

import './index.less';

class Video extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'videoModel/getMediaMediaList' });
    props.dispatch({ type: 'videoModel/getMediaCategoryList' });
    props.dispatch({ type: 'videoModel/getMediaTagList' });
    props.dispatch({ type: 'videoModel/getMediaFixedFilters' });

    this.state = {
      selectedKey: '',
    };
  }

  showModal = (selectedKey) => {
    this.setState({ selectedKey });
  };

  hideModal = (event) => {
    event.stopPropagation();

    this.setState({ selectedKey: '' });
  };

  renderSider = () => {
    const {
      videoModel: { categoryList, tagList, fixedFilters, mediaList, refineParams },
      dispatch,
    } = this.props;

    let csvData = [];
    const header = ['Name', 'Description', 'Duration', 'Tag', 'Category'];
    csvData.push(header.join(','));
    const formatVideoExport = (list = []) => {
      list.forEach((obj) => {
        if (obj.mediaEntries) {
          obj.mediaEntries.forEach((mediaEntry) => {
            const content = [
              `"${mediaEntry.name}"`,
              `"${mediaEntry.description}"`,
              getDuration(mediaEntry.msDuration),
              mediaEntry.tags ? `"${mediaEntry.tags.join(', ')}"` : '',
              obj.fullName,
            ];
            csvData.push(content.join(','));
          });
        }
        formatVideoExport(obj.subCategories);
      });
    };
    formatVideoExport(mediaList);
    const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const videoExport = window.URL.createObjectURL(blob);

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            dispatch({
              type: 'videoModel/getMediaMediaList',
              payload: values,
            });
            dispatch({
              type: 'videoModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Search Videos"
            name="freeText"
          >
            <Input placeholder="Search Text" />
          </Form.Item>
          <Form.Item
            label="Created After"
            name="createdAfter"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Category"
            name="categoriesMatchOr"
          >
            <Cascader
              multiple
              showSearch
              placeholder="Please Select"
              expandTrigger="hover"
              fieldNames={{ label: 'name', value: 'name', children: 'subCategories' }}
              options={categoryList}
            />
          </Form.Item>
          <Form.Item
            label="Tag"
            name="tagsMultiLikeOr"
          >
            <Select
              placeholder="Please Select"
              mode="multiple"
              options={tagList.map((item) => ({ label: item, value: item }))}
            />
          </Form.Item>
          {Object.entries(fixedFilters).map(([key, value]) => (
            <Form.Item
              key={key}
              label={key}
              name={['fixedFilters', value.filterType, key]}
            >
              <Select
                placeholder="Please Select"
                mode="multiple"
                options={value.filterList.map((item) => ({ label: item, value: item }))}
              />
            </Form.Item>
          ))}
          <Form.Item label="Download Video Listing">
            <Button
              type="primary"
              htmlType="a"
              download="CECVideoExport.csv"
              href={videoExport}
            >
              Export
            </Button>
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderCategories = (obj, level) => {
    const {
      videoModel: { categoryActiveKey },
      dispatch,
    } = this.props;
    const { selectedKey } = this.state;

    return (
      <>
        {obj.mediaEntries && obj.mediaEntries.length > 0 && (
          <Table
            bordered
            size="small"
            rowKey="id"
            pagination={false}
            dataSource={obj.mediaEntries}
            onRow={(record) => ({
              onClick: () => this.showModal(record.id),
            })}
            columns={[
              {
                title: 'Thumbnail',
                dataIndex: 'thumbnailUrl',
                key: 'thumbnailUrl',
                width: 100,
                render: (value) => (
                  <img
                    src={value}
                    alt="Thumbnail"
                    style={{ width: '100%' }}
                  />
                ),
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 300,
              },
              {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                render: (value) => (
                  <span
                    className="video-description"
                    title={value}
                  >
                    {value}
                  </span>
                ),
              },
              {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 150,
                render: (value) => moment(value * 1000).format(DATE_TIME_FORMAT),
              },
              {
                title: 'Duration',
                dataIndex: 'msDuration',
                key: 'msDuration',
                width: 80,
                render: (value) => getDuration(value),
              },
              {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                width: 200,
                render: (value) => (value ? value.map((item) => <Tag key={item}>{item}</Tag>) : ''),
              },
              {
                dataIndex: 'id',
                key: 'actions',
                align: 'center',
                width: 40,
                render: (value, record) => (
                  <>
                    <AppIcon
                      className="video-actions"
                      name="more"
                    />
                    {value === selectedKey && (
                      <VideoDetails
                        onCancel={this.hideModal}
                        category={obj.fullName}
                        video={record}
                      />
                    )}
                  </>
                ),
              },
            ]}
          />
        )}
        {obj.subCategories && obj.subCategories.length > 0 && (
          <Collapse
            ghost
            bordered={false}
            className="folder-collapse"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
                {isActive ? <FolderOpenOutlined /> : <FolderOutlined />}
              </span>
            )}
            activeKey={categoryActiveKey[`level${level}`]}
            onChange={(activeKey) => {
              dispatch({
                type: 'videoModel/setState',
                payload: {
                  categoryActiveKey: {
                    ...categoryActiveKey,
                    [`level${level}`]: activeKey,
                  },
                },
              });
            }}
          >
            {obj.subCategories.map((subCategory) => (
              <Collapse.Panel
                key={subCategory.id}
                header={subCategory.name}
              >
                {this.renderCategories(subCategory, level + 1)}
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      </>
    );
  };

  render() {
    const {
      videoModel: { loading, mediaList },
    } = this.props;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Problem_analysis"
          color="#0271bc"
          title="Videos"
          subtitle="Video series created by experts to help educate users on common workflows"
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="video-container">
            <Spin
              style={{ height: 400 }}
              spinning={loading}
            >
              {mediaList.length > 0 ? (
                this.renderCategories({ subCategories: mediaList }, 0)
              ) : (
                <span>No Content</span>
              )}
            </Spin>
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

export default connect(({ videoModel }) => ({
  videoModel,
}))(Video);
