export const formatFormData = (obj) => {
  const formData = new FormData();

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((val, index) =>
        formData.append(`${obj[key].length <= 1 ? key : key + index}`, val)
      );
    } else {
      formData.append(key, obj[key]);
    }
  }

  return formData;
};

export const getSearchParam = (param, url) => {
  const getSearchStr = () => {
    let search;
    try {
      search = new URL(url || window.location.href).search;
    } catch (e) {
      return url;
    }

    return search;
  };
  const search = getSearchStr();
  const params = new URLSearchParams(search);

  if (param === undefined) {
    let paramObj = {};
    for (const key of params.keys()) {
      paramObj[key] = params.getAll(key).length > 1 ? params.getAll(key) : params.get(key);
    }
    return paramObj;
  }

  return params.getAll(param).length > 1 ? params.getAll(param) : params.get(param);
};

export const formatPlatformInfo = (info) => {
  let customDimensions = {};

  if (!Array.isArray(info.customDimensions)) {
    const e =
      info.customDimensions && info.customDimensions.message
        ? info.customDimensions
        : {
            message: 'Invalid custom dimensions',
          };
    throw e;
  }

  info.customDimensions.forEach((customDimension) => {
    customDimensions[customDimension.name.replace(/\s/g, '')] = customDimension.idcustomdimension;
  });

  return {
    ...info,
    customDimensions,
  };
};
