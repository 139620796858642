import React from 'react';
import { connect } from 'dva';
import { Link } from 'dva/router';
import { Layout, Form, Select, Input, Table, Button, Modal } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import { ROUTES } from '@/constants';
import SubCategoryDetails from './sub-category-details';

const columns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (_, { label, category }) => (
      <Link to={ROUTES.ADMIN_CONTENT + `?category=${category}&sub_category=${label}`}>{label}</Link>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (category) => (
      <Link to={ROUTES.ADMIN_CONTENT + '?category=' + category}>{category}</Link>
    ),
  },
];

export const ROW_KEY = '_id';

class AdminSubCategory extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'contentModel/getSubCategories' });

    this.state = {
      modalType: '',
      selectedKey: null,
      refineParams: {},
    };
  }

  showModal = (modalType) => {
    this.setState({ modalType });
  };

  hideModal = () => {
    this.setState({ modalType: '' });
  };

  renderSider = () => {
    const {
      contentModel: { categories },
    } = this.props;

    return (
      <SidePanel type="filter">
        {categories.length ? (
          <Form
            layout="vertical"
            onValuesChange={debounce((_, values) => {
              this.setState({ selectedKey: null, refineParams: values });
            }, 500)}
          >
            <Form.Item
              label="Category"
              name="category"
            >
              <Select
                showArrow
                allowClear
                options={categories.map((item) => ({ label: item, value: item }))}
              />
            </Form.Item>
            <Form.Item
              label="Label"
              name="label"
            >
              <Input />
            </Form.Item>
          </Form>
        ) : (
          <span style={{ padding: '0 15px' }}>No Categories</span>
        )}
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      contentModel: { subCategories },
      dispatch,
    } = this.props;
    const { selectedKey } = this.state;

    const selectedObj = (subCategories || []).find(
      (subCategory) => subCategory[ROW_KEY] === selectedKey
    );

    return selectedObj ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove sub-category.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  dispatch({
                    type: 'contentModel/deleteSubCategories',
                    payload: selectedObj,
                  });
                },
              });
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    ) : null;
  };

  onSelect = (selectedKey) => {
    this.setState({ selectedKey: selectedKey === this.state.selectedKey ? null : selectedKey });
  };

  render() {
    const {
      contentModel: { loading, categories, subCategories },
      dispatch,
    } = this.props;
    const { modalType, selectedKey, refineParams } = this.state;

    const dataSource = subCategories.filter((item) => {
      for (const key in refineParams) {
        if (!refineParams[key]) continue;
        if (refineParams[key] !== item[key]) return false;
      }
      return true;
    });

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Network_audits"
          color="#0271bc"
          title="Content Management"
          subtitle="Manage subgroups for: Help, FAQ, APIs, Samples, etc."
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => this.showModal('new'),
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                pagination={false}
                loading={loading}
                rowKey={ROW_KEY}
                dataSource={dataSource}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [selectedKey],
                  onChange: (selectedKeys) => this.onSelect(selectedKeys[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onSelect(record[ROW_KEY]),
                })}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {modalType === 'new' && (
          <SubCategoryDetails
            {...refineParams}
            categories={categories}
            onCancel={this.hideModal}
            dispatch={dispatch}
          />
        )}
      </div>
    );
  }
}

export default connect(({ contentModel }) => ({
  contentModel,
}))(AdminSubCategory);
