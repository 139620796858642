import React from 'react';
import { Modal, Form, Select, Button } from 'antd';
import UserSelect from '@/components/user-select';
import { ROW_KEY } from '../';

class UserDomainDetails extends React.Component {
  formRef = React.createRef();

  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...this.props.userDomain,
      ...values,
    };

    this.setState({
      isSaveDisabled:
        !this.values.username ||
        !this.values.username.length > 0 ||
        !this.values.labDomains ||
        !this.values.labDomains.length > 0,
    });
  };

  render() {
    const { userDomain, labDomains, onCancel, dispatch } = this.props;
    const { isSaveDisabled } = this.state;
    const isEdit = this.props.userDomain[ROW_KEY];

    return (
      <Modal
        visible
        width={600}
        title={`${isEdit ? 'Edit' : 'New'} User Domain`}
        closable={false}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: `userLabDomainModel/${
                    isEdit ? 'patchUserLabDomains' : 'postUserLabDomains'
                  }`,
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 6 }}
          ref={this.formRef}
          initialValues={{
            ...userDomain,
            labDomains: (userDomain.labDomains || []).map(({ _id }) => _id),
          }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Username"
            name="username"
          >
            <UserSelect
              disabled={isEdit}
              onChange={(username) => {
                dispatch({
                  type: 'userLabDomainModel/setUserCurrLabDomains',
                  payload: { username, formRef: this.formRef },
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Lab Domains"
            name="labDomains"
          >
            <Select
              showArrow
              mode="multiple"
            >
              {labDomains.map(({ domainName, _id }) => {
                if (domainName === 'excludelabdomain' || domainName === 'defaultlabdomain')
                  return null;
                return (
                  <Select.Option
                    key={_id}
                    value={_id}
                  >
                    {domainName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default UserDomainDetails;
