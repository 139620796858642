import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Checkbox, Modal, Collapse } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import ContentCategory from '@/components/content-category';
import { getSearchParam } from '@/utils';
import ContentDetails from './content-details';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Sub-Category',
    dataIndex: 'sub_category',
    key: 'sub_category',
  },
  {
    title: 'Published',
    dataIndex: 'published',
    key: 'published',
    render: (checked) => (
      <Checkbox
        checked={checked}
        disabled
      />
    ),
  },
];

export const ROW_KEY = '_id';

class AdminContent extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'contentModel/getSubCategories' });

    this.state = {
      showModal: false,
    };

    this.refineFormRef = React.createRef();
  }

  onRefineParamsChange = () => {
    const refineParams = getSearchParam();

    this.props.dispatch({
      type: 'contentModel/onRefineParamsChange',
      payload: refineParams,
      callback: () => {
        this.refineFormRef.current.resetFields();
        this.refineFormRef.current && this.refineFormRef.current.setFieldsValue(refineParams);
      },
    });
  };

  componentDidMount() {
    this.onRefineParamsChange();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.onRefineParamsChange();
    }
  }

  showContentDetails = () => {
    this.setState({ showModal: true });
  };

  hideContentDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      contentModel: { editCategories, subCategories },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          ref={this.refineFormRef}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'contentModel/onRefineParamsChange',
              payload: values,
            });
          }, 500)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <ContentCategory
            categories={editCategories}
            subCategories={subCategories}
          />
          <Collapse
            ghost
            defaultActiveKey={['published']}
          >
            <Collapse.Panel
              header="Published"
              key="published"
            >
              <Form.Item name="published">
                <Checkbox.Group>
                  <Checkbox value={true}>Yes</Checkbox>
                  <Checkbox value={false}>No</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      contentModel: { currentContent },
    } = this.props;

    return currentContent[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove content.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'contentModel/deleteContents',
                  });
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={this.showContentDetails}
          >
            Edit
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentContentChange = (record = {}) => {
    const {
      contentModel: { currentContent },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentContent[ROW_KEY] ? {} : record;
    dispatch({ type: 'contentModel/onCurrentContentChange', payload });
  };

  render() {
    const {
      contentModel: { loading, contents, currentContent, editCategories, subCategories },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_System_profiles"
          color="#0271bc"
          title="Content Management"
          subtitle="Post content for: FAQ, API, Sample Code and General Documentation"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => {
                this.onCurrentContentChange();
                this.showContentDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={contents}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentContent[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentContentChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentContentChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <ContentDetails
            onCancel={this.hideContentDetails}
            dispatch={dispatch}
            content={currentContent}
            categories={editCategories}
            subCategories={subCategories}
          />
        )}
      </div>
    );
  }
}

export default connect(({ contentModel }) => ({
  contentModel,
}))(AdminContent);
