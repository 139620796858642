import { message } from 'antd';
import { routerRedux } from 'dva/router';
import {
  getLabsBookingsAdmin,
  patchLabsBookingsAdminCancel,
  patchLabsBookingsAdminUpdateBooking,
  deleteLabsBookingsScheduledAdmin,
} from '@/services';
import { ROUTES } from '@/constants';

const model = {
  namespace: 'scheduleModel',
  state: {
    labBookings: [],
    currentLab: {},
    refineParams: {
      limit: 10,
      page: 1,
    },
    // UI states
    pagination: {},
  },
  subscriptions: {},
  effects: {
    *getLabsBookingsAdmin({ payload, isSSE }, { put, call, select }) {
      try {
        if (!isSSE) {
          yield put({
            type: 'appModel/setTableLoading',
            payload: {
              modelName: 'scheduleModel',
              loading: true,
            },
          });
        }

        const { refineParams } = yield select(({ scheduleModel }) => ({
          refineParams: scheduleModel.refineParams,
        }));

        const data = yield call(getLabsBookingsAdmin, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            labBookings: data,
            pagination: {
              current: data.page,
              pageSize: data.limit,
              total: data.totalDocs,
            },
          },
        });

        yield put({ type: 'onCurrentLabChange' });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'scheduleModel',
            loading: false,
          },
        });
      }
    },
    *patchLabsBookingsAdminCancel({ payload }, { put, call }) {
      try {
        yield call(patchLabsBookingsAdminCancel, { bookingId: payload.bookingId });

        message.success('Success');

        /* Matomo */
        yield put({
          type: 'labModel/trackLabEvent',
          payload: {
            action: 'Cancel',
            nameObj: {
              title: payload.title,
              bookingId: payload.bookingId,
              configs: payload.bookingRequestData.labConfigs,
            },
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchLabsBookingsAdminUpdateBooking({ payload, callback }, { put, call }) {
      try {
        yield call(patchLabsBookingsAdminUpdateBooking, payload);

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteLabsBookingsScheduledAdmin({ payload }, { put, call }) {
      try {
        yield call(deleteLabsBookingsScheduledAdmin, { bookingId: payload.bookingId });

        message.success('Success');

        /* Matomo */
        yield put({
          type: 'labModel/trackLabEvent',
          payload: {
            action: 'Delete',
            nameObj: {
              title: payload.title,
              bookingId: payload.bookingId,
              configs: payload.bookingRequestData.labConfigs,
            },
          },
        });
        console.log(payload);
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *bookAgain(_, { put, select }) {
      const { currentLab } = yield select(({ scheduleModel }) => ({
        currentLab: scheduleModel.currentLab,
      }));

      yield put.resolve(
        routerRedux.push({
          pathname: ROUTES.LAB_SCHEDULE_NEW,
        })
      );

      yield put({
        type: 'labModel/setState',
        payload: {
          newLab: {
            bookAgain: true,
            title: currentLab.title,
            bookingOwner: currentLab.bookingOwner,
            bookingDomain: currentLab.bookingDomain,
            bookingRequestData: currentLab.bookingRequestData,
          },
        },
      });
    },
    *onCurrentLabChange({ payload }, { call, select, put }) {
      try {
        const { currentLab } = yield select(({ scheduleModel }) => ({
          currentLab: scheduleModel.currentLab,
        }));

        if (payload) {
          yield put({
            type: 'setState',
            payload: { currentLab: payload },
          });
        }

        const bookingId = payload ? payload.bookingId : currentLab.bookingId;
        if (bookingId) {
          const data = yield call(getLabsBookingsAdmin, { bookingId });
          yield put({
            type: 'setState',
            payload: {
              currentLab: data[0] || {},
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    *onRefineParamsChange({ payload }, { select, put }) {
      const { refineParams } = yield select(({ scheduleModel }) => ({
        refineParams: { ...scheduleModel.refineParams, ...payload },
      }));

      yield put({ type: 'getLabsBookingsAdmin', payload: refineParams });

      yield put({
        type: 'setState',
        payload: { refineParams },
      });
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
