import React from 'react';
import { Tabs, Button } from 'antd';
import SwaggerUIApp from '@/components/swagger-ui-app';
import { ROUTES } from '@/constants';

class InteractionPanel extends React.Component {
  componentDidMount() {
    // Send Matomo event after Matomo Page View tracking
    setTimeout(() => {
      const { virtualDevice } = this.props;

      const activeInteraction = virtualDevice.supported_interactions.find(
        (interaction) => interaction.active
      );
      activeInteraction && this.trackLabEvent(activeInteraction.name);
    }, 1000);
  }

  /* Matomo */
  trackLabEvent = (interactionType) => {
    const { virtualDevice, dispatch } = this.props;

    dispatch({
      type: 'labModel/trackLabEvent',
      payload: {
        action: 'Interaction',
        nameObj: {
          bookingId: virtualDevice.bookingId,
          vdId: virtualDevice.vd_id,
          interactionType,
        },
      },
    });
  };

  renderPanelContent = (interaction) => {
    const { virtualDevice } = this.props;

    if (interaction.name === 'swagger') {
      return (
        <SwaggerUIApp
          virtualDevice={virtualDevice}
          {...interaction}
        />
      );
    }

    if (interaction.link) {
      return (
        <Button
          type="primary"
          onClick={() => {
            window.open(
              `${ROUTES.LAB_TERMINAL}/${encodeURIComponent(interaction.link)}`,
              `${virtualDevice.vd_id}/${interaction.name}/${virtualDevice._id}`,
              'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=650'
            );
          }}
        >
          Open Terminal
        </Button>
      );
    }

    return null;
  };
  render() {
    const { virtualDevice } = this.props;

    return (
      <Tabs
        type="card"
        tabPosition="left"
        onChange={this.trackLabEvent}
        defaultActiveKey={
          (virtualDevice.supported_interactions.find((interaction) => interaction.active) || {})
            .name
        }
      >
        {virtualDevice.supported_interactions.map((interaction) =>
          interaction.enabled ? (
            <Tabs.TabPane
              key={interaction.name}
              tab={interaction.desc}
            >
              {this.renderPanelContent(interaction)}
            </Tabs.TabPane>
          ) : null
        )}
      </Tabs>
    );
  }
}

export default InteractionPanel;
