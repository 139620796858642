import React from 'react';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button, Modal, Tag } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import UserDomainDetails from './user-domain-details';

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Lab Domains',
    dataIndex: 'labDomains',
    key: 'labDomains',
    render: (value = []) => value.map(({ domainName, _id }) => <Tag key={_id}>{domainName}</Tag>),
  },
];

export const ROW_KEY = '_id';

class AdminUserDomain extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'userLabDomainModel/getUserLabDomains' });
    props.dispatch({ type: 'labDomainModel/getLabDomains' });

    this.state = {
      showModal: false,
    };
  }

  showUserDomainDetails = () => {
    this.setState({ showModal: true });
  };

  hideUserDomainDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      userLabDomainModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            this.props.dispatch({
              type: 'userLabDomainModel/getUserLabDomains',
              payload: values,
            });
            this.props.dispatch({
              type: 'userLabDomainModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            label="Username"
            name="username"
          >
            <Input />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  renderTableFooter = () => {
    const {
      userLabDomainModel: { currentUserLabDomain },
    } = this.props;

    return currentUserLabDomain[ROW_KEY] ? (
      <div className="table-footer">
        <div>1 selected</div>
        <div>
          <Button
            type="text"
            onClick={() => {
              Modal.confirm({
                title: 'Warning',
                content: 'This will remove user domain.',
                cancelButtonProps: { type: 'text' },
                onOk: () => {
                  this.props.dispatch({
                    type: 'userLabDomainModel/deleteUserLabDomains',
                  });
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={this.showUserDomainDetails}
          >
            Edit
          </Button>
        </div>
      </div>
    ) : null;
  };

  onCurrentUserLabDomainChange = (record = {}) => {
    const {
      userLabDomainModel: { currentUserLabDomain },
      dispatch,
    } = this.props;

    const payload = record[ROW_KEY] === currentUserLabDomain[ROW_KEY] ? {} : record;
    dispatch({
      type: 'userLabDomainModel/onCurrentUserLabDomainChange',
      payload,
    });
  };

  render() {
    const {
      userLabDomainModel: { loading, userLabDomains, currentUserLabDomain },
      labDomainModel: { labDomains },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Audit_logs"
          color="#359e35"
          title="User Domain Management"
          subtitle="Manage user domains in the CEC"
          actions={[
            {
              label: 'New',
              icon: <AppIcon name="add" />,
              onClick: () => {
                this.onCurrentUserLabDomainChange();
                this.showUserDomainDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                dataSource={userLabDomains}
                columns={columns}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: [currentUserLabDomain[ROW_KEY]],
                  onChange: (_, selectedRows) => this.onCurrentUserLabDomainChange(selectedRows[0]),
                }}
                onRow={(record) => ({
                  onClick: () => this.onCurrentUserLabDomainChange(record),
                })}
                pagination={false}
              />
              {this.renderTableFooter()}
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <UserDomainDetails
            onCancel={this.hideUserDomainDetails}
            dispatch={dispatch}
            userDomain={currentUserLabDomain}
            labDomains={labDomains}
          />
        )}
      </div>
    );
  }
}

export default connect(({ userLabDomainModel, labDomainModel }) => ({
  userLabDomainModel,
  labDomainModel,
}))(AdminUserDomain);
