import React from 'react';
import { Modal, Form, Input, Checkbox, Button, Upload } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import ContentCategory from '@/components/content-category';
import { ROW_KEY } from '../';

class ContentDetails extends React.Component {
  state = {
    isSaveDisabled: true,
  };

  onValuesChange = (values = {}) => {
    this.values = {
      ...this.props.content,
      ...values,
      body: values.body && values.body.editor ? values.body.editor.getData() : values.body,
      published: !!values.published,
    };

    this.values.files = [];
    if (values.files && values.files.length > 0) {
      let original_file_names = [];
      values.files.forEach((item) => {
        if (item.url) {
          original_file_names.push(item.uid);
        } else {
          this.values.files.push(item.originFileObj);
        }
      });
      this.values.original_file_names = original_file_names.join(';');
    }

    this.setState({
      isSaveDisabled:
        !this.values.title ||
        !this.values.body ||
        !this.values.category ||
        !this.values.sub_category,
    });
  };

  render() {
    const {
      content: { title, body, category, sub_category, files, published },
      categories,
      subCategories,
      onCancel,
      dispatch,
    } = this.props;
    const { isSaveDisabled } = this.state;
    const isEdit = this.props.content[ROW_KEY];

    const fileList = files
      ? files.map((file) => ({
          uid: file.filename,
          name: file.originalName,
          url: `/${file.path}/${file.filename}`,
        }))
      : [];

    return (
      <Modal
        visible
        width={800}
        title={`${isEdit ? 'Edit' : 'New'} Content`}
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                dispatch({
                  type: `contentModel/${isEdit ? 'putContents' : 'postContents'}`,
                  payload: this.values,
                  callback: onCancel,
                });
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
          initialValues={{ title, body, category, sub_category, files: fileList, published }}
          onValuesChange={(_, values) => this.onValuesChange(values)}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input />
          </Form.Item>
          <ContentCategory
            categories={categories}
            subCategories={subCategories}
          />
          <Form.Item label="body">
            <Form.Item>
              <Upload
                accept=".html"
                maxCount={1}
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  const fileReader = new FileReader();
                  fileReader.readAsText(file);
                  fileReader.onload = (e) => {
                    this.ckEditor && this.ckEditor.setData(e.target.result);
                  };
                  onSuccess();
                }}
              >
                <Button>Choose HTML File</Button>
              </Upload>
            </Form.Item>
            <Form.Item name="body">
              <CKEditor
                initData={body}
                config={{ fullPage: true }}
                onInstanceReady={({ editor }) => {
                  this.ckEditor = editor;
                }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="files"
            label="Attachment"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (!e || e.fileList.length < 1) return undefined;

              return e.fileList;
            }}
          >
            <Upload
              multiple
              customRequest={({ onSuccess }) => onSuccess()}
            >
              <Button>Choose File</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="published"
            valuePropName="checked"
            extra={
              <p className="highlight-info">
                Only published content can be viewed by non admin users.
              </p>
            }
          >
            <Checkbox>Published</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ContentDetails;
