import React from 'react';
import { Form, Select } from 'antd';

class ContentCategory extends React.Component {
  render() {
    const { categories, subCategories } = this.props;

    return (
      <>
        <Form.Item
          label="Category"
          name="category"
        >
          <Select
            showArrow
            allowClear
            options={categories.map((item) => ({ label: item, value: item }))}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(preValue, curValue) => {
            if (preValue.category !== curValue.category) {
              preValue.category &&
                this.setFieldValue &&
                this.setFieldValue('sub_category', undefined);
              return true;
            }
            return false;
          }}
        >
          {({ getFieldValue, setFieldValue }) => {
            const category = getFieldValue('category');
            this.setFieldValue = setFieldValue;

            if (!category) return null;

            return (
              <Form.Item
                label="Sub-Category"
                name="sub_category"
              >
                <Select
                  showArrow
                  fieldNames={{ value: 'label' }}
                  options={subCategories.filter((item) => item.category === category)}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </>
    );
  }
}

export default ContentCategory;
