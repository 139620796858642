import {
  getUserLabDomains,
  getScheduleGetScheduleForWindow,
  getClassrooms,
  getClassroomsAdmin,
  postClassrooms,
  postClassroomsAdmin,
  patchClassroomsCancel,
  patchClassroomsAdminCancel,
  deleteClassroomsScheduled,
  deleteClassroomsScheduledAdmin,
  patchClassroomAddLabConfigs,
  patchClassroomRemoveLabConfigs,
  patchClassroomRestartBooking,
  patchClassroomSendStudentEmails,
} from '@/services';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { message } from 'antd';

const LAB_CONFIG_KEYS = [
  'type',
  'config',
  'version',
  'fileUpload',
  'instructorOnly',
  'enableAudit',
  'auditType',
];
const formatLabConfigs = (labConfigs = []) => {
  return labConfigs.map((labConfig) => {
    let config = {};
    LAB_CONFIG_KEYS.forEach((key) => {
      if (labConfig[key] === undefined) return;
      config[key] = labConfig[key];
    });
    return config;
  });
};

const model = {
  namespace: 'classroomModel',
  state: {
    classrooms: [],
    labConfigsByType: {},
  },
  subscriptions: {},
  effects: {
    *getUserLabDomains(_, { put, select, call }) {
      try {
        const { profile } = yield select(({ userModel }) => ({
          profile: userModel.profile,
        }));

        const data = yield call(getUserLabDomains, undefined, { user: profile.username });

        return (data.find((item) => !isEmpty(item.labConfigsByType)) || {}).labConfigsByType || {};
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return {};
      }
    },
    *getScheduleGetScheduleForWindow({ payload }, { call, put }) {
      try {
        return payload.startTime && payload.endTime
          ? yield call(getScheduleGetScheduleForWindow, payload)
          : [];
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });

        return [];
      }
    },
    *getClassrooms({ payload, isSSE }, { call, put, select }) {
      try {
        if(!isSSE) {
          yield put({
            type: 'appModel/setTableLoading',
            payload: {
              modelName: 'classroomModel',
              loading: true,
            },
          });
        }

        const { refineParams, adminRole } = yield select(({ classroomModel, userModel }) => ({
          refineParams: classroomModel.refineParams,
          adminRole: userModel.adminRole,
        }));

        const data = yield call(
          adminRole ? getClassroomsAdmin : getClassrooms,
          payload || refineParams
        );

        yield put({
          type: 'setState',
          payload: {
            classrooms: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'classroomModel',
            loading: false,
          },
        });
      }
    },
    *postClassrooms({ payload, callback }, { call, select, put }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        const { timeRange, labConfigs, ...data } = payload;

        data.startTime = moment(timeRange[0]).format();
        data.endTime = moment(timeRange[1]).format();
        data.labConfigs = formatLabConfigs(labConfigs);

        yield call(adminRole ? postClassroomsAdmin : postClassrooms, data);

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchClassroomsCancel({ payload }, { call, select, put }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        yield call(adminRole ? patchClassroomsAdminCancel : patchClassroomsCancel, payload);

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteClassroomsScheduled({ payload }, { call, select, put }) {
      try {
        const { adminRole } = yield select(({ userModel }) => ({
          adminRole: userModel.adminRole,
        }));

        yield call(adminRole ? deleteClassroomsScheduledAdmin : deleteClassroomsScheduled, payload);

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchClassroomAddLabConfigs({ payload, callback }, { call, put }) {
      try {
        yield call(patchClassroomAddLabConfigs, {
          classroomId: payload.classroomId,
          labConfigs: formatLabConfigs(payload.labConfigs),
        });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchClassroomRemoveLabConfigs({ payload }, { call, put }) {
      try {
        yield call(patchClassroomRemoveLabConfigs, payload);

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchClassroomRestartBooking({ payload }, { call, put }) {
      try {
        yield call(patchClassroomRestartBooking, payload);

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchClassroomSendStudentEmails({ payload }, { call, put }) {
      try {
        yield call(patchClassroomSendStudentEmails, payload);

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
