import React from 'react';
import Poster from './poster';
import HomeRows from './home-rows';

class Home extends React.Component {
  render() {
    return (
      <div>
        <Poster />
        <HomeRows />
      </div>
    );
  }
}

export default Home;
