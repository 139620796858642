import React from 'react';
import { connect } from 'dva';
import { Modal, Button, Tabs, Collapse } from 'antd';
import ServerSideEvent from '@/components/server-side-event';
import { EVENTS_USERLABACTIONS } from '@/services';
import ActionPanel, { setCommitButtonDisabled } from './action-panel';

import './index.less';

const NETWORKID = 'Networks';

class SimActions extends React.Component {
  state = {
    simActions: [],
    inventory: [],
  };

  componentDidMount() {
    setCommitButtonDisabled(true);

    /* Matomo */
    const { lab, dispatch } = this.props;
    dispatch({
      type: 'labModel/trackLabEvent',
      payload: {
        action: 'SIM Actions Page',
        nameObj: { title: lab.title, bookingId: lab.bookingId, configs: lab.labConfigs },
      },
    });
    dispatch({
      type: 'analyticsModel/patchAnalyticsNotificationEventOpenLab',
      payload: {
        visitorId: window._platformInfo.visitorId,
        title: lab.title,
        eventType: 'SimAction',
      },
    });
  }

  getActionsAndInventory = () => {
    const { dispatch, lab } = this.props;

    dispatch({
      type: 'labModel/getLabsActions',
      payload: lab,
    }).then((simActions) => this.setState({ simActions }));

    dispatch({
      type: 'labModel/getLabsActionsInventory',
      payload: lab,
    }).then((inventory) => this.setState({ inventory }));
  };

  render() {
    const { lab, onCancel, dispatch } = this.props;
    const { simActions, inventory } = this.state;

    return (
      <Modal
        visible
        width={1200}
        title="SIM Actions"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              id="commit-button"
              type="primary"
              onClick={() => {
                if (!this.actionPanelRef) return;
                dispatch({
                  type: 'labModel/postLabsActionsRequest',
                  payload: this.actionPanelRef.getActionRequest(),
                  callback: this.actionPanelRef.resetActionRequest(),
                });
              }}
            >
              Commit
            </Button>
          </>
        }
      >
        <Tabs destroyInactiveTabPane>
          {simActions.map((item) => (
            <Tabs.TabPane
              key={item._id || NETWORKID}
              tab={item.vd_id || NETWORKID}
            >
              {item[NETWORKID] ? (
                <Collapse
                  className="highlight-collapse-header"
                  accordion
                  ghost
                  destroyInactivePanel
                  bordered={false}
                >
                  {item[NETWORKID].map((network) => (
                    <Collapse.Panel
                      header={network.vd_id}
                      key={network._id}
                    >
                      <ActionPanel
                        isNetwork
                        forwardedRef={(ref) => {
                          this.actionPanelRef = ref;
                        }}
                        dispatch={dispatch}
                        simActions={network}
                        inventory={inventory.find(({ vd_id }) => network.vd_id === vd_id) || {}}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              ) : (
                <ActionPanel
                  forwardedRef={(ref) => {
                    this.actionPanelRef = ref;
                  }}
                  dispatch={dispatch}
                  simActions={item}
                  inventory={inventory.find(({ vd_id }) => item.vd_id === vd_id) || {}}
                />
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <ServerSideEvent
          url={{
            pathname: EVENTS_USERLABACTIONS,
            params: {
              bookingId: lab.bookingId,
            },
          }}
          onmessage={this.getActionsAndInventory}
          onopen={this.getActionsAndInventory}
        />
      </Modal>
    );
  }
}

export default connect()(SimActions);
