import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import AppIcon from '@/components/app-icon';
import copyToClipboard from '@/utils/copyToClipboard';

const StudentDetails = ({ student, classroom, dispatch, onCancel }) => {
  return (
    <Modal
      visible
      width={800}
      title="Student Details"
      closable={false}
      onCancel={onCancel}
      footer={
        <>
          <Button
            type="text"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              dispatch({
                type: 'classroomModel/patchClassroomSendStudentEmails',
                payload: {
                  studentEmail: student.email,
                  classroomId: classroom.classroomId,
                },
              });
            }}
          >
            Send Login Link
          </Button>
        </>
      }
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 6 }}
        initialValues={student}
      >
        <Form.Item
          label="Email Address"
          name="email"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstName"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Login Link"
          name="loginLink"
        >
          <Input
            readOnly
            suffix={
              <AppIcon
                name="export"
                onClick={() => copyToClipboard(student.loginLink)}
              />
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StudentDetails;
