import {
  getPortalEvents,
  postPortalEvents,
  patchPortalEvents,
  deletePortalEvents,
  getPortalEventsActive,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'portalEventModel',
  state: {
    activeEvents: [],
    portalEvents: [],
    currentEvent: {},
  },
  subscriptions: {},
  effects: {
    *getPortalEvents({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'portalEventModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ portalEventModel }) => ({
          refineParams: portalEventModel.refineParams,
        }));

        let params = { ...(payload || refineParams) };
        if (params && params.published) {
          params.published = params.published.length === 1 ? params.published[0] : undefined;
        }
        const data = yield call(getPortalEvents, params);

        yield put({
          type: 'setState',
          payload: {
            portalEvents: data,
            currentEvent: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'portalEventModel',
            loading: false,
          },
        });
      }
    },
    *postPortalEvents({ payload, callback }, { call, put }) {
      try {
        yield call(postPortalEvents, payload);

        yield put({ type: 'getPortalEvents' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchPortalEvents({ payload, callback }, { call, put }) {
      try {
        yield call(patchPortalEvents, payload);

        yield put({ type: 'getPortalEvents' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deletePortalEvents(_, { call, select, put }) {
      try {
        const { currentEvent } = yield select(({ portalEventModel }) => ({
          currentEvent: portalEventModel.currentEvent,
        }));

        yield call(deletePortalEvents, currentEvent);

        yield put({ type: 'getPortalEvents' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getPortalEventsActive(_, { call, put }) {
      try {
        const data = yield call(getPortalEventsActive);
        yield put({
          type: 'setState',
          payload: {
            activeEvents: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentEventChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentEvent: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
