import {
  getLabsNetworkconfigTypesAdmin,
  getLabDomains,
  postLabDomains,
  patchLabDomains,
  deleteLabDomains,
} from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'labDomainModel',
  state: {
    labNetworkConfigTypes: [],
    labDomains: [],
    currentLabDomain: {},
  },
  subscriptions: {},
  effects: {
    *getLabsNetworkconfigTypesAdmin({ payload }, { call, put }) {
      try {
        const data = yield call(getLabsNetworkconfigTypesAdmin, payload);
        yield put({
          type: 'setState',
          payload: {
            labNetworkConfigTypes: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getLabDomains({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'labDomainModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ labDomainModel }) => ({
          refineParams: labDomainModel.refineParams,
        }));

        const data = yield call(getLabDomains, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            labDomains: data,
            currentLabDomain: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'labDomainModel',
            loading: false,
          },
        });
      }
    },
    *postLabDomains({ payload, callback }, { call, put }) {
      try {
        yield call(postLabDomains, payload);

        yield put({ type: 'getLabDomains' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *patchLabDomains({ payload, callback }, { call, put }) {
      try {
        yield call(patchLabDomains, payload);

        yield put({ type: 'getLabDomains' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteLabDomains(_, { call, select, put }) {
      try {
        const { currentLabDomain } = yield select(({ labDomainModel }) => ({
          currentLabDomain: labDomainModel.currentLabDomain,
        }));

        yield call(deleteLabDomains, currentLabDomain);

        yield put({ type: 'getLabDomains' });
        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentLabDomainChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentLabDomain: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
