import React from 'react';
import { Modal, Button, Row, Col, Form, Input, Select, DatePicker, Checkbox, List } from 'antd';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import AppIcon from '@/components/app-icon';
import UserSelect from '@/components/user-select';
import { TIME_FORMAT, DATE_TIME_FORMAT } from '@/constants';
import { addDuration, getDuration, compareDuration } from '@/utils/duration';
import LabConfig from '../lab-config';

import './index.less';

const ROW_KEY = '_id';

const findInterSection = (arrays = []) => {
  let curr = arrays[0].bookedLabs;

  if (arrays.length <= 1) return curr;

  arrays.forEach(({ bookedLabs: array }) => {
    let i = 0,
      j = 0,
      res = [];

    while (i < curr.length && j < array.length) {
      const { startTime: start1, endTime: end1 } = curr[i];
      const { startTime: start2, endTime: end2 } = array[j];

      const low = moment.max([moment(start1), moment(start2)]);
      const high = moment.min([moment(end1), moment(end2)]);

      if (low <= high) {
        res.push({ startTime: low.format(), endTime: high.format() });
      }

      if (moment(end1) < moment(end2)) {
        i++;
      } else {
        j++;
      }
    }

    curr = res;
  });

  return curr;
};

const mergeIntervals = (intervals = []) => {
  if (intervals.length <= 1) return intervals;
  intervals.sort((a, b) => moment(a.startTime) - moment(b.startTime));
  let res = [intervals[0]];
  for (let i = 1; i < intervals.length; i++) {
    if (moment(intervals[i].startTime) <= moment(res[res.length - 1].endTime)) {
      res[res.length - 1].endTime = moment
        .max([moment(intervals[i].endTime), moment(res[res.length - 1].endTime)])
        .format();
    } else {
      res.push(intervals[i]);
    }
  }
  return res;
};

class NewLab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      userDomains: [],
    };

    this.formRef = React.createRef();
    this.calendarRef = React.createRef();

    this.getUserLabDomains(props.newLab.bookingOwner, props.newLab.bookAgain);
    props.dispatch({ type: 'labModel/getScheduleGetSchedule' });
  }

  getUserLabDomains = (payload, bookAgain) => {
    const {
      dispatch,
      newLab: { bookingDomain },
    } = this.props;
    const { userDomains } = this.state;
    const userDomain = userDomains.find(({ domainName }) => domainName === bookingDomain) || {};

    dispatch({
      type: 'labModel/getUserLabDomains',
      payload,
    }).then((data) => {
      this.setState({ userDomains: data });
      bookAgain
        ? this.onBookingDomainChange(
            {},
            (data.length === 1
              ? data[0]
              : data.find(({ domainName }) => domainName === bookingDomain)) || {}
          )
        : this.onBookingDomainChange(userDomain, data.length === 1 ? data[0] : {});
    });
  };

  onBookingDomainChange = (prev, curr) => {
    let fieldsValue = { bookingDomain: curr.domainName };

    if (curr.labDomainUserType === 'LONGTERM') {
      fieldsValue.duration = '365 days';
      fieldsValue.userVisible = false;
    } else if (prev.labDomainUserType === 'LONGTERM') {
      fieldsValue.duration = undefined;
      fieldsValue.userVisible = true;
    }

    this.setFieldsValue(fieldsValue);
  };

  componentDidMount() {
    // To resolve the FullCalendar resizing issue.
    setTimeout(() => this.setState({ showFullCalendar: true }), 500);
  }

  showLabConfig = () => {
    this.setState({ showModal: true });
  };

  hideLabConfig = () => {
    this.props.dispatch({ type: 'labModel/getFileUpload' });
    this.setState({ showModal: false });
  };

  onValuesChange = (values) => {
    this.props.dispatch({
      type: 'labModel/onNewLabChange',
      payload: values,
    });
  };

  setFieldsValue = (values) => {
    this.formRef.current.setFieldsValue(values);
    this.onValuesChange(values);
  };

  setASAP = () => {
    const { newLab, dispatch } = this.props;

    if (!newLab.duration) {
      dispatch({
        type: 'appModel/handleError',
        payload: {
          message: 'Duration is required.',
        },
      });
      return;
    }

    let startTime = moment().add(3, 'minutes');
    let intervals = mergeIntervals(this.getBookedLabs());

    intervals.forEach((interval) => {
      if (moment(interval.endTime) < moment()) return;
      if (moment(addDuration(startTime, newLab.duration)) < moment(interval.startTime)) return;

      startTime = moment(interval.endTime).add(1, 'minutes');
    });

    this.setFieldsValue({ startTime });
  };

  getBookedLabs = () => {
    const { newLab, labConfigsSchedule } = this.props;
    const { bookingRequestData = {} } = newLab;
    let res = [];

    labConfigsSchedule.forEach((schedule) => {
      (bookingRequestData.labConfigs || []).forEach((config) => {
        if (
          schedule.type === config.type &&
          schedule.config === config.config &&
          schedule.version === config.version
        ) {
          const bookedLabs = findInterSection(schedule.allBookedLabs).map((item) => ({
            ...item,
            config: schedule.config,
          }));

          res = [...res, ...bookedLabs];
        }
      });
    });

    return res;
  };

  getCalendarEvent = () => {
    const { newLab } = this.props;

    let events = [
      {
        editable: false,
        start: moment().startOf('day').format(),
        end: moment().format(),
        display: 'background',
        backgroundColor: '#d0d0d0',
      },
    ];

    if (newLab.startTime && newLab.endTime) {
      events.push({
        id: 'new_lab',
        title: newLab.title,
        start: newLab.startTime,
        end: newLab.endTime,
      });
    }

    this.getBookedLabs().forEach(({ config, startTime, endTime }) =>
      events.push({
        editable: false,
        title: config,
        start: startTime,
        end: endTime,
        className: 'unavailable-timeslot',
      })
    );

    return events;
  };

  setCalendarEvent = ({ startStr, endStr }) => {
    this.setFieldsValue({
      startTime: startStr && moment(startStr),
      duration: endStr && getDuration(startStr, endStr),
    });
  };

  validateDuration = () => {
    const { startTime, endTime } = this.props.newLab;
    if (!startTime || !endTime) return {};
    if (compareDuration(moment(endTime), moment(startTime).add(1, 'hours'))) return null;

    return {
      validateStatus: 'error',
      help: 'Min duration is 1 hour.',
    };
  };

  render() {
    const { username, portalDomain, newLab, onCancel, dispatch, adminRole } = this.props;
    const { bookingRequestData = {}, bookingDomain } = newLab;
    const { userDomains, showModal, showFullCalendar } = this.state;
    const userDomain = userDomains.find(({ domainName }) => domainName === bookingDomain) || {};
    const events = this.getCalendarEvent();
    const durationError = this.validateDuration();

    return (
      <Modal
        visible
        title="Book Lab Time"
        width={1200}
        closable={false}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={
                !newLab.startTime ||
                durationError ||
                !newLab.bookingRequestData ||
                !newLab.bookingRequestData.labConfigs
              }
              onClick={() => dispatch({ type: 'labModel/postScheduleLab' })}
            >
              Book
            </Button>
          </>
        }
      >
        <Row
          wrap={false}
          className="cols-container"
        >
          <Col flex="30%">
            <Form
              layout="vertical"
              ref={this.formRef}
              initialValues={{ bookingOwner: username, userVisible: true, ...newLab }}
              onValuesChange={(_, values) => this.onValuesChange(values)}
            >
              <Form.Item
                label="Title"
                name="title"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Owner"
                name="bookingOwner"
              >
                <UserSelect
                  onChange={(value) => {
                    this.getUserLabDomains(value);
                    this.onValuesChange({
                      bookingRequestData: {
                        ...bookingRequestData,
                        labConfigs: [],
                      },
                    });
                  }}
                />
              </Form.Item>
              {userDomains.length > 1 && (
                <Form.Item
                  label="Domain"
                  name="bookingDomain"
                >
                  <Select
                    options={userDomains}
                    fieldNames={{ label: 'domainName', value: 'domainName' }}
                    onChange={(_, values) => {
                      this.onBookingDomainChange(userDomain, values);
                      this.onValuesChange({
                        bookingRequestData: {
                          ...bookingRequestData,
                          labConfigs: [],
                        },
                      });
                    }}
                  />
                </Form.Item>
              )}
              {bookingDomain && (
                <Form.Item
                  className="form-item-with-action"
                  label={
                    <>
                      <span>Lab Configs</span>
                      <Button
                        type="primary"
                        className="ant-btn-green"
                        onClick={this.showLabConfig}
                      >
                        Select
                      </Button>
                    </>
                  }
                >
                  <List
                    size="small"
                    rowKey={ROW_KEY}
                    locale={{ emptyText: 'No lab config yet.' }}
                    dataSource={bookingRequestData.labConfigs}
                    renderItem={(item, index) => (
                      <List.Item
                        actions={[
                          <AppIcon
                            name="trashcan"
                            color="#0271bc"
                            onClick={() => {
                              let { labConfigs = [] } = bookingRequestData;
                              labConfigs.splice(index, 1);

                              this.onValuesChange({
                                bookingRequestData: {
                                  ...bookingRequestData,
                                  labConfigs,
                                },
                              });
                            }}
                          />,
                        ]}
                      >
                        <List.Item.Meta
                          title={`Config: ${item.config}`}
                          description={
                            item.fileUpload &&
                            item.fileUpload.uploadLabel &&
                            `Upload: ${item.fileUpload.uploadLabel}`
                          }
                        />
                      </List.Item>
                    )}
                  />
                  {showModal && (
                    <LabConfig
                      onCancel={this.hideLabConfig}
                      dispatch={dispatch}
                      portalDomain={portalDomain}
                      value={bookingRequestData.labConfigs}
                      onChange={(labConfigs) =>
                        this.onValuesChange({
                          bookingRequestData: {
                            ...bookingRequestData,
                            labConfigs,
                          },
                        })
                      }
                      labConfigs={userDomain.labConfigsByType}
                    />
                  )}
                </Form.Item>
              )}
              {bookingRequestData.labConfigs && bookingRequestData.labConfigs.length > 0 && (
                <>
                  <Form.Item
                    label="Duration"
                    name="duration"
                    tooltip="The format is an hour, minute, second string separated by colons like 23:59:59. The number of days can be prefixed with a 'd' or 'day' or 'days' separator like so 7 days 23:59:59"
                    hidden={userDomain.labDomainUserType === 'LONGTERM'}
                    {...durationError}
                  >
                    <Input placeholder="Duration Ex: 02:30" />
                  </Form.Item>
                  <Form.Item
                    className="form-item-with-action"
                    label={
                      <>
                        <span>Start Time</span>
                        <Button
                          type="primary"
                          className="ant-btn-green"
                          onClick={this.setASAP}
                        >
                          ASAP
                        </Button>
                      </>
                    }
                    name="startTime"
                  >
                    <DatePicker
                      inputReadOnly
                      showToday={false}
                      showSecond={false}
                      showNow={false}
                      minuteStep={15}
                      format={DATE_TIME_FORMAT}
                      showTime={{ format: TIME_FORMAT }}
                      style={{ width: '100%' }}
                      onSelect={(current) => {
                        const okButton = document.querySelector('.ant-picker-ok button');
                        if (okButton) {
                          setTimeout(() => {
                            okButton.disabled = current < moment();
                          });
                        }
                      }}
                      disabledTime={(current) => {
                        const now = moment();
                        if (now.diff(current) < 0) return;

                        const range = (start, end) => {
                          const result = [];
                          for (let i = start; i < end; i++) {
                            result.push(i);
                          }
                          return result;
                        };

                        return {
                          disabledHours: () => range(0, now.hour()),
                          disabledMinutes: () => range(0, now.minute()),
                        };
                      }}
                      disabledDate={(current) => current && current < moment().startOf('day')}
                    />
                  </Form.Item>
                </>
              )}
              {adminRole && (
                <Form.Item
                  name="userVisible"
                  valuePropName="checked"
                >
                  <Checkbox>User Visible</Checkbox>
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col flex="70%">
            {showFullCalendar ? (
              <FullCalendar
                ref={this.calendarRef}
                selectable={userDomain.labDomainUserType !== 'LONGTERM'}
                editable={userDomain.labDomainUserType !== 'LONGTERM'}
                allDaySlot={false}
                height="100%"
                initialView="timeGridWeek"
                headerToolbar={{ end: 'prev,next' }}
                scrollTimeReset={false}
                scrollTime={moment().format(TIME_FORMAT)}
                slotLabelFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }}
                slotDuration="00:15:00"
                dayHeaderFormat="ddd D"
                plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
                validRange={{ start: moment().format() }}
                events={{
                  events: events,
                  overlap: false,
                }}
                selectOverlap={false}
                select={(event) => this.setCalendarEvent(event)}
                eventDrop={({ event }) => this.setCalendarEvent(event)}
                eventResize={({ event }) => this.setCalendarEvent(event)}
                eventClick={({ event }) => {
                  event.id === 'new_lab' &&
                    Modal.confirm({
                      title: 'Warning',
                      content: 'This will remove the selected time.',
                      cancelButtonProps: { type: 'text' },
                      onOk: () => {
                        event.remove();
                        this.setCalendarEvent({ event });
                      },
                    });
                }}
              />
            ) : (
              'Loading Calendar'
            )}
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default NewLab;
