import { getUserPermissions, postUserPermissions, deleteUserPermissions } from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'permissionModel',
  state: {
    permissions: [],
    currentPermission: {},
  },
  subscriptions: {},
  effects: {
    *getUserPermissions({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'permissionModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ permissionModel }) => ({
          refineParams: permissionModel.refineParams,
        }));

        const data = yield call(getUserPermissions, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            permissions: data,
            currentPermission: {},
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'permissionModel',
            loading: false,
          },
        });
      }
    },
    *postUserPermissions({ payload, callback }, { call, put }) {
      try {
        yield call(postUserPermissions, payload);

        yield put({ type: 'getUserPermissions' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *deleteUserPermissions({ payload }, { call, put }) {
      try {
        yield call(deleteUserPermissions, payload);

        yield put({ type: 'getUserPermissions' });

        message.success('Success');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onCurrentPermissionChange({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            currentPermission: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
