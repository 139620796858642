import React from 'react';
import { Layout, Menu } from 'antd';
import AppIcon from '../app-icon';

import './index.less';

class SidePanel extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const { type, children } = this.props;

    return (
      <Layout.Sider
        className="layout-sider"
        width={290}
        collapsedWidth={55}
        collapsible
        collapsed={collapsed}
        onCollapse={this.onCollapse}
        trigger={<AppIcon name="filter-arrow" />}
      >
        {type === 'filter' && (
          <Menu
            className="sider-header"
            selectable={false}
            items={[
              {
                key: 'refine-by',
                label: 'Refine by',
                icon: <AppIcon name="filter" />,
              },
              { type: 'divider' },
            ]}
          />
        )}
        <div className="sider-content">{children}</div>
      </Layout.Sider>
    );
  }
}

export default SidePanel;
