import { getFileUploadEulasLatest, getFileUploadEulas, postFileUploadEulas } from '@/services';
import { message } from 'antd';

const model = {
  namespace: 'fileUploadEulaModel',
  state: {
    eula: {},
    latestEula: {},
    eulas: [],
  },
  subscriptions: {},
  effects: {
    *getEulasLatest(_, { call, put }) {
      try {
        const data = yield call(getFileUploadEulasLatest);
        yield put({
          type: 'setState',
          payload: {
            eula: data,
            latestEula: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getEulas(_, { call, put }) {
      try {
        const data = yield call(getFileUploadEulas);
        yield put({
          type: 'setState',
          payload: {
            eulas: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *postEulas({ payload, callback }, { call, put }) {
      try {
        yield call(postFileUploadEulas, payload);

        yield put({ type: 'getEulasLatest' });
        yield put({ type: 'getEulas' });

        message.success('Success');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *onEulaSelect({ payload }, { put }) {
      try {
        yield put({
          type: 'setState',
          payload: {
            eula: payload,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
