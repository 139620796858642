import { message } from 'antd';

const copyToClipboard = (copyText) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(copyText).then(() => {
      message.success('Copied!');
    });
  } else {
    const inputElement = document.createElement('input');
    inputElement.setAttribute('type', 'hidden');
    inputElement.value = copyText;
    document.body.appendChild(inputElement);
    inputElement.focus();
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  }
};

export default copyToClipboard;
